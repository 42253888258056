/*-----------------------------------------------------------------------------------
    
    Template Name: Toro - Minimal eCommerce HTML Template
    Version: 1.0.2
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Section title style
    5. Product style
    6. Delay style
    7. Subscribe style
    8. Footer style
    9. Others style
    10. Categories style
    11. Service style
    12. Instagram style
    13. Animation style
    14. Banner style
    15. Blog style
    16. Video style
    17. Brand logo style
    18. Testimonial style
    19. Breadcrumb style
    20. Shop style
    21. Sidebar style
    22. Product details style
    23. Cart style
    24. Checkout style
    25. Compare style
    26. Login register style
    27. My account style
    28. Order tracking style
    29. About us style
    30. Contact style
    31. Faq style
    32. Newsletter style
    33. Wishlist style
    
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Alegreya:400,500,700,800,900|Poppins:200,300,400,500,600,700,800,900&display=swap");
/*-- Common Style --*/
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.row {
  margin-right: calc(var(--bs-gutter-x) * -.6);
  margin-left: calc(var(--bs-gutter-x) * -.6);
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .6);
  padding-left: calc(var(--bs-gutter-x) * .6);
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  padding-right: var(--bs-gutter-x, 0.9rem);
  padding-left: var(--bs-gutter-x, 0.9rem);
}

:root {
  scroll-behavior: unset;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #262626;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #262626;
  font-weight: 400;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #777;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #262626;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.bg-img-2 {
  background-position: bottom center;
  background-size: cover;
}

.border-top-1 {
  border-top: 1px solid #363636;
}

.border-top-2 {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.border-top-3 {
  border-top: 1px solid rgba(174, 174, 174, 0.3);
}

.border-bottom-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

a:hover {
  color: #fc4f4f;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .mt-100 {
    margin-top: 50px;
  }
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-175 {
  margin-top: 175px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-300 {
  margin-left: 300px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-85 {
  padding-top: 85px;
}

@media only screen and (max-width: 767px) {
  .pt-85 {
    padding-top: 45px;
  }
}

.pt-80 {
  padding-top: 80px;
}

@media only screen and (max-width: 767px) {
  .pt-80 {
    padding-top: 50px;
  }
}

.pt-95 {
  padding-top: 95px;
}

@media only screen and (max-width: 767px) {
  .pt-95 {
    padding-top: 45px;
  }
}

.pt-90 {
  padding-top: 90px;
}

@media only screen and (max-width: 767px) {
  .pt-90 {
    padding-top: 40px;
  }
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 50px;
  }
}

.pt-105 {
  padding-top: 105px;
}

@media only screen and (max-width: 767px) {
  .pt-105 {
    padding-top: 45px;
  }
}

.pt-110 {
  padding-top: 110px;
}

@media only screen and (max-width: 767px) {
  .pt-110 {
    padding-top: 50px;
  }
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 50px;
  }
}

.pt-125 {
  padding-top: 125px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-125 {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-125 {
    padding-top: 45px;
  }
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-130 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-130 {
    padding-top: 50px;
  }
}

.pt-135 {
  padding-top: 135px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-365 {
  padding-top: 365px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .pb-50 {
    padding-bottom: 20px;
  }
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .pb-70 {
    padding-bottom: 20px;
  }
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .pb-80 {
    padding-bottom: 50px;
  }
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .pb-90 {
    padding-bottom: 40px;
  }
}

.pb-95 {
  padding-bottom: 95px;
}

@media only screen and (max-width: 767px) {
  .pb-95 {
    padding-bottom: 45px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 50px;
  }
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

@media only screen and (max-width: 767px) {
  .pb-110 {
    padding-bottom: 50px;
  }
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 40px;
  }
}

.pb-125 {
  padding-bottom: 125px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-125 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-125 {
    padding-bottom: 45px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-130 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-130 {
    padding-bottom: 50px;
  }
}

.pb-135 {
  padding-bottom: 135px;
}

@media only screen and (max-width: 767px) {
  .pb-135 {
    padding-bottom: 86px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-290 {
  padding-bottom: 290px;
}

/*************************
        Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #737373;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #737373;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #737373;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4FC1F0;
  outline: none;
}

.custom-container {
  max-width: 1774px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container {
    max-width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container {
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container {
    max-width: 540px;
  }
}

.custom-container-2 {
  max-width: 1694px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container-2 {
    max-width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container-2 {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-2 {
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-2 {
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-2 {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container-2 {
    max-width: 540px;
  }
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-col-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-md-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-5.custom-col-xs-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-sm-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.custom-col-5-2 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-col-5-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-5-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5-2.custom-col-md-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-5-2 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-5-2.custom-col-xs-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5-2.custom-col-sm-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.custom-col-width-43 {
  flex: 0 0 43.798%;
  max-width: 43.798%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-width-43 {
    flex: 0 0 45%;
    max-width: 45%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-width-43 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-56 {
  flex: 0 0 56.202%;
  max-width: 56.202%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-width-56 {
    flex: 0 0 55%;
    max-width: 55%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-width-56 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-59 {
  flex: 0 0 59%;
  max-width: 59%;
  position: relative;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-width-59 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-width-59 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-41 {
  flex: 0 0 41%;
  max-width: 41%;
  position: relative;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-width-41 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-width-41 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-64 {
  flex: 0 0 64.745%;
  max-width: 64.745%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-col-width-64 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-width-64 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-width-64 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-width-64 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-width-64 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-35 {
  flex: 0 0 35.255%;
  max-width: 35.255%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-col-width-35 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-width-35 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-width-35 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-width-35 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-width-35 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-fullpage-41 {
  flex: 0 0 41.728%;
  max-width: 41.728%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-width-fullpage-41 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-fullpage-58 {
  flex: 0 0 58.272%;
  max-width: 58.272%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-width-fullpage-58 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-fullpage-42 {
  flex: 0 0 42.094%;
  max-width: 42.094%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-width-fullpage-42 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-fullpage-57 {
  flex: 0 0 57.906%;
  max-width: 57.906%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-width-fullpage-57 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-fullpage-56 {
  flex: 0 0 56.881%;
  max-width: 56.881%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-width-fullpage-56 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-fullpage-43 {
  flex: 0 0 43.119%;
  max-width: 43.119%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-width-fullpage-43 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-fullpage-47 {
  flex: 0 0 47.657%;
  max-width: 47.657%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-width-fullpage-47 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-fullpage-50 {
  flex: 0 0 50.967%;
  max-width: 50.967%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-width-fullpage-50 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-slider-hm2-58 {
  flex: 0 0 58%;
  max-width: 58%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-slider-hm2-58 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-slider-hm2-58 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.custom-col-slider-hm2-42 {
  flex: 0 0 42%;
  max-width: 42%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-slider-hm2-42 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-slider-hm2-42 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.custom-col-slider-hm2-44 {
  flex: 0 0 44%;
  max-width: 44%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-slider-hm2-44 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-slider-hm2-44 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.custom-col-slider-hm2-56 {
  flex: 0 0 56%;
  max-width: 56%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-slider-hm2-56 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-slider-hm2-56 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.custom-col-slider-hm2-47 {
  flex: 0 0 47%;
  max-width: 47%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-slider-hm2-47 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-slider-hm2-47 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.custom-col-slider-hm2-53 {
  flex: 0 0 53%;
  max-width: 53%;
  position: relative;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-slider-hm2-53 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-slider-hm2-53 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.custom-col-shop43 {
  flex: 0 0 43.642%;
  max-width: 43.642%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-shop43 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-shop43 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-shop56 {
  flex: 0 0 56.358%;
  max-width: 56.358%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-shop56 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-shop56 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.product-details-col-72 {
  flex: 0 0 72%;
  max-width: 72%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-col-72 {
    flex: 0 0 65%;
    max-width: 65%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-col-72 {
    flex: 0 0 70%;
    max-width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-col-72 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.product-details-col-28 {
  flex: 0 0 28%;
  max-width: 28%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-col-28 {
    flex: 0 0 35%;
    max-width: 35%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-col-28 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-col-28 {
    display: none;
  }
}

.product-dec-col-38 {
  flex: 0 0 38%;
  max-width: 38%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-col-38 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-col-38 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.product-dec-col-62 {
  flex: 0 0 62%;
  max-width: 62%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-col-62 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-col-62 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.product-dec2-48 {
  flex: 0 0 48%;
  max-width: 48%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec2-48 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec2-48 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.product-dec2-52 {
  flex: 0 0 52%;
  max-width: 52%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec2-52 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec2-52 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 60px;
  height: 60px;
  background-color: #262626;
  color: #fff;
  right: 50px;
  bottom: 53px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 40px;
    height: 40px;
  }
}

#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 22px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 40px;
    font-size: 16px;
  }
}

#scrollUp:hover {
  background-color: #fc4f4f;
}

.height-100vh {
  height: 100vh;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

/*--
    - Background Color
------------------------------------------*/
.bg-gray {
  background-color: #F9F9F9;
}

.bg-gray-2 {
  background-color: #efefef;
}

.bg-gray-3 {
  background-color: #E8E8E8;
}

.bg-gray-4 {
  background-color: #EEEFF1;
}

.bg-gray-5 {
  background-color: #f2f6f9;
}

.bg-light-pink {
  background-color: #f9f6f5;
}

.bg-light-blue {
  background-color: #d2e4f6;
}

.bg-black {
  background-color: #161616;
}

.bg-black-2 {
  background-color: #090909;
}

.bg-black-3 {
  background-color: #262626;
}

.bg-black-4 {
  background-color: #1f1b2c;
}

.bg-black-5 {
  background-color: #F1F1F1;
}

.bg-black-6 {
  background-color: #E2E7EC;
}

.bg-lightslategray {
  background-color: #d4dfe4;
}

.bg-antiquewhite {
  background-color: #fff5ee;
}

.bg-palegreen {
  background-color: #e5fcd8;
}

.bg-palegreen-2 {
  background-color: #EAFFFB;
}

.bg-powderblue {
  background-color: #eeffff;
}

.bg-light-pink-2 {
  background-color: #fff1f0;
}

.bg-lightslategray-2 {
  background-color: #EBF3F6;
}

.bg-lightorange {
  background-color: #F5E7DC;
}

.bg-lightorange-2 {
  background-color: #FDFFF2;
}

.body-bg-color {
  background-color: #FFEFEF;
}

.bg-theme-color {
  background-color: #fc4f4f;
}

.background-rgb-1 {
  background-color: rgba(20, 20, 20, 0.4);
}

.default-overlay, .default-overlay-2 {
  position: relative;
}

.default-overlay:before, .default-overlay-2:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .res-white-overly-xs {
    position: relative;
  }
  .res-white-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 9;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-white-overly-md {
    position: relative;
  }
  .res-white-overly-md:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 1;
  }
}

.padding-120-row-col .row {
  margin-right: -60px;
  margin-left: -60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .padding-120-row-col .row {
    margin-right: -30px;
    margin-left: -30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-120-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-120-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-120-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-120-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-120-row-col .row div[class^="col-"] {
  padding-left: 60px;
  padding-right: 60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .padding-120-row-col .row div[class^="col-"] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-120-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-120-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-120-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-120-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-60-row-col .row {
  margin-right: -30px;
  margin-left: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-60-row-col .row div[class^="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-10-row-col .container-fluid {
  padding-right: 5px;
  padding-left: 5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-10-row-col .container-fluid {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.padding-10-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-10-row-col .row {
    margin-right: -5px;
    margin-left: -5px;
  }
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-10-row-col .row div[class^="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.padding-20-row-col .row {
  margin-right: -10px;
  margin-left: -10px;
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-20-row-col .row {
    margin-right: -10px;
    margin-left: -10px;
  }
}

.padding-20-row-col .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-20-row-col .row div[class^="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.padding-40-row-col .row {
  margin-right: -20px;
  margin-left: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-40-row-col .row div[class^="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-70-row-col .row {
  margin-right: -35px;
  margin-left: -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-70-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-70-row-col .row {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-70-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-70-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-70-row-col .row div[class^="col-"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-70-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-70-row-col .row div[class^="col-"] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-70-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-70-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-80-row-col .row {
  margin-right: -40px;
  margin-left: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-80-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-80-row-col .row {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-80-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-80-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-80-row-col .row div[class^="col-"] {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-80-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-80-row-col .row div[class^="col-"] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-80-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-80-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-5-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

@media only screen and (max-width: 767px) {
  .padding-5-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-5-row-col .row {
    margin-right: -5px;
    margin-left: -5px;
  }
}

.padding-5-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 767px) {
  .padding-5-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-5-row-col .row div[class^="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.custom-d-flex {
  display: flex;
}

.height-100-percent {
  height: 100%;
}

.width-100-percent {
  width: 100%;
}

.section-padding-1 .container-fluid {
  padding: 0 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-2 .container-fluid {
  padding: 0 160px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-2 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-3 .container-fluid {
  padding: 0 250px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-3 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-3 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-3 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-4 .container-fluid {
  padding: 0 230px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-4 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-4 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-4 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-5 .container-fluid {
  padding: 0 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-6 .container-fluid {
  padding: 0 90px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-6 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-6 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-6 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-7 .container-fluid {
  padding: 0 265px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-7 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-7 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-7 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-8 .container-fluid {
  padding: 0 190px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-8 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-8 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-8 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-8 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-8 .container-fluid {
    padding: 0 15px;
  }
}

.btn-style-1 a {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.btn-style-1 a.btn-border {
  border: 1px solid #E5E5E5;
}

.btn-style-1 a.btn-border-white {
  border: 2px solid #fff;
  transition-delay: .15s;
}

.btn-style-1 a.btn-border-black {
  border: 2px solid #262626;
  transition-delay: .15s;
}

.btn-style-1 a.btn-border-black2 {
  border: 1px solid #262626;
}

.btn-style-1 a .btn-viewmore-common span {
  font-size: 14px;
  font-weight: 400;
  display: block;
  line-height: 1;
}

.btn-style-1 a .btn-viewmore-common.btn-font-2 span {
  font-size: 12px;
  font-weight: 600;
}

.btn-style-1 a .btn-viewmore-common.btn-letter-sp span {
  letter-spacing: 2px;
}

.btn-style-1 a .btn-viewmore-common.btn-font-3 span {
  font-size: 16px;
}

.btn-style-1 a .btn-viewmore-common.btn-font-4 span {
  font-size: 14px;
  font-weight: 700;
}

.btn-style-1 a .btn-viewmore-normal {
  transform: translateY(0%);
  transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
}

.btn-style-1 a .btn-viewmore-normal span {
  color: #262626;
}

.btn-style-1 a .btn-viewmore-normal.btn-color-white span {
  color: #fff;
}

.btn-style-1 a .btn-ptb-1 {
  padding: 24px 40px 27px;
}

.btn-style-1 a .btn-ptb-1.btn-ptb-inc {
  padding: 21px 40px 23px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a .btn-ptb-1 {
    padding: 15px 40px 18px;
  }
}

.btn-style-1 a .btn-ptb-2 {
  padding: 22px 50px 22px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a .btn-ptb-2 {
    padding: 14px 30px 14px;
  }
}

.btn-style-1 a .btn-ptb-3 {
  padding: 22px 36px 22px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a .btn-ptb-3 {
    padding: 14px 30px 14px;
  }
}

.btn-style-1 a .btn-viewmore-bg-transparent {
  background-color: transparent;
}

.btn-style-1 a .btn-viewmore-bg-black {
  background-color: #262626;
}

.btn-style-1 a .btn-viewmore-bg-black span {
  color: #fff;
}

.btn-style-1 a .btn-viewmore-bg-white {
  background-color: #fff;
}

.btn-style-1 a .btn-viewmore-bg-white span {
  color: #262626;
}

.btn-style-1 a .btn-viewmore-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  transform: translateY(100%);
  transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
}

.btn-style-1 a .btn-viewmore-hover span {
  color: #fff;
}

.btn-style-1 a .btn-viewmore-hover .btn-ptb-1 {
  padding: 24px 40px 27px;
}

.btn-style-1 a .btn-viewmore-hover .btn-ptb-1.btn-ptb-inc {
  padding: 21px 40px 23px;
}

.btn-style-1 a .btn-viewmore-hover .btn-ptb-2 {
  padding: 22px 50px 22px;
}

.btn-style-1 a:hover .btn-viewmore-normal {
  transform: translateY(-100%);
}

.btn-style-1 a:hover .btn-viewmore-hover {
  transform: translateY(0%);
  background-color: #fc4f4f;
}

.btn-style-1 a:hover .btn-viewmore-hover.btn-hover-transition {
  transition-delay: .15s;
}

.btn-style-1 a:hover .btn-viewmore-hover.btn-hover-black {
  background-color: #262626;
}

.btn-style-1 a:hover.btn-border-white {
  border: 2px solid #fc4f4f;
}

.btn-style-1 a:hover.btn-border-black {
  border: 2px solid #fc4f4f;
}

.btn-style-1 a:hover.btn-border-black2 {
  border: 1px solid #fc4f4f;
}

.btn-style-2 a {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  line-height: 1;
  padding: 18px 40px 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-2 a {
    padding: 12px 20px 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-2 a {
    padding: 12px 20px 14px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-2 a {
    padding: 10px 15px 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .btn-style-2 a {
    padding: 12px 20px 14px;
  }
}

.btn-style-2 a.btn-2-border-white {
  border: 2px solid #fff;
}

.btn-style-2 a.btn-2-border-black {
  border: 2px solid #262626;
}

.btn-style-2 a.btn-2-border-tm-color {
  border: 2px solid #fc4f4f;
}

.btn-style-2 a.btn-2-text-black {
  color: #262626;
}

.btn-style-2 a.btn-2-bg-color {
  background-color: #262626;
}

.btn-style-2 a.btn-2-bg-tm-color {
  background-color: #fc4f4f;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-2.mt-25 {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-2.mt-25 {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-2.mt-25 {
    margin-top: 10px;
  }
}

.content-wrapper {
  display: block;
  margin: 0 auto;
  width: 1370px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .content-wrapper {
    width: 1300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .content-wrapper {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .content-wrapper {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .content-wrapper {
    width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .content-wrapper {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .content-wrapper {
    width: 540px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .border-bottom-md {
    border-bottom: 1px solid #eee;
  }
}

@media only screen and (max-width: 767px) {
  .border-bottom-md {
    border-bottom: 1px solid #eee;
  }
}

.custom-relative {
  position: relative;
}

/*-------- 2. Header style ---------*/
.header-area {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-area {
    display: none;
  }
}

.logo-width a img {
  width: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-width a img {
    width: 125px;
  }
}

.logo-width-2 a img {
  width: 117px;
}

.main-menu > nav > ul > li {
  display: inline-block;
  padding: 0 15px;
  position: relative;
}

.main-menu > nav > ul > li.position-static {
  position: static;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li {
    padding: 0 10px;
  }
}

.main-menu > nav > ul > li > a {
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
}

.main-menu > nav > ul > li > a::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 2.5em;
  bottom: 0;
  width: 20%;
  height: 2px;
  background-color: #fc4f4f;
  content: "";
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}

.main-menu > nav > ul > li > a.active::after {
  width: calc(100%);
  opacity: 1;
}

.main-menu > nav > ul > li > a i {
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;
  color: #262626;
  font-weight: 600;
}

.main-menu > nav > ul > li .mega-menu-style-1 {
  width: 100%;
  position: absolute;
  left: 0;
  padding: 0 80px;
  transition: all 0.3s;
  display: none;
  margin-top: 30px;
  z-index: 99;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li .mega-menu-style-1 {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li .mega-menu-style-1 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li .mega-menu-style-1 {
    padding: 0 30px;
  }
}

.main-menu > nav > ul > li .mega-menu-style-1 > .mega-menu-inner {
  display: flex;
  flex-wrap: wrap;
  background-color: #262626;
  padding: 50px;
  justify-content: left;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li .mega-menu-style-1 > .mega-menu-inner {
    padding: 50px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li .mega-menu-style-1 > .mega-menu-inner {
    padding: 50px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li .mega-menu-style-1 > .mega-menu-inner {
    padding: 50px 20px;
  }
}

.main-menu > nav > ul > li .mega-menu-style-1 > .mega-menu-inner > .demo-item {
  width: 12.5%;
  padding: 0 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li .mega-menu-style-1 > .mega-menu-inner > .demo-item {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li .mega-menu-style-1 > .mega-menu-inner > .demo-item {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li .mega-menu-style-1 > .mega-menu-inner > .demo-item {
    padding: 0 10px;
    width: 20%;
  }
}

.main-menu > nav > ul > li .mega-menu-style-1 > .mega-menu-inner > .demo-item .demo-item-content h4 a {
  color: #fff;
}

.main-menu > nav > ul > li > ul {
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #262626;
  position: absolute;
  top: 100%;
  left: 0;
  transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  z-index: 999;
}

.main-menu > nav > ul > li > ul.sub-menu-width {
  width: 220px;
  padding: 39px 0 43px;
}

.main-menu > nav > ul > li > ul.sub-menu-width li {
  display: block;
  padding: 0 15px 14px 30px;
  position: relative;
}

.main-menu > nav > ul > li > ul.sub-menu-width li:last-child {
  padding-bottom: 0;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a {
  display: block;
  color: #D0D0D0;
  font-size: 12px;
  font-weight: 300;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a i {
  float: right;
  font-size: 12px;
  color: #999;
  font-weight: 600;
  position: relative;
  top: 6px;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a:hover {
  color: #fc4f4f;
  text-decoration: underline;
}

.main-menu > nav > ul > li > ul.sub-menu-width li ul.lavel-menu {
  padding: 36px 0px 40px;
  width: 220px;
  transition: all 250ms ease-out;
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #262626;
  position: absolute;
  top: -17px;
  left: 100%;
  margin: 0;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.sub-menu-width li ul.lavel-menu {
    width: 180px;
  }
}

.main-menu > nav > ul > li > ul.sub-menu-width li:hover > ul.lavel-menu {
  visibility: visible;
  opacity: 1;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 {
  width: 1170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2 {
    width: 950px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg1 {
  left: -550px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -505px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -445px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -350px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg2 {
  left: -184px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg2 {
    left: -130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg2 {
    left: -130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg2 {
    left: -158px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg3 {
  left: -184px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg3 {
    left: -183px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg3 {
    left: -183px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg3 {
    left: -170px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg4 {
  left: -454px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg4 {
    left: -447px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg4 {
    left: -349px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 {
  padding: 52px 40px 45px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 {
    padding: 52px 30px 45px 0px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li {
  float: left;
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width20 {
  width: 20%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width20 {
    width: 19%;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width22 {
  width: 22.5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width22 {
    width: 27.5%;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width37 {
  width: 37.5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width37 {
    width: 34.5%;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li a.menu-title {
  color: #fff;
  font-size: 14px;
  padding: 0px 30px 12px 30px;
  position: relative;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li a.menu-title:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  bottom: -2px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li > ul {
  margin: 28px 0 0;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li > ul li {
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li > ul li a {
  color: #D0D0D0;
  font-size: 12px;
  display: block;
  padding: 8px 30px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li > ul li a:hover {
  color: #fc4f4f;
  text-decoration: underline;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li:nth-child(2) ul li {
  display: inline-block;
  width: 49%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap {
  position: relative;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap a img {
  width: 100%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap .banner-menu-content {
  position: absolute;
  left: 41px;
  bottom: 35px;
  z-index: 9;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap .banner-menu-content h2 {
  color: #fff;
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0px;
  line-height: 70px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap .banner-menu-content h2 {
    font-size: 35px;
    line-height: 50px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap:hover .banner-menu-content h2 {
  letter-spacing: 1px;
}

.main-menu > nav > ul > li:hover > a::after {
  width: calc(100%);
  opacity: 1;
}

.main-menu > nav > ul > li:hover > .mega-menu-style-1 {
  display: block;
  -webkit-animation: dropFadeInUp 300ms;
  animation: dropFadeInUp 300ms;
  margin-top: 0px;
}

.main-menu > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.main-menu.main-menu-padding-1 > nav > ul > li {
  padding: 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding: 0 10px;
  }
}

.main-menu.main-menu-padding-2 > nav > ul > li {
  padding: 0 30px 0 0;
}

.main-menu.main-menu-padding-2 > nav > ul > li:last-child {
  padding: 0 0px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.main-menu-padding-2 > nav > ul > li {
    padding: 0 10px 0 0;
  }
  .main-menu.main-menu-padding-2 > nav > ul > li a i {
    margin-left: 3px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-padding-2 > nav > ul > li {
    padding: 0 6px 0 0;
  }
  .main-menu.main-menu-padding-2 > nav > ul > li a {
    font-size: 13px;
  }
  .main-menu.main-menu-padding-2 > nav > ul > li a i {
    margin-left: 3px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-padding-2 > nav > ul > li {
    padding: 0 10px 0 0;
  }
  .main-menu.main-menu-padding-2 > nav > ul > li a i {
    margin-left: 3px;
  }
}

.main-menu.menu-lh-1 > nav > ul > li > a {
  line-height: 120px;
}

.main-menu.menu-lh-2 > nav > ul > li > a {
  line-height: 90px;
}

.main-menu.menu-lh-3 > nav > ul > li > a {
  line-height: 50px;
}

.main-menu.main-menu-white > nav > ul > li > a {
  color: #fff;
}

.main-menu.main-menu-white > nav > ul > li > a i {
  color: #fff;
}

.main-menu.main-menu-light-black > nav > ul > li > a {
  color: #535353;
}

.main-menu.main-menu-light-black > nav > ul > li > a i {
  color: #535353;
}

.main-menu.main-menu-border-none > nav > ul > li > a::after {
  display: none;
}

.main-menu.main-menu-hover-2 > nav > ul > li {
  transition: all .3s ease 0s;
}

.main-menu.main-menu-hover-2 > nav > ul > li > a i {
  transition: all .3s ease 0s;
}

.main-menu.main-menu-hover-2 > nav > ul > li.active {
  background-color: #424242;
}

.main-menu.main-menu-hover-2 > nav > ul > li.active a {
  color: #fc4f4f;
}

.main-menu.main-menu-hover-2 > nav > ul > li.active a i {
  color: #fc4f4f;
}

.main-menu.main-menu-hover-2 > nav > ul > li:hover > a {
  color: #fc4f4f;
}

.main-menu.main-menu-hover-2 > nav > ul > li:hover > a i {
  color: #fc4f4f;
}

.main-menu.main-menu-hover-2 > nav > ul > li:hover {
  background-color: #424242;
}

.main-menu.menu-fw-400 > nav > ul > li > a {
  font-weight: 400;
}

.main-menu.menu-padding-li-none > nav > ul > li {
  padding: 0;
}

.main-menu.menu-padding-a > nav > ul > li {
  padding: 0;
}

.main-menu.menu-padding-a > nav > ul > li > a {
  padding: 0 25px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-padding-a > nav > ul > li > a {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-padding-a > nav > ul > li > a {
    padding: 0 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-padding-a > nav > ul > li > a {
    padding: 0 10px;
  }
}

.main-menu.menu-padding-a > nav > ul > li > a:before {
  top: 50%;
  transform: translateY(-50%);
  height: 3em;
  width: 50%;
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
  opacity: 0;
  transition: all 0.3s;
}

.main-menu.menu-padding-a > nav > ul > li > a:hover, .main-menu.menu-padding-a > nav > ul > li > a.active {
  color: #fff;
}

.main-menu.menu-padding-a > nav > ul > li > a:hover:before, .main-menu.menu-padding-a > nav > ul > li > a.active:before {
  width: 100% !important;
  background-color: #A8D1DD;
  opacity: 1;
}

.header-right-wrap.header-right-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 1;
}

.header-right-wrap.header-flex-2 {
  display: flex;
}

.header-right-wrap .same-style {
  margin-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap .same-style {
    margin-left: 13px;
  }
}

.header-right-wrap .same-style:first-child {
  margin-left: 0px;
}

.header-right-wrap .same-style a {
  font-size: 20px;
}

.header-right-wrap .same-style > span {
  font-size: 12px;
  margin: -9px 0 0;
  display: inline-block;
}

.header-right-wrap .same-style > span i {
  font-size: 20px;
  position: relative;
  top: 5px;
  margin-right: 8px;
}

.header-right-wrap .same-style.same-style-white a {
  color: #fff;
}

.header-right-wrap .same-style.same-style-white a:hover {
  color: #fc4f4f;
}

.header-right-wrap .same-style.same-style-white > span {
  color: #fff;
}

.header-right-wrap .same-style.same-style-font-inc a {
  font-size: 22px;
}

.header-right-wrap .same-style.same-style-font-inc > span i {
  font-size: 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap .same-style.header-contact {
    display: none;
  }
}

.header-right-wrap .cart-wrap a {
  position: relative;
}

.header-right-wrap .cart-wrap a > span {
  position: absolute;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  display: block;
  top: -16px;
  right: -10px;
  min-width: 20px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  background-color: #fc4f4f;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-right-wrap .cart-wrap a > span {
    right: -2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-right-wrap.header-right-hm3 .same-style {
    margin-left: 8px;
  }
  .header-right-wrap.header-right-hm3 .same-style:first-child {
    margin-left: 0px;
  }
}

.search-content-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  transition: all ease-in-out .25s;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-content-wrap.search-visible {
  visibility: visible;
  opacity: 1;
}

.search-content-wrap.search-visible .search-content {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.search-content-wrap > .search-close {
  font-size: 40px;
  display: block;
  position: absolute;
  top: 20%;
  right: 20%;
  line-height: 40px;
  height: 40px;
  transition: all .3s;
  color: #232324;
}

.search-content-wrap > .search-close:hover {
  transform: rotate(90deg);
}

.search-content-wrap > .search-close i:hover {
  color: #fc4f4f;
}

.search-content-wrap .search-content {
  display: inline-block;
  max-width: 90%;
  text-align: center;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out .3s;
  transform: scale(0.9);
}

.search-content-wrap .search-content p {
  font-size: 18px;
  margin: 0 0 20px;
  color: #232324;
  font-weight: 300;
}

.search-content-wrap .search-content .search-form {
  position: relative;
}

.search-content-wrap .search-content .search-form input {
  width: 800px;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #232324;
  text-align: center;
  font-size: 30px;
  padding: 21px 50px 21px 36px;
  color: #232324;
  transition: all .3s ease-out;
  font-weight: 300;
  max-width: 100%;
  height: auto;
}

.search-content-wrap .search-content .search-form .button-search {
  position: absolute;
  top: 25px;
  display: block;
  right: 5px;
  border: 0;
  background: 0 0;
  padding: 0;
  color: #000;
  font-size: 30px;
  height: 30px;
}

.search-content-wrap .search-content .search-form .button-search:hover {
  color: #fc4f4f;
}

.main-wrapper .body-overlay {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active .body-overlay {
  opacity: 1;
  visibility: visible;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 104px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 104px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 50px 15px 50px;
    width: 280px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 48px;
  right: 55px;
  font-size: 30px;
  line-height: 30px;
  transition: all 250ms ease-out;
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 20px;
    right: 23px;
    font-size: 23px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  transform: rotate(90deg);
  color: #fc4f4f;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > h3 {
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 45px;
  color: #2F2F2F;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  max-height: -webkit-calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: flex;
  margin-bottom: 25px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  flex: 0 0 75px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
  margin-top: 2px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 {
  font-size: 14px;
  margin: 0 0 8px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 14px;
  color: #262626;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  height: 20px;
  line-height: 17px;
  overflow: hidden;
  width: 20px;
  text-align: right;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 18px 0 43px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
  font-size: 14px;
  color: #535353;
  margin: 0;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span {
  font-size: 18px;
  color: #2F2F2F;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn {
  display: block;
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a {
  width: 100%;
  display: block;
  margin: 10px 0 0;
  text-align: center;
  line-height: 20px;
  padding: 19px 20px 20px;
  background-color: #2F2F2F;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:hover {
  background-color: #fc4f4f;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:first-child {
  margin-top: 0;
}

.header-aside-active {
  position: fixed;
  top: 0;
  width: 400px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  transform: translate(200px, 0);
  background-color: #1D1D1D;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .header-aside-active {
    width: 290px;
  }
}

.header-aside-active.header-aside-active-left {
  transform: translateX(-410px);
  left: 0;
}

.header-aside-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.header-aside-active .header-aside-wrap {
  padding: 155px 70px 20px;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-aside-active .header-aside-wrap {
    padding: 155px 50px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header-aside-active .header-aside-wrap {
    padding: 70px 20px 20px;
  }
}

.header-aside-active .header-aside-wrap .aside-close {
  position: absolute;
  top: 80px;
  left: 70px;
  font-size: 26px;
  line-height: 30px;
  transition: all 250ms ease-out;
  color: #BDBDBD;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-aside-active .header-aside-wrap .aside-close {
    left: 47px;
  }
}

@media only screen and (max-width: 767px) {
  .header-aside-active .header-aside-wrap .aside-close {
    top: 20px;
    left: 20px;
    font-size: 23px;
  }
}

.header-aside-active .header-aside-wrap .aside-close:hover {
  transform: rotate(90deg);
  color: #fc4f4f;
}

.header-aside-active .header-aside-wrap .header-aside-content {
  overflow: auto;
  height: 100%;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area {
    display: block;
  }
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-search {
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  margin-bottom: 27px;
  padding-bottom: 40px;
  margin-right: 15px;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-search form {
  position: relative;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-search form input {
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
  color: #BDBDBD;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-search form button {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.09);
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: #BDBDBD;
  font-size: 15px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-search form button {
    font-size: 16px;
  }
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-search form button:hover {
  color: #fc4f4f;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap {
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  margin-bottom: 36px;
  padding-bottom: 27px;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap .mobile-navigation nav {
  height: 100%;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap .mobile-navigation nav .mobile-menu li {
  display: block;
  position: relative;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  right: 0%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #BDBDBD;
  transition: all 250ms ease-out;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #BDBDBD;
  transition: 0.4s;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a {
  font-size: 15px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
  font-weight: 400;
  color: #BDBDBD;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a {
  padding: 10px 15px 5px;
  font-size: 14px;
  font-weight: 400;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a:hover {
  color: #fc4f4f;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li ul li a {
  padding: 10px 30px 5px;
  font-size: 14px;
  font-weight: 300;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap {
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  margin-bottom: 31px;
  padding-bottom: 37px;
}

@media only screen and (max-width: 767px) {
  .header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap {
    display: block;
  }
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang {
  position: relative;
  margin-bottom: 15px;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
  margin-bottom: 0;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang a {
  font-size: 15px;
  display: block;
  color: #BDBDBD;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang a i {
  float: right;
  font-size: 14px;
  position: relative;
  top: 5px;
  margin-right: 22px;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a {
  font-size: 14px;
  font-weight: 400;
  color: #BDBDBD;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
  color: #fc4f4f;
}

.header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang:hover > a {
  color: #fc4f4f;
}

@media only screen and (max-width: 767px) {
  .header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang {
    margin-bottom: 5px;
  }
  .header-aside-active .header-aside-wrap .header-aside-content .mobile-menu-area .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
    margin-bottom: 0;
  }
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu {
  margin: 0 0 71px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu {
    margin: 0 0 20px;
    display: none;
  }
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li {
  display: block;
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li a {
  font-weight: 500;
  font-size: 18px;
  color: #BDBDBD;
  display: block;
  padding: 0 0 27px;
}

@media only screen and (max-width: 767px) {
  .header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li a {
    font-size: 15px;
    padding: 0 0 16px;
  }
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li a:hover {
  color: #fc4f4f;
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li:last-child a {
  padding: 0 0 0px;
}

.header-aside-active .header-aside-wrap .header-aside-content > p {
  line-height: 1.714286;
  color: #BDBDBD;
  margin: 21px 0 0;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-contact-info {
  margin: 30px 0 23px;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-contact-info ul li {
  color: #BDBDBD;
  display: flex;
  margin: 0 0 23px;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-contact-info ul li:last-child {
  margin: 0 0 0px;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-contact-info ul li i {
  font-size: 22px;
  margin: 1px 16px 0px 0;
}

.social-icon-style a {
  display: inline-block;
  font-size: 12px;
  width: 31px;
  height: 31px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  margin-right: 5px;
  border: 1px solid transparent;
}

.social-icon-style a:last-child {
  margin-right: 0px;
}

.social-icon-style a i {
  line-height: 30px;
  font-size: 12px;
}

.social-icon-style a.facebook {
  background-color: #3b5998;
}

.social-icon-style a.facebook:hover {
  opacity: .9;
  color: #fff;
}

.social-icon-style a.twitter {
  background-color: #1da1f2;
}

.social-icon-style a.twitter:hover {
  opacity: .9;
  color: #fff;
}

.social-icon-style a.google-plus {
  background-color: #dd4b39;
}

.social-icon-style a.google-plus:hover {
  opacity: .9;
  color: #fff;
}

.social-icon-style a.behance {
  background-color: #1769ff;
}

.social-icon-style a.behance:hover {
  opacity: .9;
  color: #fff;
}

.copyright p {
  color: #BDBDBD;
  font-size: 12px;
  margin: 0;
}

.copyright p a {
  color: #BDBDBD;
}

.copyright p a:hover {
  color: #fc4f4f;
}

/* home 2 header */
.header-login-reg a {
  text-transform: uppercase;
  margin-right: 10px;
}

.header-login-reg a i {
  font-size: 14px;
  margin-right: 5px;
}

.header-login-reg a.active {
  color: #535353;
}

.header-login-reg a:hover {
  color: #fc4f4f;
}

.header-login-reg a:last-child {
  margin-right: 0px;
}

.same-style-2 {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  line-height: 80px;
}

.same-style-2 > .same-style2-left span {
  color: #777777;
  text-transform: uppercase;
}

.same-style-2 > .same-style2-right {
  margin-left: 5px;
}

.same-style-2 > .same-style2-right ul li {
  position: relative;
}

.same-style-2 > .same-style2-right ul li a {
  color: #2F2F2F;
}

.same-style-2 > .same-style2-right ul li a i {
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
}

.same-style-2 > .same-style2-right ul li ul {
  position: absolute;
  left: 0;
  top: 100%;
  width: 220px;
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #262626;
  padding: 27px 30px;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .same-style-2 > .same-style2-right ul li ul {
    width: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .same-style-2 > .same-style2-right ul li ul {
    width: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .same-style-2 > .same-style2-right ul li ul {
    width: 190px;
  }
}

.same-style-2 > .same-style2-right ul li ul li {
  display: block;
  line-height: 1;
  margin-bottom: 28px;
}

.same-style-2 > .same-style2-right ul li ul li:last-child {
  margin-bottom: 0px;
}

.same-style-2 > .same-style2-right ul li ul li a {
  display: block;
  color: #ddd;
}

.same-style-2 > .same-style2-right ul li ul li a:hover {
  color: #fc4f4f;
}

.same-style-2 > .same-style2-right ul li:hover ul {
  transform: rotateX(0deg);
  visibility: visible;
}

.same-style-2.store-wrap span a {
  color: #2F2F2F;
}

.same-style-2.store-wrap span a i {
  margin-right: 5px;
}

.same-style-2.store-wrap span a:hover {
  color: #fc4f4f;
}

.clickable-mainmenu-active {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(38, 38, 38, 0.9);
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0s 0.5s;
  z-index: 999;
}

.clickable-mainmenu-active.mainmenu-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;
}

.clickable-mainmenu-active .clickable-mainmenu-width {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close {
  position: fixed;
  top: 20%;
  right: 10%;
  font-size: 40px;
  color: #fff;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close {
    top: 10%;
    right: 3%;
    font-size: 32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close {
    top: 10%;
    right: 3%;
    font-size: 32px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close {
    top: 10%;
    right: 3%;
    font-size: 30px;
  }
}

.clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close:hover {
  color: #fc4f4f;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
  display: flex;
  padding: 0 300px;
  overflow: auto;
  height: 90%;
  justify-content: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
    padding: 0 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
    padding: 0 20px;
  }
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav {
  width: 100%;
  align-items: center;
  display: flex;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul {
  width: 1410px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  display: flex;
  max-height: 90%;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li.has-sub-menu.active > a i {
  opacity: 1;
  visibility: visible;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a {
  color: #fff;
  font-size: 32px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a {
    font-size: 18px;
  }
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a i {
  color: #fff;
  font-size: 14px;
  margin-left: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease 0s;
  top: -4px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a i {
    top: -2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a i {
    top: -2px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a i {
    top: -1px;
    font-size: 12px;
  }
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a:hover {
  color: #fc4f4f;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a:hover i {
  opacity: 1;
  visibility: visible;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul {
  padding: 37px 0;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li {
  padding: 0 0 15px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li a {
  color: #fff;
  display: block;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li a i {
  color: #fff;
  font-size: 14px;
  margin-left: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
  position: relative;
  top: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li a i {
    font-size: 10px;
    margin-left: 8px;
  }
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li a:hover {
  color: #fc4f4f;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li:hover a i {
  opacity: 1;
  visibility: visible;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li.has-sub-menu.active a i {
  font-size: 15px;
  font-weight: 400;
  top: 0px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li ul {
  padding: 23px 0 10px;
}

.clickable-contact {
  position: absolute;
  width: 250px;
  bottom: 80px;
  right: 100px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-contact {
    right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-contact {
    right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-contact {
    right: 0px;
    bottom: 20px;
  }
}

.clickable-contact > ul li {
  font-size: 12px;
  color: #ccc;
  margin: 0 0 22px;
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-contact > ul li {
    margin: 0 0 12px;
  }
}

.clickable-contact > ul li:last-child {
  margin: 0 0 0px;
}

.clickable-contact > ul li i {
  font-size: 24px;
  margin-right: 20px;
  position: relative;
  top: 7px;
}

.clickable-contact .clickable-social {
  margin-top: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-contact .clickable-social {
    margin-top: 20px;
  }
}

.clickable-contact .clickable-social a {
  color: #fff;
  border: 1px solid #777;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  font-size: 14px;
  margin-right: 16px;
  text-align: center;
  border-radius: 100%;
}

.clickable-contact .clickable-social a:hover {
  background-color: #fc4f4f;
  border: 1px solid #fc4f4f;
}

.clickable-contact .clickable-social a i {
  line-height: 32px;
}

.menu-icon span {
  font-size: 24px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.menu-icon span a {
  display: inline-block;
  border: 2px solid #262626;
  font-size: 20px;
  padding: 24px 24px;
  padding: 20px 24px 19px;
  line-height: 1;
  margin-right: 20px;
}

.menu-icon span a:hover {
  background-color: #fc4f4f;
  color: #fff;
  border: 2px solid #fc4f4f;
}

.menu-icon.menu-icon-hm17 span a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 31px 31px 30px;
}

.header-address p {
  color: #535353;
}

.header-address p i {
  font-size: 14px;
  color: #2F2F2F;
  margin-right: 2px;
  position: relative;
  top: 1px;
}

.header-address p span {
  color: #262626;
  margin-left: 6px;
}

.search-content-2 form {
  position: relative;
}

.search-content-2 form input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 60px;
  background-color: transparent;
  color: #444;
  font-size: 16px;
  padding: 3px 80px 3px 30px;
}

.search-content-2 form input::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-content-2 form input::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-content-2 form button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2F2F2F;
  color: #fff;
  font-size: 20px;
  height: 100%;
  border: none;
  padding: 5px 20px;
  transition: all .3s ease 0s;
}

.search-content-2 form button:hover {
  background-color: #fc4f4f;
}

.search-content-2.search-content-2-mrg {
  margin: 0 88px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .search-content-2.search-content-2-mrg {
    margin: 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-content-2.search-content-2-mrg {
    margin: 0 0px;
  }
}

.header-hotline {
  text-align: right;
}

.header-hotline span {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  line-height: 1;
}

.search-content-3 form {
  position: relative;
}

.search-content-3 form input {
  background-color: transparent;
  font-size: 12px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #444;
  height: 32px;
  padding: 2px 30px 10px 0;
}

.search-content-3 form .button-search-3 {
  position: absolute;
  background-color: transparent;
  border: none;
  padding: 0;
  top: 41%;
  right: 0;
  transform: translateY(-50%);
  font-size: 18px;
  color: #2F2F2F;
}

.search-content-3.search-content-3-mrg {
  margin: 0 150px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-content-3.search-content-3-mrg {
    margin: 0 50px 0 0;
  }
}

.social-icon-style-4 a {
  display: inline-block;
  color: #979797;
  font-size: 18px;
  margin-right: 22px;
  line-height: 1;
}

.social-icon-style-4 a:last-child {
  margin-right: 0;
}

.social-icon-style-4 a:hover {
  color: #a8d1dd;
}

.header-offter a {
  color: #262626;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-offter a {
    letter-spacing: 0px;
  }
}

.header-offter a span {
  color: #a8d1dd;
  font-weight: 600;
}

.header-contact-info ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-contact-info ul li {
  margin-left: 30px;
  font-size: 12px;
}

.header-contact-info ul li i {
  color: #535353;
  margin-right: 10px;
  font-size: 14px;
  position: relative;
  top: 3px;
}

.header-contact-info ul li:first-child {
  margin-left: 0px;
}

.header-aside-icon a {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 28px;
  padding: 25px 25px 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-aside-icon a {
    padding: 20px 20px 19px;
    font-size: 25px;
  }
}

.header-aside-icon a:hover {
  background-color: #262626;
  color: #fc4f4f;
  border: 1px solid #262626;
}

.footer-hm12 {
  position: absolute;
  left: 0px;
  width: 100%;
  right: 0px;
  padding: 0 80px;
  bottom: 38px;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-hm12 {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-hm12 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-hm12 {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-hm12 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-hm12 {
    padding: 0 15px;
    bottom: 20px;
  }
}

.footer-hm12-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .footer-hm12-wrap {
    display: block;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-hm12-wrap {
    display: flex;
  }
}

.footer-copyright-hm12 p {
  color: #848484;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}

.footer-social-hm12 a {
  font-size: 18px;
  color: #262626;
  margin-left: 20px;
  display: inline-block;
}

.footer-social-hm12 a:first-child {
  margin-left: 0;
}

.footer-social-hm12 a:hover {
  color: #fc4f4f;
}

.header-sidebar-wrap-all2 {
  padding: 90px 80px 50px;
  background-color: #ffffff;
  width: 400px;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}

.header-sidebar-wrap-all2.header-sidebar-shadow {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all2 {
    padding: 50px 50px 50px;
    width: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 {
    padding: 50px 20px 50px;
    width: 260px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 {
    padding: 50px 20px 50px;
    width: 260px;
  }
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.header-sidebar-wrap-all2 .header-sidebar-wrap-2 .header-mrg-tb {
  margin: 70px 0 70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .header-mrg-tb {
    margin: 40px 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .header-mrg-tb {
    margin: 40px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all2 .header-sidebar-wrap-2 .header-mrg-tb {
    margin: 40px 0 40px;
  }
}

.clickable-mainmenu-style2 {
  margin-bottom: 160px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-style2 {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-style2 {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-style2 {
    margin-bottom: 60px;
  }
}

.clickable-mainmenu-style2 > nav > ul > li {
  padding: 0 0 38px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-style2 > nav > ul > li {
    padding: 0 0 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-style2 > nav > ul > li {
    padding: 0 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-style2 > nav > ul > li {
    padding: 0 0 20px;
  }
}

.clickable-mainmenu-style2 > nav > ul > li:last-child {
  padding: 0 0 0px;
}

.clickable-mainmenu-style2 > nav > ul > li.active > a {
  border-bottom: 2px solid #262626;
}

.clickable-mainmenu-style2 > nav > ul > li > a {
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  border-bottom: 2px solid transparent;
  padding: 0 0 2px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-style2 > nav > ul > li > a {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-style2 > nav > ul > li > a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-style2 > nav > ul > li > a {
    font-size: 16px;
  }
}

.clickable-mainmenu-style2 > nav > ul > li > a span i {
  font-size: 10px;
  color: #262626;
  margin-left: 11px;
  position: relative;
  top: -2px;
}

.clickable-mainmenu-style2 > nav > ul > li ul {
  padding: 36px 0px 3px 20px;
  display: none;
}

.clickable-mainmenu-style2 > nav > ul > li ul li {
  padding-bottom: 8px;
}

.clickable-mainmenu-style2 > nav > ul > li ul li:last-child {
  padding-bottom: 0px;
}

.clickable-mainmenu-style2 > nav > ul > li ul li a {
  color: #535353;
  font-size: 14px;
  display: block;
}

.clickable-mainmenu-style2 > nav > ul > li ul li a span i {
  font-size: 10px;
  margin-left: 5px;
}

.clickable-mainmenu-style2 > nav > ul > li ul li a:hover {
  color: #fc4f4f;
}

body.body-menu-overlay {
  position: relative;
}

body.body-menu-overlay::before {
  content: "";
  background: #232324;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  z-index: 9;
  opacity: .5;
  width: 100%;
}

.header-sidebar-wrap-all3 {
  width: 120px;
  background-color: #fff;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  box-shadow: 0px 0px 60px 0px rgba(41, 44, 58, 0.06);
}

.header-sidebar-wrap-all3 .header-sidebar-wrap-3 {
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
}

.header-sidebar-wrap-all3 .header-sidebar-wrap-3 .logo-15 {
  position: absolute;
  left: 0;
  top: 0;
  width: 180px;
}

.header-sidebar-wrap-all3 .header-sidebar-wrap-3 .logo-15 > a img {
  width: 180px;
}

.header-sidebar-wrap-all3 .header-sidebar-wrap-3 .menu15-icon-position {
  position: absolute;
  top: 46%;
  left: calc(50% - 20px);
}

.header-sidebar-wrap-all3 .header-sidebar-wrap-3 .menu15-icon-position a {
  font-size: 40px;
}

.menu-active-15 {
  position: relative;
}

.menu-active-15 i.m-close {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.menu-active-15.open i.m-open {
  opacity: 0;
}

.menu-active-15.open i.m-close {
  opacity: 1;
}

.clickable-mainmenu-wrapper15 {
  text-align: left;
  transition: all 520ms ease;
  position: fixed;
  height: 100% !important;
  top: 0;
  margin: 0;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  width: 370px;
  padding: 40px 80px;
  left: 0;
  transform: translateX(-370px);
}

.clickable-mainmenu-wrapper15.open {
  margin-left: 120px;
  transform: translateX(0);
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 {
  overflow: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav {
  width: 100%;
  max-height: 100% !important;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav > ul {
  width: 100%;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav > ul > li {
  display: block;
  line-height: 1;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav > ul > li a {
  font-size: 14px;
  display: block;
  text-transform: capitalize;
  padding: 20px 0;
  color: #535353;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav > ul > li a:hover {
  color: #fc4f4f;
  text-decoration: underline;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav > ul > li a i {
  margin-left: 13px;
  position: relative;
  top: 0px;
  font-size: 10px;
  color: #535353;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav > ul > li ul {
  padding: 30px 0 30px 29px;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav > ul > li ul li {
  padding-bottom: 29px;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav > ul > li ul li:last-child {
  padding-bottom: 0;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav > ul > li ul li a {
  padding: 0;
}

.clickable-mainmenu-wrapper15 .clickable-mainmenu-style15 > nav > ul > li ul li ul {
  padding: 25px 0 4px 29px;
}

.main-menu-2 {
  position: absolute;
  left: 80px;
  top: 335px;
  z-index: 9;
  width: 320px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 {
    left: 40px;
    top: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 {
    left: 15px;
    top: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 {
    left: 30px;
    top: 150px;
    width: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-menu-2 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-menu-2 {
    display: none;
  }
}

.main-menu-2 > nav > ul > li {
  padding: 24px 0 24px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li {
    padding: 15px 0 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li {
    padding: 15px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li {
    padding: 13px 0 13px;
  }
}

.main-menu-2 > nav > ul > li > a {
  font-size: 24px;
  color: #262626;
  display: block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li > a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > a {
    font-size: 18px;
  }
}

.main-menu-2 > nav > ul > li > a i {
  float: right;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  top: 5px;
  padding-right: 30px;
}

.main-menu-2 > nav > ul > li > ul {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 100%;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  padding: 55px 60px 40px 60px;
  z-index: 999;
  height: auto;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li > ul {
    padding: 45px 60px 30px 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > ul {
    padding: 45px 60px 30px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul {
    padding: 45px 60px 30px 60px;
  }
}

.main-menu-2 > nav > ul > li > ul.menu-ntv-mrg-top {
  top: -180px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li > ul.menu-ntv-mrg-top {
    top: -100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > ul.menu-ntv-mrg-top {
    top: -100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul.menu-ntv-mrg-top {
    top: -100px;
  }
}

.main-menu-2 > nav > ul > li > ul.menu-ntv-mrg-top2 {
  top: -100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > ul.menu-ntv-mrg-top2 {
    top: -70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul.menu-ntv-mrg-top2 {
    top: -50px;
  }
}

.main-menu-2 > nav > ul > li > ul.mega-menu-width4 {
  width: 680px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width4 {
    width: 660px;
    padding: 45px 40px 30px 40px;
  }
}

.main-menu-2 > nav > ul > li > ul.mega-menu-width4-2 {
  width: 280px;
  padding: 53px 30px 35px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width4-2 {
    padding: 30px 30px 25px 30px;
  }
}

.main-menu-2 > nav > ul > li > ul > li {
  float: left;
  display: block;
}

.main-menu-2 > nav > ul > li > ul > li.mega-menu-sub4-width33 {
  width: 33.333%;
}

.main-menu-2 > nav > ul > li > ul > li.mega-menu-sub4-width46 {
  width: 46%;
}

.main-menu-2 > nav > ul > li > ul > li a.menu-title {
  font-size: 18px;
  display: block;
  margin-bottom: 44px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li > ul > li a.menu-title {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > ul > li a.menu-title {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul > li a.menu-title {
    margin-bottom: 25px;
  }
}

.main-menu-2 > nav > ul > li > ul > li ul li {
  display: block;
  padding-bottom: 23px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li > ul > li ul li {
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > ul > li ul li {
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul > li ul li {
    padding-bottom: 10px;
  }
}

.main-menu-2 > nav > ul > li > ul > li ul li a {
  display: block;
  font-size: 18px;
  color: #777;
  font-weight: 300;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li > ul > li ul li a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > ul > li ul li a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul > li ul li a {
    font-size: 15px;
  }
}

.main-menu-2 > nav > ul > li > ul > li ul li a:hover {
  color: #262626;
}

.main-menu-2 > nav > ul > li > ul > li .banner-menu-content-wrap-2 {
  position: relative;
  overflow: hidden;
}

.main-menu-2 > nav > ul > li > ul > li .banner-menu-content-wrap-2 a img {
  width: 100%;
  transition: opacity 0.35s, transform 0.35s;
}

.main-menu-2 > nav > ul > li > ul > li .banner-menu-content-wrap-2 .banner-menu-content-2 {
  position: absolute;
  left: 40px;
  bottom: 38px;
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul > li .banner-menu-content-wrap-2 .banner-menu-content-2 {
    left: 25px;
    bottom: 23px;
  }
}

.main-menu-2 > nav > ul > li > ul > li .banner-menu-content-wrap-2 .banner-menu-content-2 span {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.main-menu-2 > nav > ul > li > ul > li .banner-menu-content-wrap-2 .banner-menu-content-2 h4 {
  color: #fff;
  font-size: 36px;
  margin: 12px 0 0;
  line-height: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul > li .banner-menu-content-wrap-2 .banner-menu-content-2 h4 {
    font-size: 32px;
  }
}

.main-menu-2 > nav > ul > li > ul > li .banner-menu-content-wrap-2:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.main-menu-2 > nav > ul > li > ul > li .banner-menu-content-wrap-2:hover a img {
  transform: scale(1.05);
}

.main-menu-2 > nav > ul > li:hover > a {
  text-decoration: underline;
  color: #262626;
}

.main-menu-2 > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.main-menu-2-hm16 {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  width: 460px;
  padding: 60px 0px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2-hm16 {
    left: 40px;
    padding: 40px 0px;
    width: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2-hm16 {
    left: 15px;
    width: 300px;
    padding: 30px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2-hm16 {
    left: 30px;
    width: 280px;
    padding: 30px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-menu-2-hm16 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-menu-2-hm16 {
    display: none;
  }
}

.main-menu-2-hm16 > nav > ul > li {
  padding: 19px 0 19px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2-hm16 > nav > ul > li {
    padding: 10px 0 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2-hm16 > nav > ul > li {
    padding: 7px 0 7px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2-hm16 > nav > ul > li {
    padding: 7px 0 7px;
  }
}

.main-menu-2-hm16 > nav > ul > li > a {
  font-size: 18px;
  color: #777;
  display: block;
  padding: 0 30px 0 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2-hm16 > nav > ul > li > a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2-hm16 > nav > ul > li > a {
    font-size: 16px;
    padding: 0 30px;
  }
}

.main-menu-2-hm16 > nav > ul > li > a i {
  float: right;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  top: 5px;
}

.main-menu-2-hm16 > nav > ul > li > ul {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 100%;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  padding: 40px 30px 40px 60px;
  z-index: 999;
  height: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2-hm16 > nav > ul > li > ul {
    height: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2-hm16 > nav > ul > li > ul {
    height: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2-hm16 > nav > ul > li > ul {
    height: auto;
  }
}

.main-menu-2-hm16 > nav > ul > li > ul.mega-menu-width3 {
  width: 880px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2-hm16 > nav > ul > li > ul.mega-menu-width3 {
    width: 800px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2-hm16 > nav > ul > li > ul.mega-menu-width3 {
    width: 800px;
    padding: 30px 20px 22px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2-hm16 > nav > ul > li > ul.mega-menu-width3 {
    width: 660px;
    padding: 30px 20px 22px 20px;
  }
}

.main-menu-2-hm16 > nav > ul > li > ul.mega-menu-width3-2 {
  width: 280px;
  padding: 45px 40px 45px 40px;
}

.main-menu-2-hm16 > nav > ul > li > ul > li {
  float: left;
  display: block;
}

.main-menu-2-hm16 > nav > ul > li > ul > li.mega-menu-sub3-width26 {
  width: 26%;
}

.main-menu-2-hm16 > nav > ul > li > ul > li.mega-menu-sub3-width46 {
  width: 46%;
}

.main-menu-2-hm16 > nav > ul > li > ul > li a.menu-title {
  font-size: 18px;
  display: block;
  margin-bottom: 44px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2-hm16 > nav > ul > li > ul > li a.menu-title {
    margin-bottom: 25px;
  }
}

.main-menu-2-hm16 > nav > ul > li > ul > li ul li {
  display: block;
  padding-bottom: 23px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2-hm16 > nav > ul > li > ul > li ul li {
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2-hm16 > nav > ul > li > ul > li ul li {
    padding-bottom: 15px;
  }
}

.main-menu-2-hm16 > nav > ul > li > ul > li ul li a {
  display: block;
  font-size: 18px;
  color: #777;
  font-weight: 300;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2-hm16 > nav > ul > li > ul > li ul li a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2-hm16 > nav > ul > li > ul > li ul li a {
    font-size: 15px;
  }
}

.main-menu-2-hm16 > nav > ul > li > ul > li ul li a:hover {
  color: #262626;
}

.main-menu-2-hm16 > nav > ul > li > ul > li .banner-menu-content-wrap-2 {
  position: relative;
  overflow: hidden;
}

.main-menu-2-hm16 > nav > ul > li > ul > li .banner-menu-content-wrap-2 a img {
  width: 100%;
  transition: opacity 0.35s, transform 0.35s;
}

.main-menu-2-hm16 > nav > ul > li > ul > li .banner-menu-content-wrap-2 .banner-menu-content-2 {
  position: absolute;
  left: 40px;
  bottom: 38px;
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2-hm16 > nav > ul > li > ul > li .banner-menu-content-wrap-2 .banner-menu-content-2 {
    left: 25px;
    bottom: 23px;
  }
}

.main-menu-2-hm16 > nav > ul > li > ul > li .banner-menu-content-wrap-2 .banner-menu-content-2 span {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.main-menu-2-hm16 > nav > ul > li > ul > li .banner-menu-content-wrap-2 .banner-menu-content-2 h4 {
  color: #fff;
  font-size: 36px;
  margin: 12px 0 0;
  line-height: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2-hm16 > nav > ul > li > ul > li .banner-menu-content-wrap-2 .banner-menu-content-2 h4 {
    font-size: 32px;
  }
}

.main-menu-2-hm16 > nav > ul > li > ul > li .banner-menu-content-wrap-2:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.main-menu-2-hm16 > nav > ul > li > ul > li .banner-menu-content-wrap-2:hover a img {
  transform: scale(1.05);
}

.main-menu-2-hm16 > nav > ul > li:hover > a {
  text-decoration: underline;
  color: #262626;
}

.main-menu-2-hm16 > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

/*--------- mobile menu  -----------*/
.header-small-mobile {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-small-mobile {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-small-mobile {
    display: block;
  }
}

.header-small-mobile {
  padding: 22px 0;
}

.header-small-mobile .mobile-logo.logo-width a img {
  width: 120px;
}

@media only screen and (max-width: 767px) {
  .header-small-mobile .mobile-logo.logo-width a img {
    width: 120px;
  }
}

.category-menu-active {
  position: fixed;
  top: 0;
  width: 300px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  transform: translate(200px, 0);
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
  padding: 50px 30px;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .category-menu-active {
    width: 290px;
  }
}

.category-menu-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.category-menu-active > a.category-menu-close {
  font-size: 26px;
  line-height: 30px;
  transition: all 250ms ease-out;
  color: #333;
}

.category-menu-active > a.category-menu-close:hover {
  color: #fc4f4f;
}

.category-menu-active .category-menu-wrap {
  padding-top: 30px;
}

.category-menu-active .category-menu-wrap.clickable-mainmenu-style2 {
  margin-bottom: 0;
}

.category-menu-active .category-menu-wrap.clickable-mainmenu-style2 > nav ul li {
  padding-bottom: 20px;
}

.category-menu-active .category-menu-wrap.clickable-mainmenu-style2 > nav ul li:last-child {
  padding-bottom: 0;
}

.category-menu-active .category-menu-wrap.clickable-mainmenu-style2 > nav ul li a {
  color: #777;
  font-size: 16px;
}

.category-menu-active .category-menu-wrap.clickable-mainmenu-style2 > nav ul li a:hover {
  color: #262626;
}

.category-menu-active .category-menu-wrap.clickable-mainmenu-style2 > nav ul li a span i {
  color: #777;
}

.category-menu-active .category-menu-wrap.clickable-mainmenu-style2 > nav ul li > ul > li {
  padding-bottom: 10px;
}

.category-menu-active .category-menu-wrap.clickable-mainmenu-style2 > nav ul li > ul > li:last-child {
  padding-bottom: 0;
}

.category-menu-active .category-menu-wrap.clickable-mainmenu-style2 > nav ul li > ul > li > ul {
  padding: 20px 0px 14px 20px;
}

@keyframes dropFadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-sidebar-area {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-sidebar-area {
    display: none;
  }
}

/*-------- 3. Slider style ---------*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area.mt-80 {
    margin-top: 0;
  }
  .slider-area.pt-120 {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area.mt-80 {
    margin-top: 0;
  }
  .slider-area.pt-120 {
    padding-top: 0;
  }
}

.slider-area {
  position: relative;
}

.slider-area img {
  width: 100%;
}

.fullpage-pl-300 {
  padding-left: 300px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-pl-300 {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-pl-300 {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-pl-300 {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-pl-300 {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-pl-300 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.fullpage-pl-345 {
  padding-left: 345px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-pl-345 {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-pl-345 {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-pl-345 {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-pl-345 {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-pl-345 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.fullpage-pl-80 {
  padding-left: 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-pl-80 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-pl-80 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-pl-80 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-pl-80 {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-pl-80 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.fullpage-pl-150 {
  padding-left: 150px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-pl-150 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-pl-150 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-pl-150 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-pl-150 {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-pl-150 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.fullpage-pl-230 {
  padding-left: 230px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-pl-230 {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-pl-230 {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-pl-230 {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-pl-230 {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-pl-230 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-first-slide-content {
    text-align: center;
  }
}

.fullpage-first-slide-content h2 {
  font-size: 36px;
  line-height: 1.4074;
  margin: 0 0 21px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-first-slide-content h2 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-first-slide-content h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-first-slide-content h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-one-img {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
    text-align: center;
  }
}

.fullpage-one-img img {
  width: 837px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-one-img img {
    width: 500px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-one-img img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-one-img img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-one-img img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-one-img img {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .fullpage-one-img img {
    width: 350px;
  }
}

.fullpage-two-img {
  float: right;
  margin-right: 165px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-two-img {
    margin-right: 170px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-two-img {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-two-img {
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 30px;
    text-align: center;
    float: inherit;
  }
}

.fullpage-two-img img {
  width: 665px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-two-img img {
    width: 500px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-two-img img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-two-img img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-two-img img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-two-img img {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .fullpage-two-img img {
    width: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-three-img {
    margin-bottom: 30px;
    text-align: center;
  }
}

.fullpage-three-img img {
  width: 665px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-three-img img {
    width: 550px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-three-img img {
    width: 550px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-three-img img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-three-img img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-three-img img {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .fullpage-three-img img {
    width: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-four-img {
    margin-bottom: 30px;
    text-align: center;
  }
}

.fullpage-four-img img {
  width: 665px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-four-img img {
    width: 500px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-four-img img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-four-img img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-four-img img {
    width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-four-img img {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .fullpage-four-img img {
    width: 320px;
  }
}

.single-full-slider {
  overflow: hidden;
}

#fp-nav.right {
  right: 0px;
}

#fp-nav ul li {
  height: 35px;
  width: 85px;
  margin: 0 0 30px;
}

#fp-nav ul li a span {
  background: transparent;
  margin: 0;
  width: auto;
  height: auto;
  font-weight: 300;
  font-size: 32px;
  left: -46%;
  color: rgba(0, 0, 0, 0.2);
  position: relative;
  transition: all .3s ease 0s;
}

#fp-nav ul li a span:before {
  background-color: #262626;
  content: "";
  height: 2px;
  width: 40px;
  left: -46px;
  top: 22px;
  position: absolute;
  opacity: 0;
  transition: all .3s ease 0s;
}

#fp-nav ul li a span:hover {
  color: black;
}

#fp-nav ul li a.active span {
  margin: 0;
  color: black;
}

#fp-nav ul li a.active span:before {
  opacity: 1;
}

#fp-nav ul li:nth-child(1) a span::after {
  content: "01";
}

#fp-nav ul li:nth-child(2) a span::after {
  content: "02";
}

#fp-nav ul li:nth-child(3) a span::after {
  content: "03";
}

#fp-nav ul li:nth-child(4) a span::after {
  content: "04";
}

#fp-nav ul li:hover a span {
  width: auto;
  height: auto;
  margin: 0;
}

#fp-nav ul li:hover a span:before {
  opacity: 1;
}

#fp-nav ul li:hover a.active span {
  width: auto;
  height: auto;
  margin: 0;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: auto;
  width: auto;
  border-radius: inherit;
}

.slider-content-mrg-l-1 {
  margin-left: 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-mrg-l-1 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-mrg-l-1 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-mrg-l-1 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-mrg-l-1 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-mrg-l-1 {
    margin-left: 0px;
  }
}

.slider-content-mrg-l-2 {
  margin-left: 330px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-mrg-l-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-mrg-l-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-mrg-l-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-mrg-l-2 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-mrg-l-2 {
    margin-left: 0px;
    padding: 30px 15px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-mrg-l-2 {
    padding: 0px 15px 0px;
  }
}

.slider-img-mrg-l-1 {
  margin-left: 220px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-img-mrg-l-1 {
    margin-left: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-mrg-l-1 {
    margin-left: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-mrg-l-1 {
    margin-left: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-mrg-l-1 {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.slider-img-mrg-l-2 {
  margin-left: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-mrg-l-2 {
    margin-left: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-mrg-l-2 {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-mrg-l-2 {
    margin: 0 15px 30px 15px;
  }
}

.main-slider-active {
  position: relative;
}

.owl-item.active .slider-animated-1 span {
  animation-delay: 1.0s;
  animation-name: fadeInDown;
}

.owl-item.active .slider-animated-1 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-1 h5 {
  animation-delay: 1.4s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 a {
  animation-delay: 1.6s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 .slider-conten12-animation-1 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInRight;
}

.owl-item.active .slider-animated-1 .slider-conten12-animation-1 .slider-btn-2 a {
  animation-delay: 1.7s;
  animation-name: fadeInRight;
}

.owl-item.active .slider-animated-1 .slider-conten12-animation-2 .slider-btn-2 a {
  animation-delay: 1.7s;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-1 .slider-bg-text-2-2 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInRight;
}

.owl-item.active .slider-animated-1 .main-slider-content-2-1 h1,
.owl-item.active .slider-animated-1 .main-slider-content-hm5-1 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInDown;
}

.owl-item.active .slider-animated-1 .main-slider-content-3-1 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInDown;
}

.owl-item.active .slider-animated-1 .main-slider-content-16-1 h1 {
  animation-delay: 1.6s;
  animation-name: fadeInDown;
}

.owl-item.active .slider-animated-1 .main-slider-content-3-3 h3,
.owl-item.active .slider-animated-1 .main-slider-content-6-1 h3 {
  animation-delay: 1.0s;
  animation-name: fadeInDown;
}

.owl-item.active .slider-animated-1 .main-slider-content-3-3 h1,
.owl-item.active .slider-animated-1 .main-slider-content-6-1 h1,
.owl-item.active .slider-animated-1 .main-slider-content-11-1 h1,
.owl-item.active .slider-animated-1 .main-slider-content-13-1 h1,
.owl-item.active .slider-animated-1 .main-slider-content-7-1 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInDown;
}

.owl-item.active .slider-animated-1 .slider-hm2-img-animation img,
.owl-item.active .slider-animated-1 .img-position-hm4-4 img,
.owl-item.active .slider-animated-1 .img-position-hm5-1-1 img {
  animation-delay: 1.3s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 .main-slider-content-hm4-1 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 .main-slider-content-3-2 img,
.owl-item.active .slider-animated-1 .img-position-hm12-2-1 img,
.owl-item.active .slider-animated-1 .slider-img-16-1 img,
.owl-item.active .slider-animated-1 .slider-img-16-2 img {
  animation-delay: 1.3s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .slider-bg-text-10-1 h1 {
  animation-delay: 1.2s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .main-slider-content-16-2 h5 {
  animation-delay: 1.3s;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-1 .main-slider-content-16-2 h1 {
  animation-delay: 1.4s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 .content-position-hm4-1,
.owl-item.active .slider-animated-1 .slide-pro-price {
  animation-delay: 1.6s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .main-slider-content-hm5-1 span {
  animation-delay: 1.6s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 .main-slider-content-10-1 h1 {
  animation-delay: 2.6s;
  animation-name: fadeInDown;
}

.owl-item.active .slider-animated-1 .main-slider-content-10-1 .slider-btn-2 a {
  animation-delay: 2.9s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 .main-slider-img-hm10-1 > img {
  animation-delay: 2.4s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .main-slider-img-hm10-1 > .slider-img-hm10-2-position > img {
  animation-delay: 1.9s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .main-slider-img-hm10-3 > img {
  animation-delay: 1.3s;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-1 .img-position-hm5-1-2 > img {
  animation-delay: 1.6s;
  animation-name: fadeInRight;
}

.owl-item.active .slider-animated-1 .img-position-hm5-1-3 > img {
  animation-delay: 1.9s;
  animation-name: fadeInRight;
}

.owl-item.active .slider-animated-1 .img-position-hm5-2-1 > img {
  animation-delay: 2.2s;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-1 .img-position-hm5-2-3 > img {
  animation-delay: 2.6s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .img-position-hm5-3-1 > img {
  animation-delay: 1.9s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 .img-position-hm7-1 > img,
.owl-item.active .slider-animated-1 .img-position-hm4-1 > img,
.owl-item.active .slider-animated-1 .img-position-hm12-1-2 > img,
.owl-item.active .slider-animated-1 .img-position-hm12-3-1 > img {
  animation-delay: 1.3s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .img-position-hm7-2 > img,
.owl-item.active .slider-animated-1 .img-position-hm4-2 > img {
  animation-delay: 1.6s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .img-position-hm7-3 > img,
.owl-item.active .slider-animated-1 .img-position-hm12-2-2 > img,
.owl-item.active .slider-animated-1 .img-position-hm12-3-2 > img {
  animation-delay: 1.9s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .img-position-hm7-4 > img,
.owl-item.active .slider-animated-1 .img-position-hm5-2-2 > img {
  animation-delay: 2.4s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .img-position-hm7-5 > img,
.owl-item.active .slider-animated-1 .img-position-hm7-6 > img {
  animation-delay: 2.1s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-1 .img-position-hm4-3 > img {
  animation-delay: 2.2s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 .img-position-hm4-5 > img {
  animation-delay: 1.8s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 .img-position-hm12-1-1 > img {
  animation-delay: 1.8s;
  animation-name: fadeInDown;
}

.main-slider-content-1 span {
  color: #262626;
  font-size: 18px;
  letter-spacing: 2px;
  display: block;
}

.main-slider-content-1 h1 {
  color: #262626;
  font-size: 109px;
  margin: 25px 0 74px;
  line-height: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-1 h1 {
    font-size: 95px;
    margin: 25px 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-1 h1 {
    font-size: 75px;
    line-height: 60px;
    margin: 25px 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-1 h1 {
    font-size: 60px;
    margin: 20px 0 30px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-1 h1 {
    font-size: 40px;
    margin: 15px 0 10px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-1 {
    padding: 40px 0;
  }
}

.slider-btn-1 a {
  display: inline-block;
  line-height: 1;
  position: relative;
  font-weight: 700;
  font-size: 12px;
  color: #262626;
  margin-left: 400px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-btn-1 a {
    margin-left: 250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-btn-1 a {
    margin-left: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-btn-1 a {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-btn-1 a {
    margin-left: 50px;
  }
}

.slider-btn-1 a:before {
  background-color: #262626;
  height: 2px;
  width: 378px;
  position: absolute;
  content: "";
  left: -399px;
  top: 6px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-btn-1 a:before {
    left: -250px;
    width: 230px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-btn-1 a:before {
    left: -200px;
    width: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-btn-1 a:before {
    left: -50px;
    width: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-btn-1 a:before {
    left: -50px;
    width: 40px;
  }
}

.slider-dot-style-1.slider-dot-position-1 .owl-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-dot-style-1.slider-dot-position-1 .owl-dots {
    bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-dot-style-1.slider-dot-position-1 .owl-dots {
    bottom: 30px;
    right: 0;
    left: auto;
  }
}

.slider-dot-style-1.slider-dot-position-2 .owl-dots {
  position: absolute;
  right: 150px;
  bottom: 12%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-dot-style-1.slider-dot-position-2 .owl-dots {
    bottom: 3%;
    right: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-dot-style-1.slider-dot-position-2 .owl-dots {
    right: 80px;
    bottom: 3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-dot-style-1.slider-dot-position-2 .owl-dots {
    right: 80px;
    bottom: 3%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-dot-style-1.slider-dot-position-2 .owl-dots {
    right: 10px;
  }
}

.slider-dot-style-1.slider-dot-position-4 .owl-dots {
  position: absolute;
  right: 82px;
  bottom: 9%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-dot-style-1.slider-dot-position-4 .owl-dots {
    right: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-dot-style-1.slider-dot-position-4 .owl-dots {
    right: 100px;
    bottom: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-dot-style-1.slider-dot-position-4 .owl-dots {
    right: 10px;
  }
}

.slider-dot-style-1.slider-dot-hm12 .owl-dots {
  position: absolute;
  right: 82px;
  bottom: 9%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-dot-style-1.slider-dot-hm12 .owl-dots {
    right: 100px;
    bottom: 12%;
  }
  .slider-dot-style-1.slider-dot-hm12 .owl-dots .owl-dot {
    margin-bottom: 5px;
    padding-bottom: 5px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-dot-style-1.slider-dot-hm12 .owl-dots {
    right: 100px;
    bottom: 12%;
  }
  .slider-dot-style-1.slider-dot-hm12 .owl-dots .owl-dot {
    margin-bottom: 5px;
    padding-bottom: 5px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-dot-style-1.slider-dot-hm12 .owl-dots {
    right: 90px;
    bottom: 12%;
  }
  .slider-dot-style-1.slider-dot-hm12 .owl-dots .owl-dot {
    margin-bottom: 5px;
    padding-bottom: 5px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-dot-style-1.slider-dot-hm12 .owl-dots {
    right: 100px;
    bottom: 12%;
  }
  .slider-dot-style-1.slider-dot-hm12 .owl-dots .owl-dot {
    margin-bottom: 5px;
    padding-bottom: 5px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-dot-style-1.slider-dot-hm12 .owl-dots {
    right: 30px;
  }
}

.slider-dot-style-1 .owl-dots .owl-dot {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  transition: all .3s;
  position: relative;
  text-align: right;
  font-weight: bold;
  margin-bottom: 13px;
  padding-bottom: 9px;
  margin-right: 35px;
}

@media only screen and (max-width: 767px) {
  .slider-dot-style-1 .owl-dots .owl-dot {
    margin-bottom: 3px;
    padding-bottom: 3px;
    margin-right: 0px;
    font-size: 18px;
  }
}

.slider-dot-style-1 .owl-dots .owl-dot::before {
  position: absolute;
  transition: all .3s;
  z-index: 1;
  width: 30px;
  height: 2px;
  background: #F53E6A;
  bottom: 0;
  right: 0;
  content: "";
  opacity: 0;
}

.slider-dot-style-1 .owl-dots .owl-dot:hover, .slider-dot-style-1 .owl-dots .owl-dot.active {
  color: #2f2f2f;
}

.slider-dot-style-1 .owl-dots .owl-dot:hover::before, .slider-dot-style-1 .owl-dots .owl-dot.active::before {
  opacity: 1;
  width: 80px;
}

@media only screen and (max-width: 767px) {
  .slider-dot-style-1 .owl-dots .owl-dot:hover::before, .slider-dot-style-1 .owl-dots .owl-dot.active::before {
    width: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-dot-style-1.slider-dot-hm2 .owl-dots .owl-dot {
    margin-bottom: 3px;
    padding-bottom: 3px;
    margin-right: 0px;
    font-size: 18px;
  }
}

.slider-social {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .slider-social {
    display: none;
  }
}

.slider-social ul li {
  display: block;
  height: 160px;
  width: 100%;
  text-align: center;
}

.slider-social ul li a {
  text-transform: uppercase;
  letter-spacing: 3px;
  transform: rotate(-90deg);
  display: block;
  padding-top: 17px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-social ul li a {
    padding-top: 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-social ul li a {
    padding-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-social ul li a {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-social ul li a {
    padding-top: 0px;
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-social ul li {
    height: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-social ul li {
    height: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-social ul li {
    height: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-social.slider-social-white ul li a {
    color: #fff;
  }
}

.single-main-slider {
  position: relative;
  overflow: hidden;
}

.single-main-slider .slider-bg-text-2-1 {
  position: absolute;
  top: 20px;
  left: 0;
}

.single-main-slider .slider-bg-text-2-1 h1 {
  color: rgba(249, 227, 227, 0.9);
  font-size: 545px;
  font-weight: 700;
  margin: 0;
  line-height: 403px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-main-slider .slider-bg-text-2-1 h1 {
    font-size: 445px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-main-slider .slider-bg-text-2-1 h1 {
    font-size: 245px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-main-slider .slider-bg-text-2-1 h1 {
    font-size: 200px;
    line-height: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-main-slider .slider-bg-text-2-1 h1 {
    font-size: 200px;
    line-height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .single-main-slider .slider-bg-text-2-1 h1 {
    font-size: 145px;
    line-height: 145px;
  }
}

.single-main-slider .slider-bg-text-2-2 {
  position: absolute;
  bottom: 50px;
  left: 115px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-main-slider .slider-bg-text-2-2 {
    left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-main-slider .slider-bg-text-2-2 {
    left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-main-slider .slider-bg-text-2-2 {
    left: 0px;
  }
}

.single-main-slider .slider-bg-text-2-2 h1 {
  color: rgba(249, 227, 227, 0.9);
  font-size: 545px;
  font-weight: 700;
  margin: 0;
  line-height: 403px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-main-slider .slider-bg-text-2-2 h1 {
    font-size: 445px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-main-slider .slider-bg-text-2-2 h1 {
    font-size: 245px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-main-slider .slider-bg-text-2-2 h1 {
    font-size: 200px;
    line-height: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-main-slider .slider-bg-text-2-2 h1 {
    font-size: 200px;
    line-height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .single-main-slider .slider-bg-text-2-2 h1 {
    font-size: 100px;
    line-height: 100px;
  }
}

.single-main-slider .slider-bg-text-2-3 {
  position: absolute;
  top: 270px;
  left: -48%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-main-slider .slider-bg-text-2-3 {
    top: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-main-slider .slider-bg-text-2-3 {
    top: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-main-slider .slider-bg-text-2-3 {
    top: 70px;
    left: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .single-main-slider .slider-bg-text-2-3 {
    top: 70px;
    left: 0%;
  }
}

.single-main-slider .slider-bg-text-2-3 h1 {
  color: rgba(38, 38, 38, 0.1);
  font-size: 347px;
  font-weight: 700;
  line-height: 300px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-main-slider .slider-bg-text-2-3 h1 {
    font-size: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-main-slider .slider-bg-text-2-3 h1 {
    font-size: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-main-slider .slider-bg-text-2-3 h1 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-main-slider .slider-bg-text-2-3 h1 {
    font-size: 100px;
    line-height: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .single-main-slider .slider-bg-text-2-3 h1 {
    font-size: 50px;
    line-height: 120px;
  }
}

.single-main-slider .slider-bg-text-2-4 {
  position: absolute;
  bottom: 220px;
  left: -10%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-main-slider .slider-bg-text-2-4 {
    bottom: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-main-slider .slider-bg-text-2-4 {
    bottom: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-main-slider .slider-bg-text-2-4 {
    bottom: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-main-slider .slider-bg-text-2-4 {
    bottom: 20px;
  }
}

.single-main-slider .slider-bg-text-2-4 h1 {
  color: rgba(38, 38, 38, 0.1);
  font-size: 347px;
  font-weight: 700;
  line-height: 300px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-main-slider .slider-bg-text-2-4 h1 {
    font-size: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-main-slider .slider-bg-text-2-4 h1 {
    font-size: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-main-slider .slider-bg-text-2-4 h1 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-main-slider .slider-bg-text-2-4 h1 {
    font-size: 100px;
    line-height: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .single-main-slider .slider-bg-text-2-4 h1 {
    font-size: 50px;
    line-height: 60px;
  }
}

.single-main-slider .slider-bg-text-2-5 {
  position: absolute;
  top: 240px;
  left: 0%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-main-slider .slider-bg-text-2-5 {
    top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-main-slider .slider-bg-text-2-5 {
    top: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-main-slider .slider-bg-text-2-5 {
    top: 100px;
  }
}

.single-main-slider .slider-bg-text-2-5 h1 {
  color: rgba(38, 38, 38, 0.08);
  font-size: 347px;
  font-weight: 700;
  line-height: 345px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-main-slider .slider-bg-text-2-5 h1 {
    font-size: 200px;
    line-height: 205px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-main-slider .slider-bg-text-2-5 h1 {
    font-size: 150px;
    line-height: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-main-slider .slider-bg-text-2-5 h1 {
    font-size: 150px;
    line-height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .single-main-slider .slider-bg-text-2-5 h1 {
    font-size: 70px;
    line-height: 100px;
  }
}

.main-slider-content-2-1 span {
  font-size: 18px;
  display: inline-block;
  padding-bottom: 5px;
  color: #262626;
  letter-spacing: 2px;
  border-bottom: 5px solid #262626;
}

.main-slider-content-2-1 h1 {
  font-size: 109px;
  color: #262626;
  letter-spacing: 2px;
  line-height: 84px;
  margin: 42px 0 48px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-2-1 h1 {
    font-size: 80px;
    margin: 25px 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-2-1 h1 {
    font-size: 70px;
    margin: 20px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-2-1 h1 {
    font-size: 40px;
    line-height: 34px;
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-2-1 h1 {
    font-size: 30px;
    line-height: 30px;
    margin: 20px 0 10px;
  }
}

.main-slider-content-2-1 h1.slider-content-2-font-dec {
  font-size: 79px;
  line-height: 90px;
  margin: 42px 0 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-slider-content-2-1 h1.slider-content-2-font-dec {
    font-size: 60px;
    line-height: 75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-2-1 h1.slider-content-2-font-dec {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-2-1 h1.slider-content-2-font-dec {
    font-size: 50px;
    line-height: 60px;
    margin: 20px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-2-1 h1.slider-content-2-font-dec {
    font-size: 30px;
    line-height: 40px;
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-2-1 h1.slider-content-2-font-dec {
    font-size: 23px;
    line-height: 30px;
    margin: 10px 0 10px;
  }
}

.main-slider-content-2-1 h5 {
  font-size: 24px;
  color: #fc4f4f;
  font-weight: 500;
  margin: 0 0 31px;
}

@media only screen and (max-width: 767px) {
  .main-slider-content-2-1 h5 {
    margin: 0 0 20px;
  }
}

.slider-btn-2 a {
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  color: #fff;
  background-color: #262626;
  text-transform: uppercase;
  padding: 20px 35px 21px;
}

@media only screen and (max-width: 767px) {
  .slider-btn-2 a {
    padding: 12px 15px 13px;
  }
}

.slider-btn-2 a:hover {
  background-color: #fc4f4f;
}

.slider-btn-2.slider-btn-2-white a {
  color: #262626;
  background-color: #fff;
}

.slider-btn-2.slider-btn-2-white a:hover {
  background-color: #fc4f4f;
  color: #fff;
}

.slider-btn-2.slider-btn-2-border-white a {
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  padding: 16px 35px 17px;
}

@media only screen and (max-width: 767px) {
  .slider-btn-2.slider-btn-2-border-white a {
    padding: 12px 18px 13px;
  }
}

.slider-btn-2.slider-btn-2-border-white a:hover {
  background-color: #fc4f4f;
  color: #fff;
  border: 2px solid #fc4f4f;
}

.slider-btn-2.slider-btn-2-border-black a {
  color: #262626;
  background-color: transparent;
  border: 2px solid #262626;
  padding: 16px 35px 17px;
}

@media only screen and (max-width: 767px) {
  .slider-btn-2.slider-btn-2-border-black a {
    padding: 12px 18px 13px;
  }
}

.slider-btn-2.slider-btn-2-border-black a:hover {
  background-color: #fc4f4f;
  color: #fff;
  border: 2px solid #fc4f4f;
}

.slider-content-ntvmrg-1 {
  margin-left: -107px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-ntvmrg-1 {
    margin-left: 0px;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-ntvmrg-1 {
    margin-left: 0px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-ntvmrg-1 {
    margin-left: 15px;
    margin-right: 15px;
    padding: 0px 0 30px;
  }
}

.slider-content-ntvmrg-2 {
  margin-left: -50px;
}

@media only screen and (max-width: 767px) {
  .slider-content-ntvmrg-2 {
    margin-left: 0;
    padding: 0px 15px 30px;
  }
}

.slider-height-hm2 {
  height: 1175px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-hm2 {
    height: 840px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-hm2 {
    height: 740px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-hm2 {
    height: 610px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-hm2 {
    height: 470px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-hm2 {
    height: 650px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-hm2 {
    height: 400px;
  }
}

.slider-height-hm3 {
  height: 932px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-hm3 {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-hm3 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-hm3 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-hm3 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-hm3 {
    height: 400px;
  }
}

.main-slider-content-3-1 {
  position: relative;
  z-index: 9;
}

.main-slider-content-3-1 span {
  font-size: 18px;
  display: inline-block;
  padding-bottom: 5px;
  color: #262626;
  letter-spacing: 2px;
  border-bottom: 5px solid #262626;
}

.main-slider-content-3-1 h1 {
  font-size: 36px;
  color: #262626;
  line-height: 45px;
  margin: 42px 0 16px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-3-1 h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-3-1 h1 {
    font-size: 27px;
    line-height: 40px;
    margin: 22px 0 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-3-1 h1 {
    font-size: 27px;
    line-height: 40px;
    margin: 22px 0 16px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-3-1 h1 {
    font-size: 18px;
    line-height: 32px;
    margin: 15px 0 15px;
  }
}

.main-slider-content-3-1 h5 {
  font-size: 20px;
  color: #fc4f4f;
  font-weight: 600;
  margin: 0 0 27px;
}

.main-slider-content-3-1.main-slider-content-hm5-2 {
  position: relative;
  z-index: 9;
}

.main-slider-content-3-1.main-slider-content-hm5-2 h1 {
  margin: 16px 0 22px;
}

.slider-hm3-content-3-1 {
  margin-left: -135px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-hm3-content-3-1 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-hm3-content-3-1 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-hm3-content-3-1 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-hm3-content-3-1 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-hm3-content-3-1 {
    margin-left: 0px;
  }
}

.main-slider-content-3-2 img {
  margin-bottom: 30px;
}

.slider-img-width-hm3.owl-carousel .owl-item .main-slider-img {
  text-align: center;
}

.slider-img-width-hm3.owl-carousel .owl-item img {
  text-align: center;
  display: inline-block;
  width: 1058px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-img-width-hm3.owl-carousel .owl-item img {
    width: 800px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-img-width-hm3.owl-carousel .owl-item img {
    width: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-width-hm3.owl-carousel .owl-item img {
    width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-width-hm3.owl-carousel .owl-item img {
    width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-width-hm3.owl-carousel .owl-item img {
    width: 200px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-img-width-hm3.owl-carousel .owl-item img {
    width: 350px;
  }
}

.main-slider-content-3-3 h3 {
  font-size: 36px;
  color: #fff;
  margin: 0;
  line-height: 32px;
}

@media only screen and (max-width: 767px) {
  .main-slider-content-3-3 h3 {
    font-size: 30px;
  }
}

.main-slider-content-3-3 h1 {
  font-size: 139px;
  color: #fff;
  margin: 62px 0 45px;
  line-height: 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-3-3 h1 {
    margin: 32px 0 35px;
    font-size: 100px;
    line-height: 88px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-3-3 h1 {
    margin: 32px 0 35px;
    font-size: 80px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-3-3 h1 {
    margin: 32px 0 35px;
    font-size: 80px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-3-3 h1 {
    margin: 20px 0 20px;
    font-size: 60px;
    line-height: 50px;
  }
}

.slider-height-hm6 {
  height: 908px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-hm6 {
    height: 651px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-hm6 {
    height: 572px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-hm6 {
    height: 473px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-hm6 {
    height: 560px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-hm6 {
    height: 400px;
  }
}

.main-slider-content-6-1 h3 {
  font-size: 45px;
  color: #fff;
  margin: 0;
  line-height: 40px;
}

@media only screen and (max-width: 767px) {
  .main-slider-content-6-1 h3 {
    font-size: 30px;
    line-height: 28px;
  }
}

.main-slider-content-6-1 h1 {
  font-size: 207px;
  color: #fff;
  letter-spacing: 2px;
  margin: 50px 0 47px;
  line-height: 172px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-slider-content-6-1 h1 {
    font-size: 150px;
    line-height: 125px;
    margin: 30px 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-6-1 h1 {
    font-size: 150px;
    line-height: 125px;
    margin: 30px 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-6-1 h1 {
    font-size: 100px;
    line-height: 90px;
    margin: 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-6-1 h1 {
    font-size: 100px;
    line-height: 90px;
    margin: 30px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-6-1 h1 {
    font-size: 60px;
    line-height: 50px;
    margin: 20px 0 20px;
  }
}

.slider-nav-style-1 .owl-nav div {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  transition: all .3s ease 0s;
  z-index: 9;
}

.slider-nav-style-1 .owl-nav div:hover {
  background: black;
}

.slider-nav-style-1.slider-nav-position-1 .owl-nav div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-1.slider-nav-position-1 .owl-nav div {
    left: 15px;
  }
}

.slider-nav-style-1.slider-nav-position-1 .owl-nav div.owl-next {
  left: auto;
  right: 30px;
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-1.slider-nav-position-1 .owl-nav div.owl-next {
    right: 15px;
  }
}

.slider-nav-style-1.slider-nav-position-2 .owl-nav {
  position: absolute;
  bottom: 85px;
  right: 110px;
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-1.slider-nav-position-2 .owl-nav {
    right: 0;
    left: 0;
    text-align: center;
    bottom: 30px;
  }
}

.slider-nav-style-1.slider-nav-position-2 .owl-nav div {
  margin: 0 5px;
}

.slider-nav-style-1.nav-style-1-wd-inc .owl-nav div {
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-1.nav-style-1-wd-inc .owl-nav div {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
  }
}

.slider-nav-style-2 .owl-nav div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.38);
  font-size: 50px;
  transition: all .3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-2 .owl-nav div {
    left: 15px;
  }
}

.slider-nav-style-2 .owl-nav div.owl-next {
  left: auto;
  right: 30px;
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-2 .owl-nav div.owl-next {
    right: 15px;
  }
}

.slider-nav-style-2 .owl-nav div:hover {
  color: black;
}

.slider-nav-style-2:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.slider-height-hm10 {
  height: 650px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-hm10 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-hm10 {
    height: 500px;
  }
  .slider-height-hm10.pt-30 {
    padding-top: 0;
  }
}

.slider-height-hm11 {
  height: 773px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-hm11 {
    height: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-hm11 {
    height: 550px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-hm11 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-hm11 {
    height: 480px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-hm11 {
    height: 400px;
  }
}

.slider-bg-text-10-1 {
  position: absolute;
  left: -155px;
  top: 12px;
  right: 0;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-bg-text-10-1 {
    left: -80px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-bg-text-10-1 {
    left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-bg-text-10-1 {
    top: 30px;
  }
}

.slider-bg-text-10-1 h1 {
  font-size: 167px;
  color: rgba(45, 45, 45, 0.05);
  line-height: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-bg-text-10-1 h1 {
    font-size: 100px;
    line-height: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-bg-text-10-1 h1 {
    font-size: 50px;
    line-height: 65px;
  }
}

.main-slider-img-hm10-1 {
  position: relative;
  text-align: center;
}

.main-slider-img-hm10-1 > img {
  position: relative;
  z-index: 99;
  margin-right: 55px;
}

@media only screen and (max-width: 767px) {
  .main-slider-img-hm10-1 > img {
    margin-right: 40px;
  }
}

.main-slider-img-hm10-1 .slider-img-hm10-2-position {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 9;
}

.main-slider-img-hm10-1 .slider-img-hm10-2-position img {
  width: 398px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-img-hm10-1 .slider-img-hm10-2-position img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-img-hm10-1 .slider-img-hm10-2-position img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-img-hm10-1 {
    margin-bottom: 20px;
  }
}

.owl-carousel .owl-item .main-slider-img-hm10-1 > img {
  width: 256px;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .main-slider-img-hm10-1 > img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .main-slider-img-hm10-1 > img {
    width: 120px;
  }
}

.owl-carousel .owl-item .main-slider-img-hm10-3 {
  margin-left: 103px;
}

.owl-carousel .owl-item .main-slider-img-hm10-3 > img {
  width: 424px;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .main-slider-img-hm10-3 > img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .main-slider-img-hm10-3 > img {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .main-slider-img-hm10-3 {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .main-slider-img-hm10-3 {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .main-slider-img-hm10-3 {
    margin-left: 0px;
    text-align: center;
  }
}

.main-slider-content-10-1 h1 {
  color: #262626;
  font-size: 32px;
  line-height: 45px;
  margin: 0 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-10-1 h1 {
    font-size: 25px;
    line-height: 35px;
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-10-1 h1 {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 20px;
  }
}

.main-slider-content-10-1.slider-content-10-1-mrg {
  margin-right: 170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-10-1.slider-content-10-1-mrg {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-10-1.slider-content-10-1-mrg {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-10-1.slider-content-10-1-mrg {
    margin-right: 0px;
  }
}

.main-slider-content-10-1.slider-content-10-2-mrg {
  margin-right: 206px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-10-1.slider-content-10-2-mrg {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-10-1.slider-content-10-2-mrg {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-10-1.slider-content-10-2-mrg {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

.main-slider-content-11-1 h1 {
  font-size: 79px;
  color: #fff;
  margin: 0 0 53px;
  line-height: 70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-slider-content-11-1 h1 {
    font-size: 75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-11-1 h1 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-11-1 h1 {
    font-size: 60px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-11-1 h1 {
    font-size: 50px;
    line-height: 40px;
    margin: 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-11-1 h1 {
    font-size: 26px;
    line-height: 35px;
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-content-11-1 h1 {
    font-size: 35px;
    line-height: 40px;
    margin: 0 0 30px;
  }
}

.slider-dot-style-2.slider-dot-position-3 .owl-dots {
  position: absolute;
  right: 0px;
  left: 0;
  text-align: center;
  bottom: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-dot-style-2.slider-dot-position-3 .owl-dots {
    bottom: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-dot-style-2.slider-dot-position-3 .owl-dots {
    bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-dot-style-2.slider-dot-position-3 .owl-dots {
    bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-dot-style-2.slider-dot-position-3 .owl-dots {
    bottom: 30px;
  }
}

.slider-dot-style-2 .owl-dots .owl-dot {
  background: rgba(0, 0, 0, 0.22);
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 5px;
  transition: all .3s ease 0s;
  position: relative;
}

.slider-dot-style-2 .owl-dots .owl-dot.active, .slider-dot-style-2 .owl-dots .owl-dot:hover {
  background: rgba(0, 0, 0, 0.83);
}

.slider-dot-style-2 .owl-dots .owl-dot:before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -40px;
  width: 33px;
  height: 20px;
  line-height: 18px;
  background-color: #fff;
  color: #262626;
  font-size: 12px;
  font-weight: 600;
  transition: all .3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.slider-dot-style-2 .owl-dots .owl-dot::after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 9px solid #fff;
  position: absolute;
  content: "";
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  transition: all .3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.slider-dot-style-2 .owl-dots .owl-dot:nth-child(1):before {
  content: "01";
}

.slider-dot-style-2 .owl-dots .owl-dot:nth-child(2):before {
  content: "02";
}

.slider-dot-style-2 .owl-dots .owl-dot:nth-child(3):before {
  content: "03";
}

.slider-dot-style-2 .owl-dots .owl-dot:hover:before, .slider-dot-style-2 .owl-dots .owl-dot:hover:after {
  opacity: 1;
  visibility: visible;
}

.main-slider-content-13-1 h1 {
  font-size: 64px;
  margin: 0 0 40px;
  line-height: 86px;
}

.main-slider-content-13-1 h1.white-text {
  color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-13-1 h1 {
    font-size: 55px;
    line-height: 75px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-13-1 h1 {
    font-size: 44px;
    line-height: 60px;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-13-1 h1 {
    font-size: 44px;
    line-height: 60px;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-13-1 h1 {
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-content-13-1 h1 {
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 30px;
  }
}

.main-slider-content-13-1.slider-content-13-mrg {
  margin-left: 130px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-slider-content-13-1.slider-content-13-mrg {
    margin-left: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-13-1.slider-content-13-mrg {
    margin-left: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-13-1.slider-content-13-mrg {
    margin-left: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-13-1.slider-content-13-mrg {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-13-1.slider-content-13-mrg {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-content-13-1.slider-content-13-mrg {
    margin-left: 40px;
  }
}

.slider-btn-3 a {
  display: inline-block;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: underline;
}

.slider-btn-3.white-btn a {
  color: #fff;
}

.slider-btn-3.white-btn a:hover {
  color: #fc4f4f;
}

.img-position-hm7-1 {
  position: absolute;
  left: 2.5%;
  bottom: 5.5%;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .img-position-hm7-1 {
    left: 9.5%;
  }
}

.img-position-hm7-2 {
  position: absolute;
  left: 31.7%;
  bottom: 0%;
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .img-position-hm7-2 {
    left: 36.7%;
  }
}

.img-position-hm7-3 {
  position: absolute;
  left: 0%;
  bottom: 0%;
  z-index: 2;
}

.img-position-hm7-4 {
  position: absolute;
  left: 20%;
  bottom: 12%;
  z-index: 1;
}

.img-position-hm7-5 {
  position: absolute;
  left: 14.5%;
  bottom: 20%;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-position-hm7-5 {
    left: 6.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-position-hm7-5 {
    left: 4.5%;
  }
}

.img-position-hm7-6 {
  position: absolute;
  left: 15.7%;
  bottom: 0%;
  z-index: 3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-position-hm7-6 {
    left: 10.7%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm7-6 {
    left: 0;
    right: 0;
    text-align: center;
  }
}

.owl-carousel .owl-item .img-position-hm7-1 img {
  width: 859px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm7-1 img {
    width: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm7-1 img {
    width: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm7-1 img {
    width: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm7-1 img {
    width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-1 img {
    width: 250px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-1 img {
    width: 280px;
  }
}

.owl-carousel .owl-item .img-position-hm7-2 img {
  width: 555px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm7-2 img {
    width: 455px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm7-2 img {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm7-2 img {
    width: 355px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm7-2 img {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-2 img {
    width: 200px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-2 img {
    width: 300px;
  }
}

.owl-carousel .owl-item .img-position-hm7-3 img {
  width: 378px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm7-3 img {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm7-3 img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-3 img {
    width: 150px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-3 img {
    width: 200px;
  }
}

.owl-carousel .owl-item .img-position-hm7-4 img {
  width: 900px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm7-4 img {
    width: 660px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm7-4 img {
    width: 460px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm7-4 img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm7-4 img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-4 img {
    width: 200px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-4 img {
    width: 350px;
  }
}

.owl-carousel .owl-item .img-position-hm7-5 img {
  width: 907px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm7-5 img {
    width: 707px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm7-5 img {
    width: 607px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm7-5 img {
    width: 507px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm7-5 img {
    width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-5 img {
    width: 250px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-5 img {
    width: 350px;
  }
}

.owl-carousel .owl-item .img-position-hm7-6 img {
  width: 556px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm7-6 img {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm7-6 img {
    width: 360px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm7-6 img {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm7-6 img {
    width: 256px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm7-6 img {
    width: 160px;
    display: inline-block;
  }
}

.main-slider-content-7-1 {
  position: relative;
  z-index: 9;
  padding: 0 80px 0 125px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-7-1 {
    padding: 0 80px 0 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-7-1 {
    padding: 0 80px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-7-1 {
    padding: 0 80px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-7-1 {
    padding: 50px 0px 0 0px;
  }
}

.main-slider-content-7-1 span {
  color: #262626;
  font-size: 24px;
  display: block;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .main-slider-content-7-1 span {
    font-size: 20px;
  }
}

.main-slider-content-7-1 h1 {
  color: #262626;
  font-size: 63px;
  line-height: 60px;
  margin: 20px 0 53px;
  line-height: 85px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-7-1 h1 {
    font-size: 40px;
    margin: 12px 0 30px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-7-1 h1 {
    font-size: 40px;
    margin: 12px 0 30px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-7-1 h1 {
    font-size: 28px;
    margin: 5px 0 20px;
    line-height: 44px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-7-1 h1 {
    font-size: 25px;
    margin: 5px 0 20px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-content-7-1 h1 {
    font-size: 28px;
  }
}

.slider-btn-4 a {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  color: #262626;
}

.slider-btn-4 a:hover {
  color: #fc4f4f;
}

.slider-btn-4 a i {
  font-size: 15px;
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.slider-height-hm7 {
  height: 1119px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-hm7 {
    height: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-hm7 {
    height: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-hm7 {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-hm7 {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-hm7 {
    height: 480px;
  }
  .slider-height-hm7.align-items-center {
    align-items: start !important;
  }
}

.slider-height-hm4 {
  height: 1119px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-hm4 {
    height: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-hm4 {
    height: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-hm4 {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-hm4 {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-hm4 {
    height: 480px;
  }
  .slider-height-hm4.align-items-center {
    align-items: start !important;
  }
}

.img-position-hm4-1 {
  position: absolute;
  left: 10.5%;
  bottom: 14.5%;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .img-position-hm4-1 {
    left: 0%;
    right: 0;
    text-align: center;
  }
}

.img-position-hm4-2 {
  position: absolute;
  left: 0%;
  bottom: 0%;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .img-position-hm4-2 {
    left: 0%;
    right: 0;
    text-align: center;
  }
}

.img-position-hm4-3 {
  position: absolute;
  left: 18.5%;
  bottom: 0%;
  z-index: 3;
}

@media only screen and (max-width: 767px) {
  .img-position-hm4-3 {
    left: 0%;
    right: 0;
    text-align: center;
  }
}

.img-position-hm4-4 {
  position: absolute;
  left: 0;
  bottom: 0%;
  z-index: 1;
}

.img-position-hm4-5 {
  position: absolute;
  left: 17%;
  bottom: 0%;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .img-position-hm4-5 {
    left: 0%;
    right: 0;
    text-align: center;
  }
}

.owl-carousel .owl-item .img-position-hm4-1 img {
  width: 772px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm4-1 img {
    width: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm4-1 img {
    width: 550px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm4-1 img {
    width: 450px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm4-1 img {
    width: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm4-1 img {
    width: 280px;
    display: inline-block;
  }
}

.owl-carousel .owl-item .img-position-hm4-2 img {
  width: 1054px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm4-2 img {
    width: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm4-2 img {
    width: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm4-2 img {
    width: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm4-2 img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm4-2 img {
    width: 300px;
    display: inline-block;
  }
}

.owl-carousel .owl-item .img-position-hm4-3 img {
  width: 469px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm4-3 img {
    width: 320px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm4-3 img {
    width: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm4-3 img {
    width: 270px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm4-3 img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm4-3 img {
    width: 150px;
    display: inline-block;
  }
}

.owl-carousel .owl-item .img-position-hm4-4 img {
  width: 100%;
}

.owl-carousel .owl-item .img-position-hm4-5 img {
  width: 581px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm4-5 img {
    width: 420px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm4-5 img {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm4-5 img {
    width: 320px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm4-5 img {
    width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm4-5 img {
    width: 150px;
    display: inline-block;
  }
}

.main-slider-content-hm4-1 {
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .main-slider-content-hm4-1 {
    padding: 30px 0;
  }
}

.main-slider-content-hm4-1 span {
  color: #262626;
  font-size: 24px;
  display: block;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .main-slider-content-hm4-1 span {
    font-size: 20px;
  }
}

.main-slider-content-hm4-1 h1 {
  color: #262626;
  font-size: 83px;
  line-height: 70px;
  margin: 25px 0 38px;
  line-height: 85px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-hm4-1 h1 {
    font-size: 60px;
    margin: 12px 0 30px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-hm4-1 h1 {
    font-size: 40px;
    margin: 12px 0 30px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-hm4-1 h1 {
    font-size: 45px;
    margin: 20px 0 20px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-hm4-1 h1 {
    font-size: 30px;
    margin: 11px 0 10px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-content-hm4-1 h1 {
    font-size: 28px;
  }
}

.main-slider-content-hm4-1 h1.hm4-h1-dec {
  font-size: 63px;
  line-height: 70px;
  margin: 45px 0 46px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-hm4-1 h1.hm4-h1-dec {
    font-size: 45px;
    margin: 30px 0 35px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-hm4-1 h1.hm4-h1-dec {
    font-size: 40px;
    line-height: 55px;
    margin: 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-hm4-1 h1.hm4-h1-dec {
    font-size: 30px;
    line-height: 40px;
    margin: 15px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-hm4-1 h1.hm4-h1-dec {
    font-size: 25px;
    line-height: 35px;
    margin: 20px 0 20px;
  }
}

.main-slider-content-hm4-1 h1.hm4-h1-dec2 {
  font-size: 63px;
  line-height: 70px;
  margin: 38px 0 44px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-hm4-1 h1.hm4-h1-dec2 {
    font-size: 50px;
    line-height: 60px;
    margin: 20px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-hm4-1 h1.hm4-h1-dec2 {
    font-size: 45px;
    line-height: 50px;
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-hm4-1 h1.hm4-h1-dec2 {
    font-size: 35px;
    line-height: 35px;
    margin: 20px 0 20px;
  }
}

.slider-content-hm4-mrg {
  margin-left: 37px;
}

@media only screen and (max-width: 767px) {
  .slider-content-hm4-mrg {
    margin-left: 0px;
  }
}

.slider-content-hm4-mrg2 {
  margin-right: 375px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-hm4-mrg2 {
    margin-right: 175px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-hm4-mrg2 {
    margin-right: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-hm4-mrg2 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-hm4-mrg2 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-hm4-mrg2 {
    margin-right: 0px;
  }
}

.slider-content-hm4-mrg3 {
  margin-right: 55px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-hm4-mrg3 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-hm4-mrg3 {
    margin-right: 0px;
  }
}

.content-position-hm4-1 {
  position: absolute;
  left: 54%;
  bottom: 30%;
  z-index: 9;
}

.content-position-hm4-1 span {
  display: inline-block;
  color: #fff;
  border-radius: 100%;
  background-color: #fc4f4f;
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
}

.main-slider-content-12-1 h1 {
  color: #262626;
  font-size: 32px;
  margin: 0 0 50px;
  line-height: 46px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-12-1 h1 {
    font-size: 25px;
    margin: 0 0 20px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-12-1 h1 {
    font-size: 25px;
    margin: 0 0 20px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-12-1 h1 {
    font-size: 25px;
    margin: 0 0 20px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-12-1 h1 {
    font-size: 22px;
    margin: 0 0 15px;
    line-height: 30px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-12-1 {
    text-align: center;
    margin-top: 40px;
  }
  .main-slider-content-12-1 .slider-btn-2 {
    text-align: center;
  }
}

.img-position-hm12-1-1 {
  position: absolute;
  left: 29%;
  bottom: 15%;
  z-index: 2;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .img-position-hm12-1-1 {
    bottom: 19%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-position-hm12-1-1 {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-position-hm12-1-1 {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    left: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm12-1-1 {
    left: 0;
    right: 0;
    text-align: center;
    bottom: 25%;
  }
}

.img-position-hm12-1-2 {
  position: absolute;
  left: 32.5%;
  bottom: 7%;
  z-index: 3;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .img-position-hm12-1-2 {
    bottom: 14%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-position-hm12-1-2 {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-position-hm12-1-2 {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    left: 8%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm12-1-2 {
    left: 0;
    right: 0;
    text-align: center;
    bottom: 23%;
  }
}

.img-position-hm12-2-1 {
  position: absolute;
  right: 14%;
  bottom: 19%;
  z-index: 2;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .img-position-hm12-2-1 {
    bottom: 18%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-position-hm12-2-1 {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    right: 11%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-position-hm12-2-1 {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    right: 11%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm12-2-1 {
    bottom: 19%;
    right: 0;
    text-align: center;
    left: 0;
  }
}

.img-position-hm12-2-2 {
  position: absolute;
  right: 20.5%;
  bottom: 21%;
  z-index: 3;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .img-position-hm12-2-2 {
    bottom: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-position-hm12-2-2 {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    right: 15.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-position-hm12-2-2 {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    right: 15.5%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm12-2-2 {
    right: 0;
    bottom: 21%;
    left: 0;
    text-align: center;
  }
}

.img-position-hm12-3-1 {
  position: absolute;
  right: 15%;
  bottom: 21.8%;
  z-index: 2;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .img-position-hm12-3-1 {
    bottom: 17.8%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .img-position-hm12-3-1 {
    bottom: 17.8%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-position-hm12-3-1 {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    right: 11%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-position-hm12-3-1 {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    right: 11%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm12-3-1 {
    left: 0;
    right: 0;
    text-align: center;
  }
}

.img-position-hm12-3-2 {
  position: absolute;
  right: 17.2%;
  bottom: 21%;
  z-index: 3;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .img-position-hm12-3-2 {
    bottom: 14%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .img-position-hm12-3-2 {
    bottom: 19%;
    right: 19.2%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-position-hm12-3-2 {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    right: 14.2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-position-hm12-3-2 {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    right: 14.2%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm12-3-2 {
    left: 0;
    right: 0;
    text-align: center;
  }
}

.slider-content-12-mrg {
  margin-left: 194px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-12-mrg {
    margin-left: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-12-mrg {
    margin-left: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-12-mrg {
    margin-left: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-12-mrg {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-12-mrg {
    margin-left: 0px;
    text-align: center;
    margin-top: 50px;
  }
}

.owl-carousel .owl-item .img-position-hm12-1-1 img,
.owl-carousel .owl-item .img-position-hm12-2-1 img {
  width: 600px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm12-1-1 img,
  .owl-carousel .owl-item .img-position-hm12-2-1 img {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm12-1-1 img,
  .owl-carousel .owl-item .img-position-hm12-2-1 img {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm12-1-1 img,
  .owl-carousel .owl-item .img-position-hm12-2-1 img {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm12-1-1 img,
  .owl-carousel .owl-item .img-position-hm12-2-1 img {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm12-1-1 img,
  .owl-carousel .owl-item .img-position-hm12-2-1 img {
    width: 250px;
    display: inline-block;
  }
}

.owl-carousel .owl-item .img-position-hm12-1-2 img {
  width: 477px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm12-1-2 img {
    width: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm12-1-2 img {
    width: 250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm12-1-2 img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm12-1-2 img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm12-1-2 img {
    width: 150px;
    display: inline-block;
  }
}

.owl-carousel .owl-item .img-position-hm12-2-2 img {
  width: 337px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm12-2-2 img {
    width: 220px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm12-2-2 img {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm12-2-2 img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm12-2-2 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm12-2-2 img {
    width: 140px;
    display: inline-block;
  }
}

.owl-carousel .owl-item .img-position-hm12-3-1 img {
  width: 555px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm12-3-1 img {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm12-3-1 img {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm12-3-1 img {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm12-3-1 img {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm12-3-1 img {
    width: 250px;
    display: inline-block;
  }
}

.owl-carousel .owl-item .img-position-hm12-3-2 img {
  width: 465px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm12-3-2 img {
    width: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm12-3-2 img {
    width: 250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm12-3-2 img {
    width: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm12-3-2 img {
    width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm12-3-2 img {
    width: 150px;
    display: inline-block;
  }
}

.slider-height-hm16 {
  height: 852px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-hm16 {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-hm16 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-hm16 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-hm16 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-hm16 {
    height: 530px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-hm16 {
    height: 400px;
  }
}

.owl-carousel .owl-item .slider-img-16-1 img {
  width: 573px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .slider-img-16-1 img {
    width: 373px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .slider-img-16-1 img {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .slider-img-16-1 img {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .slider-img-16-1 img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .slider-img-16-1 img {
    width: 200px;
    display: inline-block;
  }
}

.owl-carousel .owl-item .slider-img-16-2 {
  position: relative;
  z-index: 2;
}

.owl-carousel .owl-item .slider-img-16-2 img {
  width: 462px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .slider-img-16-2 img {
    width: 362px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .slider-img-16-2 img {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .slider-img-16-2 img {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .slider-img-16-2 img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .slider-img-16-2 img {
    width: 170px;
    display: inline-block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .owl-carousel .owl-item .slider-img-16-2 img {
    width: 200px;
  }
}

.slider-img-16-1 {
  margin-right: 128px;
  float: right;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-img-16-1 {
    margin-right: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-16-1 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-16-1 {
    margin-right: 30px;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-16-1 {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    float: inherit;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-img-16-1 {
    margin-bottom: 0px;
  }
}

.slider-img-16-2 {
  margin-right: 90px;
  float: right;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-img-16-2 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-img-16-2 {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-16-2 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-16-2 {
    margin-right: 30px;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-16-2 {
    margin-right: 15px;
    margin-left: 15px;
    float: inherit;
    text-align: center;
  }
}

.slider-img-16-2 .slide-pro-price {
  position: absolute;
  top: 4%;
  right: 15%;
  z-index: 3;
  width: 79px;
  background-color: #fc4f4f;
  height: 79px;
  text-align: center;
  line-height: 79px;
  display: inline-block;
  border-radius: 100%;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-img-16-2 .slide-pro-price {
    right: 28%;
  }
}

.slider-img-16-2 .slide-pro-price > span {
  display: block;
  color: #fff;
  line-height: 1;
}

.slider-img-16-2 .slide-pro-price > span.top {
  font-size: 10px;
  margin: 24px 0 6px;
}

.slider-img-16-2 .slide-pro-price > span.bottom {
  font-size: 16px;
}

.slider-img-16-3 {
  margin-right: 138px;
  float: right;
}

.main-slider-content-16-1 {
  margin-right: 100px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-slider-content-16-1 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-16-1 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-16-1 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-16-1 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-16-1 {
    margin-right: 0px;
  }
}

.main-slider-content-16-1 h1 {
  color: #262626;
  font-size: 40px;
  margin: 0 0 40px;
  line-height: 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-slider-content-16-1 h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-16-1 h1 {
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-16-1 h1 {
    font-size: 25px;
    margin: 0 0 20px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-16-1 h1 {
    font-size: 28px;
    margin: 0 0 25px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-16-1 h1 {
    font-size: 22px;
    margin: 0 0 20px;
    line-height: 30px;
  }
}

.main-slider-content-16-2 {
  width: 650px;
  margin-top: 123px;
  margin-left: -300px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-slider-content-16-2 {
    margin-left: -180px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-16-2 {
    margin-left: -110px;
    width: 450px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-16-2 {
    width: 100%;
    margin-top: 80px;
    margin-left: -90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-16-2 {
    width: 100%;
    margin-top: 100px;
    margin-left: -55px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-16-2 {
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-content-16-2 {
    margin-top: 50px;
    margin-left: -30px;
  }
}

.main-slider-content-16-2 h5 {
  background-color: #ebbc11;
  color: #262626;
  font-size: 16px;
  margin: 0;
  padding: 19px 50px 22px;
  text-align: right;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-16-2 h5 {
    font-size: 15px;
    padding: 13px 20px 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-16-2 h5 {
    font-size: 14px;
    padding: 13px 20px 16px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-16-2 h5 {
    font-size: 14px;
    padding: 8px 10px 10px;
    text-align: center;
    display: inline-block;
    line-height: 25px;
  }
}

.main-slider-content-16-2 h1 {
  color: #262626;
  font-size: 59px;
  margin: 25px 27px 30px;
  text-align: right;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-16-2 h1 {
    font-size: 50px;
    margin: 15px 27px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-16-2 h1 {
    font-size: 40px;
    margin: 15px 27px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-16-2 h1 {
    font-size: 40px;
    margin: 15px 27px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-16-2 h1 {
    font-size: 26px;
    text-align: center;
    margin: 8px 20px 6px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-content-16-2 h1 {
    margin: 12px 20px 15px;
  }
}

.main-slider-content-16-2 .slider-btn-2 {
  text-align: right;
  margin-right: 125px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-16-2 .slider-btn-2 {
    margin-right: 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-16-2 .slider-btn-2 {
    margin-right: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-16-2 .slider-btn-2 {
    margin-right: 0px;
    text-align: center;
  }
}

.slider-height-hm5 {
  height: 971px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-hm5 {
    height: 800px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-hm5 {
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-hm5 {
    height: 650px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-hm5 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-hm5 {
    height: 400px;
  }
}

.img-position-hm5-1-1 {
  position: absolute;
  bottom: 0;
  left: 25%;
  z-index: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .img-position-hm5-1-1 {
    left: 12%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .img-position-hm5-1-1 {
    left: 13%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-position-hm5-1-1 {
    left: 4%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm5-1-1 {
    left: 0;
    right: 0;
    text-align: center;
  }
}

.img-position-hm5-1-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.img-position-hm5-1-3 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}

.img-position-hm5-2-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.img-position-hm5-2-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 27%;
  z-index: 3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-position-hm5-2-2 {
    left: 15%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm5-2-2 {
    left: 0;
    right: 0;
    text-align: center;
  }
}

.img-position-hm5-2-3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18%;
  z-index: 3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-position-hm5-2-3 {
    left: 8%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm5-2-3 {
    left: 0;
    right: 0;
    text-align: center;
  }
}

.img-position-hm5-3-1 {
  position: absolute;
  bottom: 0;
  right: 27.3%;
  z-index: 3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-position-hm5-3-1 {
    right: 23.3%;
  }
}

@media only screen and (max-width: 767px) {
  .img-position-hm5-3-1 {
    right: 0;
    left: 0;
    text-align: center;
  }
}

.owl-carousel .owl-item .img-position-hm5-1-1 img {
  width: 1298px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm5-1-1 img {
    width: 1000px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm5-1-1 img {
    width: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm5-1-1 img {
    width: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm5-1-1 img {
    width: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm5-1-1 img {
    width: 300px;
    display: inline-block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm5-1-1 img {
    width: 450px;
  }
}

.owl-carousel .owl-item .img-position-hm5-1-2 img {
  width: 771px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm5-1-2 img {
    width: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm5-1-2 img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm5-1-2 img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm5-1-2 img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm5-1-2 img {
    width: 250px;
  }
}

.owl-carousel .owl-item .img-position-hm5-1-3 img {
  width: 552px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm5-1-3 img {
    width: 450px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm5-1-3 img {
    width: 330px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm5-1-3 img {
    width: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm5-1-3 img {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm5-1-3 img {
    width: 200px;
  }
}

.owl-carousel .owl-item .img-position-hm5-2-1 img {
  width: 698px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm5-2-1 img {
    width: 550px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm5-2-1 img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm5-2-1 img {
    width: 450px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm5-2-1 img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm5-2-1 img {
    width: 200px;
  }
}

.owl-carousel .owl-item .img-position-hm5-2-2 img {
  width: 455px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm5-2-2 img {
    width: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm5-2-2 img {
    width: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm5-2-2 img {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm5-2-2 img {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm5-2-2 img {
    width: 230px;
    display: inline-block;
  }
}

.owl-carousel .owl-item .img-position-hm5-2-3 img {
  width: 307px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm5-2-3 img {
    width: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm5-2-3 img {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm5-2-3 img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm5-2-3 img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm5-2-3 img {
    width: 150px;
    display: inline-block;
  }
}

.owl-carousel .owl-item .img-position-hm5-3-1 img {
  width: 500px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .owl-carousel .owl-item .img-position-hm5-3-1 img {
    width: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel .owl-item .img-position-hm5-3-1 img {
    width: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .owl-carousel .owl-item .img-position-hm5-3-1 img {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .img-position-hm5-3-1 img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .img-position-hm5-3-1 img {
    width: 180px;
    display: inline-block;
  }
}

.main-slider-content-hm5-1 {
  position: relative;
  z-index: 9;
}

.main-slider-content-hm5-1 h1 {
  color: #262626;
  font-size: 36px;
  margin: 0;
  line-height: 32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-hm5-1 h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-hm5-1 h1 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-hm5-1 h1 {
    font-size: 27px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-hm5-1 h1 {
    font-size: 21px;
    line-height: 30px;
  }
}

.main-slider-content-hm5-1 span {
  color: #fc4f4f;
  font-size: 24px;
  display: block;
  margin: 25px 0 32px;
}

@media only screen and (max-width: 767px) {
  .main-slider-content-hm5-1 span {
    margin: 10px 0 15px;
  }
}

.main-slider-content-hm5-1.main-slider-content-hm5-3 span {
  margin: 0px;
}

.main-slider-content-hm5-1.main-slider-content-hm5-3 h1 {
  margin: 25px 0 32px;
}

.slider-content-hm5-mrg1 {
  margin-right: 197px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-hm5-mrg1 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-hm5-mrg1 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-hm5-mrg1 {
    margin-right: 0px;
  }
}

.slider-hm3-content-hm5-2 {
  margin-left: -320px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-hm3-content-hm5-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-hm3-content-hm5-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-hm3-content-hm5-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-hm3-content-hm5-2 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-hm3-content-hm5-2 {
    margin-left: 0px;
  }
}

.slider-dot-hm5 .owl-dots {
  position: absolute;
  right: 2%;
  bottom: 22%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-dot-hm5 .owl-dots {
    bottom: 15%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-dot-hm5 .owl-dots {
    bottom: 15%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-dot-hm5 .owl-dots {
    bottom: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-dot-hm5 .owl-dots {
    bottom: 12%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-dot-hm5 .owl-dots {
    bottom: 6%;
  }
}

.slider-dot-hm5 .owl-dots .owl-dot {
  opacity: 0;
  visibility: hidden;
  color: rgba(38, 38, 38, 0.13);
  font-size: 347px;
  transform: scale(0.3);
  transition: all 1.2s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-dot-hm5 .owl-dots .owl-dot {
    font-size: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-dot-hm5 .owl-dots .owl-dot {
    font-size: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-dot-hm5 .owl-dots .owl-dot {
    font-size: 147px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-dot-hm5 .owl-dots .owl-dot {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-dot-hm5 .owl-dots .owl-dot {
    font-size: 50px;
  }
}

.slider-dot-hm5 .owl-dots .owl-dot.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

@media only screen and (max-width: 767px) {
  .slider-img-width.owl-carousel .owl-item .main-slider-img {
    text-align: center;
  }
  .slider-img-width.owl-carousel .owl-item img {
    text-align: center;
    display: inline-block;
    width: 200px;
  }
  .slider-img-width.owl-carousel .owl-item .single-main-slider.align-items-end {
    align-items: center !important;
  }
  .slider-img-width.owl-carousel .owl-item .single-main-slider.pt-60 {
    padding-top: 0;
  }
  .slider-height-hm12.height-100vh {
    height: 600px;
  }
  .slider-height-hm12.align-items-center {
    align-items: start !important;
  }
  .hm4-bg-position {
    background-position: 80%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hm4-bg-position {
    background-position: 100%;
  }
}

/*-------- 4. Section title style ---------*/
.section-title-1 h2 {
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 17px;
  margin: 0px;
  line-height: 26px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1 h2 {
    font-size: 32px;
    letter-spacing: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1 h2 {
    font-size: 26px;
    letter-spacing: 3px;
    line-height: 21px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1.mb-70 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1.mb-70 {
    margin-bottom: 30px;
  }
}

.section-title-2 h2 {
  font-family: "Alegreya", serif;
  font-size: 54px;
  font-weight: 400;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  text-transform: uppercase;
  line-height: 41px;
  margin: 0 0 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2 h2 {
    font-size: 46px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2 {
    font-size: 27px;
    line-height: 23px;
  }
}

.section-title-2 p {
  color: #777777;
  width: 33%;
  margin: 28px auto 0;
  line-height: 1.714286;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-2 p {
    width: 39%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-2 p {
    width: 39%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-2 p {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2 p {
    width: 65%;
    margin: 20px auto 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2 p {
    width: 95%;
    margin: 12px auto 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2.mb-70 {
    margin-bottom: 40px;
  }
  .section-title-2.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2.mb-70 {
    margin-bottom: 30px;
  }
  .section-title-2.mb-60 {
    margin-bottom: 20px;
  }
  .section-title-2.mb-45 {
    margin-bottom: 23px;
  }
}

.section-title-3 h2 {
  font-size: 54px;
  font-weight: 500;
  line-height: 46px;
  margin: 0 0 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-3 h2 {
    font-size: 46px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3 h2 {
    font-size: 27px;
    line-height: 23px;
  }
}

.section-title-3 p {
  color: #777777;
  margin: 16px auto 0;
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-3.mb-70 {
    margin-bottom: 40px;
  }
  .section-title-3.mb-80 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3.mb-70 {
    margin-bottom: 30px;
  }
  .section-title-3.mb-80 {
    margin-bottom: 30px;
  }
}

.section-title-4 h2 {
  font-size: 54px;
  font-weight: 400;
  line-height: 46px;
  margin: 0 0 0px;
  padding: 0 0 30px;
  position: relative;
  text-transform: uppercase;
}

.section-title-4 h2:before {
  position: absolute;
  left: 0;
  right: 0;
  width: 80px;
  height: 2px;
  margin: 0 auto;
  content: "";
  background-color: #fc4f4f;
  bottom: 0;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4 h2 {
    font-size: 46px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4 h2 {
    font-size: 27px;
    line-height: 23px;
    padding: 0 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4.mb-70 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4.mb-70 {
    margin-bottom: 30px;
  }
}

.section-title-5 h2 {
  font-size: 54px;
  font-weight: 400;
  line-height: 46px;
  margin: 0 0 0px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-5 h2 {
    font-size: 46px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5 h2 {
    font-size: 27px;
    line-height: 23px;
  }
}

.section-title-5 p {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  margin: 12px 0 0px;
}

@media only screen and (max-width: 767px) {
  .section-title-5.mb-50 {
    margin-bottom: 20px;
  }
}

.section-title-6 h2 {
  font-size: 32px;
  line-height: 23px;
  margin: 0 0 0px;
}

@media only screen and (max-width: 767px) {
  .section-title-6.mb-50 {
    margin-bottom: 20px;
  }
}

.section-title-7 {
  display: block;
}

.section-title-7 h2 {
  position: relative;
  margin: 0;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: inline-block;
  line-height: 17px;
}

@media only screen and (max-width: 767px) {
  .section-title-7 h2 {
    font-size: 20px;
    letter-spacing: 1px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-7 h2 {
    font-size: 24px;
    letter-spacing: 3px;
  }
}

.section-title-7 h2:before {
  position: absolute;
  left: -117px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 72px;
  content: "";
}

@media only screen and (max-width: 767px) {
  .section-title-7 h2:before {
    width: 40px;
    left: -50px;
  }
}

.section-title-7 h2:after {
  position: absolute;
  right: -117px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 72px;
  content: "";
}

@media only screen and (max-width: 767px) {
  .section-title-7 h2:after {
    width: 40px;
    right: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-7.mb-65 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7.mb-65 {
    margin-bottom: 20px;
  }
}

.section-title-8 {
  display: block;
}

.section-title-8 h2 {
  position: relative;
  padding: 0 0 0 135px;
  font-size: 54px;
  display: inline-block;
  line-height: 42px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-8 h2 {
    padding: 0 0 0 100px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-8 h2 {
    padding: 0 0 0 50px;
    font-size: 25px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-8 h2 {
    padding: 0 0 0 50px;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

.section-title-8 h2:before {
  position: absolute;
  left: 0px;
  top: 20px;
  background-color: #fc4f4f;
  height: 2px;
  width: 83px;
  content: "";
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-8 h2:before {
    width: 40px;
    top: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-8 h2:before {
    width: 40px;
    top: 14px;
  }
}

.section-title-9 h2 {
  font-size: 46px;
  margin: 0 0 0px;
  line-height: 37px;
}

@media only screen and (max-width: 767px) {
  .section-title-9 h2 {
    font-size: 28px;
    line-height: 25px;
  }
}

.section-title-9 p {
  color: #777;
  font-size: 16px;
  margin: 25px 0 0;
}

@media only screen and (max-width: 767px) {
  .section-title-9.mb-50 {
    margin-bottom: 30px;
  }
  .section-title-9.mb-60 {
    margin-bottom: 30px;
  }
}

.section-title-10 h3 {
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Alegreya", serif;
  margin: 0;
  line-height: 18px;
  display: inline-block;
  padding: 20px 50px 21px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

@media only screen and (max-width: 767px) {
  .section-title-10 h3 {
    padding: 15px 25px 16px;
  }
}

.section-title-10 h3:before {
  position: absolute;
  left: -75px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 75px;
  content: "";
}

@media only screen and (max-width: 767px) {
  .section-title-10 h3:before {
    width: 40px;
    left: -50px;
  }
}

.section-title-10 h3:after {
  position: absolute;
  right: -75px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 75px;
  content: "";
}

@media only screen and (max-width: 767px) {
  .section-title-10 h3:after {
    width: 40px;
    right: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-10.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-11 h2 {
  font-family: "Alegreya", serif;
  font-size: 36px;
  font-weight: 300;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  text-transform: uppercase;
  line-height: 22px;
  margin: 0 0 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-11 h2 {
    font-size: 46px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-11 h2 {
    font-size: 27px;
    line-height: 23px;
  }
}

.section-title-11 p {
  color: #777777;
  width: 26%;
  font-size: 16px;
  margin: 17px auto 0;
  line-height: 1.714286;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-11 p {
    width: 39%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-11 p {
    width: 39%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-11 p {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-11 p {
    width: 65%;
    margin: 20px auto 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-11 p {
    width: 95%;
    margin: 12px auto 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-11.mb-70 {
    margin-bottom: 40px;
  }
  .section-title-11.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-11.mb-70 {
    margin-bottom: 30px;
  }
  .section-title-11.mb-60 {
    margin-bottom: 20px;
  }
  .section-title-11.mb-45 {
    margin-bottom: 23px;
  }
}

.section-title-12 h2 {
  font-size: 36px;
  text-transform: uppercase;
  position: relative;
  padding: 0 0 27px;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .section-title-12 h2 {
    font-size: 23px;
    padding: 0 0 10px;
  }
}

.section-title-12 h2::before {
  position: absolute;
  left: 0;
  right: 0;
  width: 80px;
  height: 2px;
  margin: 0 auto;
  content: "";
  background-color: #A8D1DD;
  bottom: 0;
  text-transform: uppercase;
}

.section-title-12 p {
  line-height: 2.4em;
  font-size: 12px;
  color: #777;
  margin: 15px 0 0;
}

@media only screen and (max-width: 767px) {
  .section-title-12 p {
    line-height: 1.7em;
  }
}

.section-title-12.title-12-white h2 {
  color: #fff;
}

.section-title-12.title-12-white h2::before {
  background-color: #6ec1e4;
}

.section-title-12.title-12-white p {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-12.mb-65 {
    margin-bottom: 45px;
  }
  .section-title-12.mb-60 {
    margin-bottom: 40px;
  }
  .section-title-12.mb-70 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-12.mb-65 {
    margin-bottom: 30px;
  }
  .section-title-12.mb-60 {
    margin-bottom: 25px;
  }
  .section-title-12.mb-70 {
    margin-bottom: 30px;
  }
}

.section-title-13 h2 {
  font-size: 36px;
  font-family: "Alegreya", serif;
  margin: 0;
  line-height: 30px;
  position: relative;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .section-title-13 h2 {
    font-size: 25px;
    line-height: 25px;
  }
}

.section-title-13 h2:before {
  position: absolute;
  right: -110px;
  top: 19px;
  content: "";
  background-color: #2F2F2F;
  width: 80px;
  height: 2px;
}

@media only screen and (max-width: 767px) {
  .section-title-13 h2:before {
    right: -70px;
    top: 14px;
    width: 50px;
  }
}

.section-title-13.section-title-13-after h2:after {
  position: absolute;
  left: -110px;
  top: 19px;
  content: "";
  background-color: #2F2F2F;
  width: 80px;
  height: 2px;
}

@media only screen and (max-width: 767px) {
  .section-title-13.section-title-13-after h2:after {
    left: -70px;
    top: 14px;
    width: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-13.mb-55 {
    margin-bottom: 35px;
  }
  .section-title-13.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-13.mb-55 {
    margin-bottom: 25px;
  }
  .section-title-13.mb-60 {
    margin-bottom: 40px;
  }
}

.section-title-14 h2 {
  margin: 0;
  font-size: 46px;
  font-weight: 600;
  line-height: 34px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .section-title-14 h2 {
    font-size: 25px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-14.mb-45 {
    margin-bottom: 15px;
  }
}

/*-------- 5. Product style ---------*/
.product-wrap-opacity {
  opacity: 0.5;
}

.product-wrap-opacity-2 {
  opacity: 0.2;
}

.product-wrap {
  position: relative;
}

.product-wrap .product-img {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.product-wrap .product-img > a img {
  width: 100%;
}

.product-wrap .product-img > a img.hover-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18), -webkit-transform 2s cubic-bezier(0, 0, 0.44, 1.18);
}

.product-wrap .product-img > a > span {
  display: inline-block;
  color: #fff;
  text-align: center;
  position: absolute;
  text-transform: uppercase;
  font-size: 10px;
  z-index: 1;
}

.product-wrap .product-img > a > span.badge-top-0 {
  top: 0px;
}

.product-wrap .product-img > a > span.badge-right-0 {
  right: 0px;
}

.product-wrap .product-img > a > span.badge-left-0 {
  left: 0px;
}

.product-wrap .product-img > a > span.badge-left-20 {
  left: 20px;
}

.product-wrap .product-img > a > span.badge-right-20 {
  right: 20px;
}

.product-wrap .product-img > a > span.badge-top-20 {
  top: 20px;
}

.product-wrap .product-img > a > span.badge-border-radius-100 {
  border-radius: 100%;
}

.product-wrap .product-img > a > span.badge-width-height-1 {
  min-width: 90px;
  height: 30px;
  line-height: 30px;
}

.product-wrap .product-img > a > span.badge-width-height-2 {
  min-width: 55px;
  height: 55px;
  line-height: 55px;
}

.product-wrap .product-img > a > span.badge-black {
  background-color: #262626;
}

.product-wrap .product-img > a > span.badge-red {
  background-color: #d84646;
}

.product-wrap .product-img > a > span.badge-white {
  background-color: #fff;
}

.product-wrap .product-img > a > span.badge-text-black {
  color: #262626;
}

.product-wrap .product-img > a > span.badge-pr {
  padding-right: 8px;
}

.product-wrap .product-img > a > span.badge-pt {
  padding-top: 2px;
}

.product-wrap .product-img > a > span.badge-black-before:before {
  content: "";
  background: #262626;
  width: 80px;
  height: 105px;
  transform: rotate(-60deg);
  position: absolute;
  z-index: -1;
  top: -62px;
  right: -30px;
  text-align: right;
  line-height: 30px;
}

.product-wrap .product-img .product-action-position-1 {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 99;
}

.product-wrap .product-img .product-action-position-2 {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 45px;
  text-align: center;
  z-index: 99;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action-position-2 {
    bottom: 35px;
  }
}

.product-wrap .product-img .product-action-position-3 {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 99;
}

.product-wrap .product-img .shop-list-quickview {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99;
  margin-top: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all .35s ease 0s;
}

.product-wrap .product-img .shop-list-quickview a {
  color: #000;
  background-color: #fff;
  display: inline-block;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  font-size: 18px;
}

.product-wrap .product-img .shop-list-quickview a:hover {
  background-color: #fc4f4f;
  color: #fff;
}

.product-wrap .product-img .shop-list-quickview a i {
  line-height: 46px;
}

.product-wrap .product-img .shop-list-quickview a > span {
  visibility: hidden;
  position: absolute;
  background-color: #262626;
  text-align: center;
  padding: 6px 12px 8px;
  border-radius: 0px;
  font-size: 10px;
  z-index: 3;
  opacity: 0;
  white-space: pre;
  top: -31px;
  left: 50%;
  font-size: 10px;
  display: inline-block;
  line-height: 1;
  transform: translateX(-50%);
}

.product-wrap .product-img .shop-list-quickview a > span::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #262626 transparent transparent transparent;
  position: absolute;
  content: "";
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.product-wrap .product-img .shop-list-quickview a:hover span {
  visibility: visible;
  opacity: 1;
}

.product-wrap .product-img .product-action a {
  background-color: white;
  border-radius: 50px;
  color: #262626;
  display: inline-block;
  font-size: 18px;
  height: 46px;
  opacity: 0;
  transform: translate(50px, 0);
  margin: 5px;
  position: relative;
  text-align: center;
  visibility: hidden;
  width: 46px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action a {
    margin: 2px 2px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap .product-img .product-action a {
    margin: 5px 3px;
  }
}

.product-wrap .product-img .product-action a i {
  line-height: 46px;
}

.product-wrap .product-img .product-action a.icon-blod i {
  font-weight: bold;
}

.product-wrap .product-img .product-action a:nth-child(1) {
  transition-delay: 100ms;
}

.product-wrap .product-img .product-action a:nth-child(2) {
  transition-delay: 150ms;
}

.product-wrap .product-img .product-action a:nth-child(3) {
  transition-delay: 200ms;
}

.product-wrap .product-img .product-action a:nth-child(4) {
  transition-delay: 200ms;
}

.product-wrap .product-img .product-action a > span {
  visibility: hidden;
  position: absolute;
  background-color: #262626;
  text-align: center;
  padding: 6px 12px 8px;
  border-radius: 0px;
  font-size: 10px;
  z-index: 3;
  opacity: 0;
  white-space: pre;
  top: -31px;
  left: 50%;
  font-size: 10px;
  display: inline-block;
  line-height: 1;
  transform: translateX(-50%);
}

.product-wrap .product-img .product-action a > span::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #262626 transparent transparent transparent;
  position: absolute;
  content: "";
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.product-wrap .product-img .product-action a:hover span {
  visibility: visible;
  opacity: 1;
}

.product-wrap .product-img .product-action a:hover {
  background-color: #fc4f4f;
  color: #fff;
}

.product-wrap .product-img .product-action.action-inc-width-height a {
  width: 55px;
  height: 55px;
  border-radius: 0px;
}

.product-wrap .product-img .product-action.action-inc-width-height a i {
  line-height: 55px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-img .product-action.action-inc-width-height a {
    width: 45px;
    height: 45px;
  }
  .product-wrap .product-img .product-action.action-inc-width-height a i {
    line-height: 45px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-img .product-action.action-inc-width-height a {
    width: 45px;
    height: 45px;
  }
  .product-wrap .product-img .product-action.action-inc-width-height a i {
    line-height: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action.action-inc-width-height a {
    width: 45px;
    height: 45px;
  }
  .product-wrap .product-img .product-action.action-inc-width-height a i {
    line-height: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action.action-inc-width-height a {
    width: 45px;
    height: 45px;
  }
  .product-wrap .product-img .product-action.action-inc-width-height a i {
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action.action-inc-width-height a {
    width: 42px;
    height: 42px;
  }
  .product-wrap .product-img .product-action.action-inc-width-height a i {
    line-height: 42px;
  }
}

.product-wrap .product-img .product-action.product-action-sky a:hover {
  background-color: #A8D1DD;
}

.product-wrap .product-img:hover a img.hover-img {
  opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img.mb-40 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img.mb-40 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img.mb-40 {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img.mb-25 {
    margin-bottom: 15px;
  }
}

.product-wrap .product-img.product-img-border {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.product-wrap .product-content {
  position: relative;
  z-index: 2;
}

.product-wrap .product-content h3 {
  font-size: 24px;
  margin: 0 0 4px;
  text-transform: uppercase;
  position: relative;
  padding: 0 65px 0 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content h3 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-content h3 {
    font-size: 14px;
    padding: 0 40px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content h3 {
    font-size: 14px;
    padding: 0 40px 0 0;
  }
}

.product-wrap .product-content h3.title-blod {
  font-weight: 700;
}

.product-wrap .product-content h3::before {
  position: absolute;
  content: "";
  background: url(../images/icon-img/circle-simple-right.png);
  width: 61px;
  height: 61px;
  font-family: FontAwesome;
  direction: ltr;
  transition: all 0.3s;
  background-size: 100%;
  right: 0;
  top: -16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content h3::before {
    width: 35px;
    height: 35px;
    top: -6px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-content h3::before {
    width: 30px;
    height: 30px;
    top: -6px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content h3::before {
    width: 30px;
    height: 30px;
    top: -6px;
  }
}

.product-wrap .product-content h3:hover::before {
  background-position: center bottom;
}

.product-wrap .product-content-2 h3 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.product-wrap .product-content-2.title-font-width-400 h3 {
  font-weight: 400;
}

.product-wrap .product-content-2.product-content-size-dec h3 {
  font-size: 11px;
}

.product-wrap .product-content-2.product-content-size-dec .product-price span {
  font-size: 12px;
}

.product-wrap .product-content-4 h3 {
  font-size: 14px;
  margin: 0 0 7px;
}

.product-wrap .product-content-4 .product-price-3 span {
  font-size: 16px;
  margin: 0 1px;
}

.product-wrap .product-content-4 .product-price-3 span.old-price {
  text-decoration: line-through;
}

.product-wrap .product-content-4.product-content-sky h3 {
  color: #262626;
}

.product-wrap .product-content-4.product-content-sky h3 a {
  color: #262626;
}

.product-wrap .product-content-4.product-content-sky h3 a:hover {
  color: #A8D1DD;
}

.product-wrap .product-content-5 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -20px;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: all 0.3s;
}

.product-wrap .product-content-5 h4 {
  font-size: 14px;
  color: #fff;
  margin: 0 0 8px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-5 h4 {
    margin: 0 0 3px;
  }
}

.product-wrap .product-content-5 h4 a {
  color: #fff;
}

.product-wrap .product-content-5 span {
  color: #fff;
}

.product-wrap:hover .product-action a {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0);
}

.product-wrap:hover .default-overlay::before {
  background-color: rgba(24, 24, 24, 0.2);
  opacity: 1;
  pointer-events: none;
}

.product-wrap:hover .product-img .product-price-2 {
  opacity: 1;
  visibility: visible;
  left: 0;
}

.product-wrap:hover .product-content-5 {
  opacity: 1;
  visibility: visible;
  bottom: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap:hover .product-content-5 {
    bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap:hover .product-content-5 {
    bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap:hover .product-content-5 {
    bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap:hover .product-content-5 {
    bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap.mb-60 {
    margin-bottom: 30px;
  }
  .product-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

.product-price span {
  font-size: 14px;
  margin: 0 1px;
}

.product-price span.old-price {
  text-decoration: line-through;
}

.product-price.product-price-red span {
  color: #fc4f4f;
}

.product-price.product-price-red span.old-price {
  color: #262626;
}

.product-price-2 {
  position: absolute;
  left: -100px;
  bottom: 0;
  transition: all 0.5s ease;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
}

.product-price-2 span {
  font-size: 14px;
  margin: 0 1px;
  color: #fff;
  display: inline-block;
  line-height: 1;
  padding: 11px 20px;
  background-color: #262626;
}

.product-slider-padding {
  overflow: hidden;
  text-align: center;
}

.product-slider-padding .slick-list {
  text-align: left;
  margin-right: -60px;
  margin-left: -60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-slider-padding .slick-list {
    margin-right: -30px;
    margin-left: -30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-slider-padding .slick-list {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-padding .slick-list {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-padding .slick-list {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-padding .slick-list {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.product-slider-padding .slick-list .product-wrap {
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-slider-padding .slick-list .product-wrap {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-slider-padding .slick-list .product-wrap {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-padding .slick-list .product-wrap {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-padding .slick-list .product-wrap {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-padding .slick-list .product-wrap {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
  }
}

.product-slider-padding .slick-list .product-wrap.slick-center {
  margin-top: 0;
}

.product-slider-padding .slick-list .product-wrap.slick-center.product-wrap-opacity {
  opacity: 1;
}

.product-slider-padding-2 {
  overflow: hidden;
  margin-right: -0px;
  margin-left: -0px;
}

.product-slider-padding-2 .slick-list .slick-track {
  display: flex;
  flex-wrap: wrap;
}

.product-slider-padding-2 .slick-list .slick-track .pro-all {
  padding-right: 0px;
  padding-left: 0px;
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
}

.product-slider-padding-2 .slick-list .slick-track .pro-all .product-wrap {
  padding: 60px;
  margin: 0px -30px;
  margin-bottom: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-padding-2 .slick-list .slick-track .pro-all .product-wrap {
    padding: 40px;
    margin: 0px -20px;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-padding-2 .slick-list .slick-track .pro-all .product-wrap {
    padding: 15px;
    margin: 0px -7.5px;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-padding-2 .slick-list .slick-track .pro-all .product-wrap {
    padding: 0px;
    margin: 0px 15px;
    margin-bottom: 80px;
  }
}

.product-slider-padding-2 .slick-list .slick-track .pro-all.slick-center {
  margin-left: 60px;
  margin-right: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-padding-2 .slick-list .slick-track .pro-all.slick-center {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-padding-2 .slick-list .slick-track .pro-all.slick-center {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-padding-2 .slick-list .slick-track .pro-all.slick-center {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.product-slider-padding-2 .slick-list .slick-track .pro-all.slick-center .product-wrap {
  padding: 0px;
}

.product-slider-padding-2 .slick-list .slick-track .pro-all.slick-center.product-wrap-opacity {
  opacity: 1;
}

.slider-icon {
  position: absolute;
  bottom: 0px;
  z-index: 999;
}

.slider-icon i {
  font-size: 35px;
  width: 55px;
  height: 55px;
  display: inline-block;
  border-radius: 100%;
  line-height: 55px;
  color: #262626;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all .3s ease 0s;
  cursor: pointer;
  text-align: center;
}

.slider-icon i:hover {
  background-color: #262626;
  color: #fff;
  border: 1px solid black;
}

.slider-icon.slider-next {
  right: calc(50% - 60px);
}

.slider-icon.slider-prev {
  left: calc(50% - 60px);
}

.slider-icon.pro-slider-4 {
  bottom: -85px;
}

@media only screen and (max-width: 767px) {
  .slider-icon.pro-slider-4 {
    bottom: -80px;
  }
}

.dot-style-1 .slick-dots {
  display: inline-block;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 1;
  border-radius: 50px;
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-1 .slick-dots {
    margin-top: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-1 .slick-dots {
    margin-top: 30px;
  }
}

.dot-style-1 .slick-dots li {
  display: inline-block;
}

.dot-style-1 .slick-dots li button {
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: bold;
  color: #262626;
  font-size: 18px;
  padding: 19px 46px 18px;
  border-radius: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-1 .slick-dots li button {
    padding: 12px 35px 11px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-1 .slick-dots li button {
    padding: 8px 24px 7px;
    font-size: 15px;
  }
}

.dot-style-1 .slick-dots li button::before {
  content: "0";
  display: inline-block;
  font-size: 18px;
}

.dot-style-1 .slick-dots li.slick-active button {
  background-color: #fc4f4f;
  color: #fff;
}

.dot-style-1 .slick-dots li.slick-active button::before {
  color: #fff;
  font-weight: bold;
}

.dot-style-1 .slick-dots li:nth-child(2), .dot-style-1 .slick-dots li:nth-child(3) {
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .dot-style-1 .slick-dots li:nth-child(2), .dot-style-1 .slick-dots li:nth-child(3) {
    display: inherit;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-1 .slick-dots li:nth-child(2), .dot-style-1 .slick-dots li:nth-child(3) {
    display: inherit;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-1 .slick-dots li:nth-child(2), .dot-style-1 .slick-dots li:nth-child(3) {
    display: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-1 .slick-dots li:nth-child(2), .dot-style-1 .slick-dots li:nth-child(3) {
    display: inherit;
  }
}

.dot-style-1 .slick-dots li:nth-child(4) button::after {
  content: "2";
  font-size: 18px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .dot-style-1 .slick-dots li:nth-child(4) button::after {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-1 .slick-dots li:nth-child(4) button::after {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-1 .slick-dots li:nth-child(4) button::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-1 .slick-dots li:nth-child(4) button::after {
    display: none;
  }
}

.dot-style-1 .slick-dots li:nth-child(4) button {
  font-size: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .dot-style-1 .slick-dots li:nth-child(4) button {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-1 .slick-dots li:nth-child(4) button {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-1 .slick-dots li:nth-child(4) button {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-1 .slick-dots li:nth-child(4) button {
    font-size: 18px;
  }
}

.product-tab-list {
  display: flex;
  justify-content: center;
}

.product-tab-list a {
  color: #777777;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 21px;
  line-height: 20px;
  padding: 0 0 6px;
}

@media only screen and (max-width: 767px) {
  .product-tab-list a {
    margin: 0 10px 5px;
    font-size: 16px;
  }
}

.product-tab-list a.active {
  border-bottom: 2px solid #fc4f4f;
}

.product-tab-list a.active, .product-tab-list a:hover {
  color: #262626;
}

@media only screen and (max-width: 767px) {
  .product-tab-list.mb-50 {
    margin-bottom: 30px;
  }
}

.product-tab-list-2 {
  display: flex;
  justify-content: center;
}

.product-tab-list-2 a {
  color: #ccc;
  font-size: 54px;
  margin: 0 21px;
  line-height: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-2 a {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-2 a {
    font-size: 35px;
    margin: 0 12px;
    line-height: 31px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2 a {
    font-size: 25px;
    margin: 0 7px;
    line-height: 35px;
  }
}

.product-tab-list-2 a.active, .product-tab-list-2 a:hover {
  color: #262626;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-2.mb-65 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2.mb-65 {
    margin-bottom: 30px;
  }
}

.product-tab-list-3 a {
  color: #777777;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
  margin: 0px 15px;
  padding: 0 0 5px;
  border-bottom: 1px solid transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-3 a {
    margin: 0px 8px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3 a {
    margin: 0px 5px 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-3 a {
    margin: 0px 12px 0px;
  }
}

.product-tab-list-3 a:hover, .product-tab-list-3 a.active {
  color: #262626;
  border-bottom: 1px solid #262626;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3 {
    justify-content: center;
    margin: 15px 0 7px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-4 {
    justify-content: center;
    display: flex;
  }
}

.product-tab-list-4 a {
  color: #777777;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
  margin: 0px 0 0 40px;
  border-bottom: 1px solid transparent;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-tab-list-4 a {
    margin: 0px 0 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-4 a {
    margin: 0px 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-4 a {
    margin: 0px 0 0 12px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-4 a {
    margin: 0px 10px 10px 10px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-4 a {
    margin: 0px 8px 10px 8px;
  }
}

.product-tab-list-4 a:first-child {
  margin: 0px 0 0 0px;
}

.product-tab-list-4 a:hover, .product-tab-list-4 a.active {
  color: #262626;
}

.product-tab-list-5 {
  justify-content: center;
}

.product-tab-list-5 a {
  color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  margin: 0px 20px;
  border-bottom: 1px solid transparent;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-5 a {
    font-size: 22px;
    margin: 0px 6px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-5 a {
    font-size: 24px;
    margin: 0px 12px;
  }
}

.product-tab-list-5 a:hover, .product-tab-list-5 a.active {
  color: #262626;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-5.mb-45 {
    margin-bottom: 25px;
  }
}

.product-tab-list-6 a {
  color: #262626;
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  margin: 0px 10px 0 0;
  padding: 18px 48px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.product-tab-list-6 a:last-child {
  margin: 0px 0px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-tab-list-6 a {
    padding: 15px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-tab-list-6 a {
    padding: 15px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-6 a {
    padding: 10px 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-6 a {
    padding: 10px 22px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-6 a {
    padding: 10px 22px;
    margin: 0px 10px 10px 0;
  }
}

.product-tab-list-6 a:hover, .product-tab-list-6 a.active {
  color: #fff;
  background-color: #a8d1dd;
  border: 1px solid #a8d1dd;
}

.product-tab-list-7 a {
  color: #777;
  font-size: 16px;
  margin-right: 38px;
  border-bottom: 1px solid transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-7 a {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-7 a {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-7 a {
    margin-right: 9px;
    margin-bottom: 10px;
    font-size: 15px;
  }
}

.product-tab-list-7 a:last-child {
  margin-right: 0;
}

.product-tab-list-7 a:hover {
  color: #262626;
}

.product-tab-list-7 a.active {
  border-bottom: 1px solid #262626;
  color: #262626;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-7.mb-50 {
    margin-bottom: 20px;
  }
}

.pro-view-all a {
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
}

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  animation-name: lastudioZoomIn;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-play-state: running;
}

.tab-content.jump-2 .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump-2 .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  animation-name: moveUp;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-play-state: running;
}

.tab-content.jump-3 .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump-3 .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
}

.section-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../images/icon-img/bg-title-home-06.png");
  background-position: bottom center;
  background-repeat: repeat-x;
}

@media only screen and (max-width: 767px) {
  .section-wrap {
    display: block;
    text-align: center;
  }
  .section-wrap.mb-50 {
    margin-bottom: 30px;
  }
  .section-wrap.pb-60 {
    padding-bottom: 30px;
  }
}

.section-wrap-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .section-wrap-2 {
    display: block;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .section-wrap-2.mb-60 {
    margin-bottom: 20px;
  }
}

.product-all-2 {
  padding: 0px 20px 0px 20px;
}

.product-all-2 .product-wrap-2 {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .product-all-2 .product-wrap-2 {
    display: block;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-all-2 .product-wrap-2 {
    display: flex;
  }
}

.product-all-2 .product-wrap-2 .product-img-2 {
  flex: 0 0 44%;
  overflow: hidden;
  border-radius: 100%;
}

.product-all-2 .product-wrap-2 .product-img-2 a {
  display: block;
}

.product-all-2 .product-wrap-2 .product-img-2 a img {
  width: 100%;
  border-radius: 100%;
  transition: all .35s ease 0s;
}

.product-all-2 .product-wrap-2 .product-content-3 {
  flex: 0 0 56%;
  padding: 0px 0px 0px 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-all-2 .product-wrap-2 .product-content-3 {
    padding: 0px 0px 0px 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-all-2 .product-wrap-2 .product-content-3 {
    padding: 0px 0px 0px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-all-2 .product-wrap-2 .product-content-3 {
    padding: 0px 0px 0px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-all-2 .product-wrap-2 .product-content-3 {
    padding: 0px 0px 0px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-all-2 .product-wrap-2 .product-content-3 {
    padding: 30px 0px 0px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-all-2 .product-wrap-2 .product-content-3 {
    padding: 0px 0px 0px 30px;
  }
}

.product-all-2 .product-wrap-2 .product-content-3 h3 {
  font-size: 32px;
  font-family: "Alegreya", serif;
  margin: 0;
  text-transform: uppercase;
  line-height: 23px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-all-2 .product-wrap-2 .product-content-3 h3 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-all-2 .product-wrap-2 .product-content-3 h3 {
    font-size: 25px;
  }
}

.product-all-2 .product-wrap-2 .product-content-3 p {
  font-size: 18px;
  font-weight: 300;
  color: #777777;
  margin: 30px 0 80px;
  line-height: 1.714286;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-all-2 .product-wrap-2 .product-content-3 p {
    font-size: 15px;
    margin: 20px 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-all-2 .product-wrap-2 .product-content-3 p {
    font-size: 15px;
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-all-2 .product-wrap-2 .product-content-3 p {
    font-size: 15px;
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-all-2 .product-wrap-2 .product-content-3 p {
    font-size: 15px;
    margin: 10px 0 20px;
  }
}

.product-all-2 .product-wrap-2 .product-content-3 .product-btn a {
  display: inline-block;
  background-color: #262626;
  color: #fff;
  line-height: 1;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 25px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-all-2 .product-wrap-2 .product-content-3 .product-btn a {
    padding: 16px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-all-2 .product-wrap-2 .product-content-3 .product-btn a {
    padding: 16px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-all-2 .product-wrap-2 .product-content-3 .product-btn a {
    padding: 16px 30px;
  }
}

.product-all-2 .product-wrap-2 .product-content-3 .product-btn a:hover {
  background-color: #fc4f4f;
}

.product-all-2 .product-wrap-2:hover .product-img-2 a img {
  transform: scale(1.2);
}

.product-slider-active-4 .slick-list .product-all-2.slick-center .product-wrap-opacity-2 {
  opacity: 1;
}

.shop-instagram-wrap {
  position: relative;
  overflow: hidden;
}

.shop-instagram-wrap::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  background-color: rgba(255, 57, 69, 0.8);
  transition: all .4s;
  pointer-events: none;
}

.shop-instagram-wrap a img {
  width: 100%;
}

.shop-instagram-wrap .shop-insta-content-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 80px 40px 50px;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 60px 40px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 60px 40px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 57px 20px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 57px 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 57px 20px 20px;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
  max-width: 90%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
  transform: translateY(20px);
  opacity: 0;
  font-weight: 700;
  font-size: 20px;
  display: block;
  color: #fff;
  transition: all .4s ease-in-out .1s;
  margin: 0 0 8px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 22px;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 a {
  color: #fff;
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content p {
  transform: translateY(20px);
  opacity: 0;
  font-weight: 400;
  font-size: 16px;
  display: block;
  color: #fff;
  transition: all .4s ease-in-out .3s;
  margin: 0;
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup a {
  color: #fff;
  display: inline-block;
  transform: translateX(20px);
  opacity: 0;
  font-size: 2.5em;
  transition: all .4s ease-in-out .4s;
}

.shop-instagram-wrap:hover::after {
  opacity: 1;
}

.shop-instagram-wrap:hover .shop-insta-content h3 {
  transform: translateY(0px);
  opacity: 1;
}

.shop-instagram-wrap:hover .shop-insta-content p {
  transform: translateY(0px);
  opacity: 1;
}

.shop-instagram-wrap:hover .shop-insta-popup a {
  transform: translateX(0px);
  opacity: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap.mb-70 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap.mb-70 {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap.mb-70 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap.mb-70 {
    margin-bottom: 30px;
  }
}

.product-slider-active-5 {
  padding: 300px 0px 110px 0px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-active-5 {
    padding: 190px 0px 110px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-active-5 {
    padding: 100px 0px 110px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-active-5 {
    padding: 100px 0px 110px 0px;
  }
}

.product-slider-active-5 .single-product-slider {
  padding: 0px 60px 0px 60px;
}

@media only screen and (max-width: 767px) {
  .product-slider-active-5 .single-product-slider {
    padding: 0px 20px 0px 20px;
  }
}

.product-slider-active-5 .single-product-slider a img {
  width: 100%;
}

.product-slider-active-5 .single-product-slider h3 {
  font-size: 40px;
  margin: 85px 0 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-active-5 .single-product-slider h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-active-5 .single-product-slider h3 {
    font-size: 30px;
    margin: 40px 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-active-5 .single-product-slider h3 {
    font-size: 22px;
    margin: 30px 0 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-slider-active-5 .single-product-slider h3 {
    font-size: 22px;
  }
}

.product-slider-active-5 .single-product-slider span {
  font-size: 24px;
  display: block;
  line-height: 1;
  font-weight: 300;
}

.product-slider-active-5 .product-slider-icon {
  position: absolute;
  bottom: 100px;
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.6);
  font-size: 80px;
  width: 90px;
  height: 90px;
  text-align: center;
  transition: all 250ms ease-out;
  z-index: 9;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .product-slider-active-5 .product-slider-icon {
    font-size: 30px;
    width: 50px;
    height: 50px;
  }
}

.product-slider-active-5 .product-slider-icon i {
  line-height: 90px;
}

@media only screen and (max-width: 767px) {
  .product-slider-active-5 .product-slider-icon i {
    line-height: 50px;
  }
}

.product-slider-active-5 .product-slider-icon.slider-prev {
  left: 20%;
  right: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-active-5 .product-slider-icon.slider-prev {
    left: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-active-5 .product-slider-icon.slider-prev {
    left: 5%;
  }
}

.product-slider-active-5 .product-slider-icon.slider-next {
  right: 20%;
  left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-active-5 .product-slider-icon.slider-next {
    right: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-active-5 .product-slider-icon.slider-next {
    right: 5%;
  }
}

.product-slider-active-5 .product-slider-icon:hover {
  background-color: #262626;
  color: #fff;
  font-size: 60px;
}

@media only screen and (max-width: 767px) {
  .product-slider-active-5 .product-slider-icon:hover {
    font-size: 40px;
  }
}

.pro-view-all-2 a {
  color: #535353;
  font-size: 12px;
  display: inline-block;
  line-height: 1;
}

.pro-view-all-2 a:hover {
  color: #fc4f4f;
}

.pro-view-all-3 a {
  color: #262626;
  display: inline-block;
  line-height: 1;
  position: relative;
  text-transform: capitalize;
}

.pro-view-all-3 a:before {
  position: absolute;
  left: -58px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 40px;
  content: "";
}

.pro-view-all-3 a:after {
  position: absolute;
  right: -58px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 40px;
  content: "";
}

.pro-view-all-3 a:hover {
  color: #6ec1e4;
}

.single-featured {
  position: relative;
  overflow: hidden;
}

.single-featured .featured-img {
  overflow: hidden;
}

.single-featured .featured-img a img {
  width: 100%;
  transition: all .3s ease 0s;
  transform: scale(1);
}

.single-featured .featured-content {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  text-align: center;
}

.single-featured .featured-content h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  padding: 25px 10px 24px;
}

.single-featured .featured-content h3 a {
  color: #fff;
}

.single-featured:hover .featured-img a img {
  transform: scale(1.05);
}

.single-featured:hover::before {
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 1;
  pointer-events: none;
}

.arrow-style-position-1 .owl-nav {
  position: absolute;
  top: -106px;
  left: 34.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .arrow-style-position-1 .owl-nav {
    top: -100px;
    left: 45.5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .arrow-style-position-1 .owl-nav {
    top: -100px;
    left: 50.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .arrow-style-position-1 .owl-nav {
    top: -97px;
    left: 62.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .arrow-style-position-1 .owl-nav {
    top: -82px;
    left: 64.5%;
  }
}

@media only screen and (max-width: 767px) {
  .arrow-style-position-1 .owl-nav {
    top: -66px;
    left: 60.5%;
  }
}

.arrow-style-1 .owl-nav div {
  display: inline-block;
  margin: 0 8px;
}

.arrow-style-1 .owl-nav div i {
  font-size: 38px;
  width: 55px;
  height: 55px;
  display: inline-block;
  border-radius: 100%;
  line-height: 55px;
  color: #262626;
  box-shadow: 0px 0px 1px 0px rgba(38, 38, 38, 0.23);
  transition: all .3s ease 0s;
  cursor: pointer;
  text-align: center;
}

.arrow-style-1 .owl-nav div i:hover {
  background-color: #262626;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-area.pt-100 {
    padding-top: 50px;
  }
  .product-area.pb-70 {
    padding-bottom: 25px;
  }
  .product-area.pb-75 {
    padding-bottom: 30px;
  }
  .product-area.pb-215 {
    padding-bottom: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .product-area.pt-100 {
    padding-top: 20px;
  }
  .product-area.pt-70 {
    padding-top: 20px;
  }
  .product-area.pb-115 {
    padding-bottom: 50px;
  }
  .product-area.pb-75 {
    padding-bottom: 20px;
  }
  .product-area.pb-215 {
    padding-bottom: 130px;
  }
  .product-area.pb-45 {
    padding-bottom: 15px;
  }
  .product-area.pb-65 {
    padding-bottom: 15px;
  }
  .product-area.p-hm19-xs-mrg {
    padding-top: 48px;
  }
  .product-area-2.pb-45 {
    padding-bottom: 20px;
  }
}

/*-------- 6. Delay style ---------*/
.delay-area {
  position: relative;
  overflow: hidden;
}

.delay-area > .deal-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 9;
}

.delay-area > .deal-wrap > span {
  color: rgba(255, 255, 255, 0.78);
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #fc4f4f;
  display: inline-block;
  line-height: 1;
  padding: 12px 35px;
}

.bg-1 {
  background-image: url(../../assets/images/bg/bg-1.png);
  background-position: 110% calc(110% + 69.7px);
  position: absolute;
  left: 0;
  top: -10%;
  height: 120%;
  width: 100%;
  background-repeat: no-repeat;
  will-change: transform;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .bg-1 {
    background-position: 160% calc(75% + 57.7px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bg-1 {
    background-position: 265% calc(99% + 99.7px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-1 {
    background-position: 320px center !important;
    background-size: 100% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-1 {
    background-position: 320px center !important;
    background-size: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .bg-1 {
    background-position: 0px center !important;
    background-size: 100% !important;
  }
}

.delay-content {
  margin-left: -190px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .delay-content {
    margin-left: -320px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .delay-content {
    margin-left: 0px;
    margin-right: 240px;
  }
}

@media only screen and (max-width: 767px) {
  .delay-content {
    margin-left: 0px;
  }
}

.delay-content.delay-content-mrg-2 {
  margin-left: -117px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .delay-content.delay-content-mrg-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .delay-content.delay-content-mrg-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .delay-content.delay-content-mrg-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .delay-content.delay-content-mrg-2 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .delay-content.delay-content-mrg-2 {
    margin-left: 0px;
  }
}

.delay-content > span {
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Alegreya", serif;
}

.delay-content h2 {
  font-size: 168px;
  text-transform: uppercase;
  font-family: "Alegreya", serif;
  line-height: 153px;
  margin: 10px 0 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .delay-content h2 {
    font-size: 145px;
    line-height: 135px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .delay-content h2 {
    font-size: 125px;
    line-height: 115px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .delay-content h2 {
    font-size: 100px;
    line-height: 95px;
  }
}

@media only screen and (max-width: 767px) {
  .delay-content h2 {
    font-size: 62px;
    line-height: 60px;
  }
}

.delay-content .timer-style-1 div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.delay-content .timer-style-1 div > span {
  border: 1px solid #EAEAEA;
  width: 80px;
  height: 80px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  border-radius: 100%;
  background-color: #fff;
  margin: 0 10px;
}

@media only screen and (max-width: 767px) {
  .delay-content .timer-style-1 div > span {
    width: 61px;
    height: 61px;
    margin: 0 2px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .delay-content .timer-style-1 div > span {
    width: 80px;
    height: 80px;
    margin: 0 5px;
  }
}

.delay-content .timer-style-1 div > span > span {
  font-size: 24px;
  line-height: 20px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .delay-content .timer-style-1 div > span > span {
    font-size: 18px;
    line-height: 18px;
  }
}

.delay-content .timer-style-1 div > span > p {
  font-size: 12px;
  color: #262626;
  margin: 5px 0 0;
  line-height: 15px;
}

@media only screen and (max-width: 767px) {
  .delay-content .timer-style-1 div > span > p {
    margin: 2px 0 0;
    line-height: 10px;
    font-size: 11px;
  }
}

.delay-14-img {
  text-align: right;
  background-image: url("../images/icon-img/home14-bg-1.jpg");
  background-position: center left;
  background-repeat: no-repeat;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .delay-14-img {
    background-size: 80%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .delay-14-img {
    background-size: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .delay-14-img {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .delay-14-img {
    margin-bottom: 20px;
  }
}

.delay-14-img a img {
  max-width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .delay-14-img a img {
    max-width: 60%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .delay-14-img a img {
    max-width: 60%;
  }
}

.delay-content-4 h3 {
  line-height: 1.4074;
  font-size: 36px;
  margin: 0 0 52px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .delay-content-4 h3 {
    line-height: 1.4;
    font-size: 32px;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .delay-content-4 h3 {
    line-height: 1.4;
    font-size: 32px;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .delay-content-4 h3 {
    font-size: 25px;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .delay-content-4 h3 {
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .delay-content-4 h3 {
    font-size: 20px;
    margin: 0 0 30px;
  }
}

.delay-content-4 .delay-sold {
  margin: 37px 0 40px;
}

@media only screen and (max-width: 767px) {
  .delay-content-4 .delay-sold {
    margin: 20px 0 20px;
  }
}

.delay-content-4 .delay-sold span {
  background-color: #777;
  color: #fff;
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  padding: 10px 15px;
}

.timer-style-2 div {
  display: flex;
  flex-wrap: wrap;
}

.timer-style-2 div > span {
  text-align: center;
  margin-right: 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .timer-style-2 div > span {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-2 div > span {
    margin-right: 20px;
  }
}

.timer-style-2 div > span:last-child {
  margin-right: 0;
}

.timer-style-2 div > span > span {
  font-size: 26px;
  display: block;
  line-height: 20px;
}

.timer-style-2 div > span > p {
  margin: 10px 0 0;
  color: #262626;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .delay-area.pt-190 {
    padding-top: 100px;
  }
  .delay-area.pb-190 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .delay-area.pt-190 {
    padding-top: 100px;
  }
  .delay-area.pb-190 {
    padding-bottom: 100px;
  }
}

.parallax-ptb-2 {
  padding: 133px 0 125px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .parallax-ptb-2 {
    padding: 100px 0 92px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .parallax-ptb-2 {
    padding: 100px 0 92px;
  }
}

@media only screen and (max-width: 767px) {
  .parallax-ptb-2 {
    padding: 70px 0 70px;
  }
}

.parallax-height {
  height: 900px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .parallax-height {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .parallax-height {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .parallax-height {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .parallax-height {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .parallax-height {
    height: 350px;
  }
}

.delay-content-2 {
  position: relative;
  z-index: 9;
}

.delay-content-2 > span {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 4px;
  background-color: #fc4f4f;
  display: inline-block;
  line-height: 1;
  padding: 12px 32px;
}

@media only screen and (max-width: 767px) {
  .delay-content-2 > span {
    letter-spacing: 3px;
    padding: 12px 30px;
  }
}

.delay-content-2 > h3 {
  font-size: 30px;
  letter-spacing: 1px;
  margin: 24px 0 8px;
}

@media only screen and (max-width: 767px) {
  .delay-content-2 > h3 {
    font-size: 25px;
    letter-spacing: 0px;
  }
}

.delay-content-2 > h2 {
  font-size: 180px;
  font-family: "Alegreya", serif;
  margin: 0 0 0px;
  line-height: 170px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .delay-content-2 > h2 {
    line-height: 145px;
    font-size: 155px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .delay-content-2 > h2 {
    line-height: 110px;
    font-size: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .delay-content-2 > h2 {
    line-height: 125px;
    font-size: 135px;
  }
}

@media only screen and (max-width: 767px) {
  .delay-content-2 > h2 {
    line-height: 80px;
    font-size: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .delay-content-2 > h2 {
    line-height: 85px;
    font-size: 80px;
  }
}

.delay-content-2 .delay-price {
  margin: 27px 0 30px;
}

@media only screen and (max-width: 767px) {
  .delay-content-2 .delay-price {
    margin: 15px 0 20px;
  }
}

.delay-content-2 .delay-price span {
  font-size: 32px;
  color: #fc4f4f;
  margin: 0 5px;
}

.delay-content-2 .delay-price span.old-price {
  color: #262626;
  text-decoration: line-through;
}

.delay-content-3 > span {
  font-size: 36px;
  display: block;
  line-height: 30px;
}

@media only screen and (max-width: 767px) {
  .delay-content-3 > span {
    font-size: 24px;
    line-height: 24px;
  }
}

.delay-content-3 > h2 {
  font-size: 180px;
  line-height: 150px;
  margin: 25px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .delay-content-3 > h2 {
    font-size: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .delay-content-3 > h2 {
    font-size: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .delay-content-3 > h2 {
    font-size: 100px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .delay-content-3 > h2 {
    font-size: 100px;
    line-height: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .delay-content-3 > h2 {
    font-size: 65px;
    line-height: 54px;
  }
}

.delay-content-3 .delay-price-2 {
  margin: 22px 0 48px;
}

@media only screen and (max-width: 767px) {
  .delay-content-3 .delay-price-2 {
    margin: 15px 0 20px;
  }
}

.delay-content-3 .delay-price-2 span {
  font-size: 32px;
  color: #fc4f4f;
  margin: 0 5px;
}

.delay-content-3 .delay-price-2 span.old-price {
  color: #262626;
  text-decoration: line-through;
}

.delay-content-3.delay-content-mrg-3 {
  margin-left: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .delay-content-3.delay-content-mrg-3 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .delay-content-3.delay-content-mrg-3 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .delay-area.pt-190 {
    padding-top: 80px;
  }
  .delay-area.pb-190 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .delay-area.pt-190 {
    padding-top: 50px;
  }
  .delay-area.pb-190 {
    padding-bottom: 50px;
  }
}

/*-------- 7. Subscribe style ---------*/
.subscribe-content-form-wrap {
  display: flex;
  margin: 0 10px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .subscribe-content-form-wrap {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-content-form-wrap {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-content-form-wrap {
    display: block;
    text-align: center;
    margin: 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-content-form-wrap {
    display: block;
    text-align: center;
    margin: 0 0 0px;
  }
}

.subscribe-content-form-wrap .subscribe-width-34 {
  flex: 0 0 34.188%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-content-form-wrap .subscribe-width-34 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-content-form-wrap .subscribe-width-34 {
    width: 100%;
  }
}

.subscribe-content-form-wrap .subscribe-width-65 {
  flex: 0 0 65.812%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-content-form-wrap .subscribe-width-65 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-content-form-wrap .subscribe-width-65 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-content-form-wrap .subscribe-content {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-content-form-wrap .subscribe-content {
    margin: 0 0 20px;
  }
}

.subscribe-content-form-wrap .subscribe-content h2 {
  font-size: 42px;
  font-family: "Alegreya", serif;
  margin: -4px 0 20px;
  text-transform: uppercase;
  line-height: 32px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-content-form-wrap .subscribe-content h2 {
    font-size: 39px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-content-form-wrap .subscribe-content h2 {
    font-size: 35px;
    margin: 0px 0 10px;
    line-height: 25px;
  }
}

.subscribe-content-form-wrap .subscribe-content p {
  font-size: 16px;
  width: 80%;
  margin: 0 0 0px;
  color: #262626;
  line-height: 1.7em;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-content-form-wrap .subscribe-content p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-content-form-wrap .subscribe-content p {
    width: 100%;
    line-height: 1.5em;
  }
}

.subscribe-content-form-wrap .subscribe-content.subscribe-content-white h2 {
  color: #fff;
}

.subscribe-content-form-wrap .subscribe-content.subscribe-content-white p {
  color: rgba(255, 255, 255, 0.55);
}

.subscribe-content-form-wrap .subscribe-content.subscribe-content-hm4 h2 {
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  font-size: 44px;
  margin: -4px 0 32px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-content-form-wrap .subscribe-content.subscribe-content-hm4 h2 {
    font-size: 34px;
    margin: -4px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-content-form-wrap .subscribe-content.subscribe-content-hm4 h2 {
    font-size: 34px;
    margin: -4px 0 25px;
  }
}

.subscribe-form form {
  position: relative;
}

.subscribe-form form .mc-form input {
  background-color: transparent;
  color: #262626;
  font-size: 16px;
  border: 1px solid #e0e0e8;
  height: 87px;
  font-weight: 300;
  padding: 5px 150px 5px 30px;
}

@media only screen and (max-width: 767px) {
  .subscribe-form form .mc-form input {
    padding: 5px 95px 5px 10px;
    height: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-form form .mc-form input {
    padding: 5px 125px 5px 20px;
  }
}

.subscribe-form form .mc-form input::-moz-input-placeholder {
  color: #262626;
  opacity: 1;
}

.subscribe-form form .mc-form input::-webkit-input-placeholder {
  color: #262626;
  opacity: 1;
}

.subscribe-form form .mc-form .mc-news {
  display: none;
}

.subscribe-form form .mc-form .clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.subscribe-form form .mc-form .clear input {
  width: auto;
  border: none;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #262626;
  padding: 5px 30px;
}

@media only screen and (max-width: 767px) {
  .subscribe-form form .mc-form .clear input {
    padding: 5px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-form form .mc-form .clear input {
    padding: 5px 20px;
  }
}

.subscribe-form form .mc-form .clear input::-moz-input-placeholder {
  color: #262626;
  opacity: 1;
}

.subscribe-form form .mc-form .clear input::-webkit-input-placeholder {
  color: #262626;
  opacity: 1;
}

.subscribe-form form .mc-form .clear input:hover {
  color: #fc4f4f;
}

.subscribe-form.subscribe-form-white form .mc-form input {
  color: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.subscribe-form.subscribe-form-white form .mc-form input::-moz-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.subscribe-form.subscribe-form-white form .mc-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.subscribe-form.subscribe-form-white form .mc-form .clear input {
  color: rgba(255, 255, 255, 0.4);
  border: none;
  font-weight: 300;
}

.subscribe-form.subscribe-form-white form .mc-form .clear input::-moz-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.subscribe-form.subscribe-form-white form .mc-form .clear input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.subscribe-form.subscribe-form-white form .mc-form .clear input:hover {
  color: #fc4f4f;
}

.subscribe-form.subscribe-form-hm19 form .mc-form input {
  color: rgba(255, 255, 255, 0.91);
  font-size: 18px;
  font-weight: 300;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 5px 160px 5px 30px;
}

@media only screen and (max-width: 767px) {
  .subscribe-form.subscribe-form-hm19 form .mc-form input {
    padding: 5px 125px 5px 10px;
  }
}

.subscribe-form.subscribe-form-hm19 form .mc-form input::-moz-input-placeholder {
  color: rgba(255, 255, 255, 0.91);
}

.subscribe-form.subscribe-form-hm19 form .mc-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.91);
}

.subscribe-form.subscribe-form-hm19 form .mc-form .clear input {
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 300;
  padding: 5px 36px;
  background-color: #fc4f4f;
}

@media only screen and (max-width: 767px) {
  .subscribe-form.subscribe-form-hm19 form .mc-form .clear input {
    padding: 5px 20px;
  }
}

.subscribe-form.subscribe-form-hm19 form .mc-form .clear input::-moz-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-form.subscribe-form-hm19 form .mc-form .clear input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-form.subscribe-form-hm19 form .mc-form .clear input:hover {
  background-color: #aa4039;
}

.subscribe-form.subscribe-form-hm4 form .mc-form input {
  border: none;
  background-color: #fff;
  height: 93px;
}

.subscribe-form.subscribe-form-hm4 form .mc-form .clear input {
  font-weight: 600;
}

.subscribe-form.subscribe-form-dec form .mc-form input {
  font-size: 14px;
}

.subscribe-form.subscribe-form-dec form .mc-form .clear input {
  font-size: 14px;
  font-weight: 400;
}

.subscribe-form.subscribe-mrg {
  margin: 0 35px;
}

@media only screen and (max-width: 767px) {
  .subscribe-form.subscribe-mrg {
    margin: 0 0px;
  }
}

.subscribe-form-2 form .mc-form-2 {
  position: relative;
}

.subscribe-form-2 form .mc-form-2 input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #a6a6a6;
  font-size: 18px;
  font-weight: 300;
  color: #262626;
  padding: 5px 100px 5px 0;
  height: 50px;
}

.subscribe-form-2 form .mc-form-2 input::-moz-input-placeholder {
  color: #262626;
  opacity: 1;
}

.subscribe-form-2 form .mc-form-2 input::-webkit-input-placeholder {
  color: #262626;
  opacity: 1;
}

.subscribe-form-2 form .mc-form-2 .mc-news-2 {
  display: none;
}

.subscribe-form-2 form .mc-form-2 .clear-2 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form-2 form .mc-form-2 .clear-2 input {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  border: none;
  width: auto;
  padding: 0 0px 0 0;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2 form .mc-form-2 .clear-2 input {
    font-size: 16px;
  }
}

.subscribe-form-2 form .mc-form-2 .clear-2 input:hover {
  color: #fc4f4f;
}

.subscribe-form-2.subscribe-mrg-lr {
  margin: 0 245px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-form-2.subscribe-mrg-lr {
    margin: 0 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-2.subscribe-mrg-lr {
    margin: 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2.subscribe-mrg-lr {
    margin: 0 0px;
  }
}

.subscribe-form-2.subscribe-mrg-lr-2 {
  margin: 0 198px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .subscribe-form-2.subscribe-mrg-lr-2 {
    margin: 0 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .subscribe-form-2.subscribe-mrg-lr-2 {
    margin: 0 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-form-2.subscribe-mrg-lr-2 {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-2.subscribe-mrg-lr-2 {
    margin: 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2.subscribe-mrg-lr-2 {
    margin: 0 0px;
  }
}

.subscribe-form-2.subscribe-mrg-lr-3 {
  margin: 0 312px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .subscribe-form-2.subscribe-mrg-lr-3 {
    margin: 0 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .subscribe-form-2.subscribe-mrg-lr-3 {
    margin: 0 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-form-2.subscribe-mrg-lr-3 {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-2.subscribe-mrg-lr-3 {
    margin: 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2.subscribe-mrg-lr-3 {
    margin: 0 0px;
  }
}

.subscribe-form-2.subscribe-form-w400 form .mc-form-2 input {
  font-weight: 400;
}

.subscribe-area-mrg {
  margin: 0 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .subscribe-area-mrg {
    margin: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .subscribe-area-mrg {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-area-mrg {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-area-mrg {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-area-mrg {
    margin: 0 15px;
  }
}

.subscribe-ptb {
  padding: 50px 0px;
}

.subscribe-footer-wrap p {
  color: #767676;
  font-size: 12px;
  margin: 0 0 20px;
  width: 80%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .subscribe-footer-wrap p {
    width: 95%;
  }
}

.subscribe-footer-wrap .subscribe-form-3 form .mc-form-3 input {
  border: 1px solid rgba(255, 255, 255, 0.14);
  background-color: transparent;
  color: #767676;
  font-size: 12px;
  width: 80%;
  height: 40px;
  padding: 2px 2px 2px 20px;
  border-bottom: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .subscribe-footer-wrap .subscribe-form-3 form .mc-form-3 input {
    width: 90%;
  }
}

.subscribe-footer-wrap .subscribe-form-3 form .mc-form-3 input::-moz-input-placeholder {
  color: #767676;
  opacity: 1;
}

.subscribe-footer-wrap .subscribe-form-3 form .mc-form-3 input::-webkit-input-placeholder {
  color: #767676;
  opacity: 1;
}

.subscribe-footer-wrap .subscribe-form-3 form .mc-form-3 .mc-news-3 {
  display: none;
}

.subscribe-footer-wrap .subscribe-form-3 form .mc-form-3 .clear-3 input {
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.14);
  border-top: none;
  color: #767676;
}

.subscribe-footer-wrap .subscribe-form-3 form .mc-form-3 .clear-3 input:hover {
  color: #6ec1e4;
}

@media only screen and (max-width: 767px) {
  .pb-75 {
    padding-bottom: 50px;
  }
  .subscribe-area.pt-70 {
    padding-top: 30px;
  }
}

/*-------- 8. Footer style ---------*/
.footer-menu nav ul li {
  display: inline-block;
  margin: 0 25px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-menu nav ul li {
    margin: 0 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-menu nav ul li {
    margin: 0 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-menu nav ul li {
    margin: 0 3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu nav ul li {
    margin: 0 10px 3px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu nav ul li {
    margin: 0 7px 3px;
  }
}

.footer-menu nav ul li a {
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
}

.footer-menu nav ul li a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .footer-menu {
    margin: 20px 0px 15px;
  }
}

.social-icon-style-2 a {
  display: inline-block;
  font-size: 16px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  transform: scale(1);
  margin: 0 0px 0 5px;
  border: 1px solid rgba(255, 255, 255, 0.16);
}

.social-icon-style-2 a:first-child {
  margin: 0 0px 0 0px;
}

.social-icon-style-2 a:hover {
  transform: scale(1.1);
  opacity: .9;
  border: 1px solid transparent;
}

.social-icon-style-2 a:hover.facebook {
  background-color: #3b5998;
}

.social-icon-style-2 a:hover.twitter {
  background-color: #1da1f2;
}

.social-icon-style-2 a:hover.youtube {
  background-color: #cd201f;
}

.social-icon-style-2 a:hover.dribbble {
  background-color: #ea4c89;
}

.social-icon-style-2.social-icon-hm4 a {
  color: #777777;
  border: 1px solid rgba(0, 0, 0, 0.14);
}

.social-icon-style-2.social-icon-hm4 a:hover {
  color: #fff;
  border: 1px solid transparent;
}

.social-icon-style-2.social-icon-hm4.social-icon-square a {
  border-radius: 0%;
  width: 32px;
  height: 32px;
  color: #fff;
  line-height: 32px;
}

.social-icon-style-2.social-icon-hm4.social-icon-square a:hover {
  transform: scale(1);
  opacity: 1;
}

.social-icon-style-2.social-icon-right {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .social-icon-style-2.social-icon-right {
    text-align: center;
  }
}

.footer-column {
  position: relative;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.footer-column.footer-width-26 {
  flex: 0 0 26.036%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-26 {
    flex: 0 0 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-26 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-15 {
  flex: 0 0 15.423%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-15 {
    flex: 0 0 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-15 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-17 {
  flex: 0 0 17.164%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-17 {
    flex: 0 0 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-17 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-25 {
  flex: 0 0 25.871%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-25 {
    flex: 0 0 66.667%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-25 {
    flex: 0 0 100%;
  }
}

.footer-column.footer-logo-center {
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-logo-center {
    align-items: flex-start;
  }
}

.footer-column.footer-width-24 {
  flex: 0 0 24.585%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-24 {
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-24 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-24 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-24 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-12 {
  flex: 0 0 12.443%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-12 {
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-12 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-12 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-12 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-24-2 {
  flex: 0 0 24.627%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-24-2 {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-24-2 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-24-2 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-24-2 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-13 {
  flex: 0 0 13.184%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-13 {
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-13 {
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-13 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-13 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-11 {
  flex: 0 0 11.94%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-11 {
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-11 {
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-11 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-11 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-23 {
  flex: 0 0 23.051%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-23 {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-23 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-23 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-23 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-27 {
  flex: 0 0 27.198%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-27 {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-27 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-27 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-27 {
    flex: 0 0 100%;
  }
}

.footer-column.footer-width-14 {
  flex: 0 0 14.48%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-14 {
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-14 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-14 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-14 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-19 {
  flex: 0 0 19.492%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-19 {
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-19 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-19 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-19 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-29 {
  flex: 0 0 29%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-29 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-29 {
    flex: 0 0 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-29 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-29 {
    flex: 0 0 100%;
  }
}

.footer-column.footer-width-30 {
  flex: 0 0 30.467%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-30 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-30 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-30 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-20 {
  flex: 0 0 20%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-20 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-20 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-20 {
    flex: 0 0 50%;
  }
}

.footer-column.footer-width-29-2 {
  flex: 0 0 29.533%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-29-2 {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-29-2 {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-29-2 {
    flex: 0 0 50%;
  }
}

.footer-about {
  margin-top: 55px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-about {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-about {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-about {
    margin-top: 0px;
  }
}

.footer-about > a img {
  width: 140px;
}

.footer-about p {
  color: #767676;
  line-height: 1.84;
  width: 70%;
  margin: 16px 0 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-about p {
    width: 73%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-about p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-about p {
    width: 100%;
    margin: 10px 0 20px;
  }
}

.footer-about.footer-about-hm10 {
  margin-top: 28px;
}

@media only screen and (max-width: 767px) {
  .footer-about.footer-about-hm10 {
    margin-top: 0px;
  }
}

.footer-about.footer-about-hm10 p {
  margin: 30px 0 19px;
}

@media only screen and (max-width: 767px) {
  .footer-about.footer-about-hm10 p {
    margin: 15px 0 12px;
  }
}

.copyright-2 {
  margin-bottom: 27px;
}

.copyright-2 p {
  color: #767676;
  font-size: 12px;
  margin: 0;
}

.copyright-2 p a {
  color: #767676;
}

.copyright-2 p a:hover {
  color: #fc4f4f;
}

.copyright-2.copyright-sky p a {
  color: #767676;
}

.copyright-2.copyright-sky p a:hover {
  color: #6ec1e4;
}

.footer-widget {
  width: 100%;
}

.footer-logo {
  margin-top: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-logo {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-logo {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-logo {
    margin-top: 0px;
  }
}

.footer-info-list h3.footer-title {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 0 35px;
  line-height: 12px;
}

@media only screen and (max-width: 767px) {
  .footer-info-list h3.footer-title {
    margin: 0 0 20px;
  }
}

.footer-info-list h3.footer-title.ft-letter-space-inc {
  letter-spacing: 2px;
}

.footer-info-list ul li {
  display: block;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .footer-info-list ul li {
    margin-bottom: 10px;
  }
}

.footer-info-list ul li:last-child {
  margin-bottom: 0px;
}

.footer-info-list ul li a {
  color: #767676;
  display: block;
}

.footer-info-list ul li a:hover {
  color: #fc4f4f;
}

.footer-info-list.fi-sky-color ul li a {
  color: #767676;
}

.footer-info-list.fi-sky-color ul li a:hover {
  color: #6ec1e4;
}

.footer-info-list.footer-list-hm10 h3.footer-title {
  text-transform: capitalize;
  color: #fff;
}

.footer-info-list.footer-list-hm10 ul li {
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .footer-info-list.footer-list-hm10 ul li {
    margin-bottom: 8px;
  }
}

.footer-info-list.footer-list-hm10 ul li a {
  font-size: 12px;
}

.footer-info-list.footer-list-hm10 ul li a:hover {
  color: #fc4f4f;
}

.footer-info-list-2 h3.footer-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 33px;
  line-height: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-info-list-2 h3.footer-title {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-info-list-2 h3.footer-title {
    margin: 0 0 20px;
  }
}

.footer-info-list-2 ul li {
  color: #767676;
  font-size: 12px;
  margin: 0 0 15px;
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-info-list-2 ul li {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-info-list-2 ul li {
    margin: 0 0 5px;
  }
}

.footer-info-list-2 ul li:last-child {
  margin-bottom: 0;
}

.footer-info-list-2 ul li a {
  color: #767676;
  display: block;
  font-size: 12px;
}

.footer-info-list-2 ul li a:hover {
  color: #fc4f4f;
}

.footer-info-list-2 ul li i {
  font-size: 26px;
  position: relative;
  top: 0px;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .footer-info-list-2 ul li i {
    font-size: 20px;
    top: 3px;
  }
}

.footer-info-list-2.footer-contect ul li {
  margin: 0 0 31px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-info-list-2.footer-contect ul li {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-info-list-2.footer-contect ul li {
    margin: 0 0 10px;
  }
}

.footer-info-list-2.footer-contect ul li:last-child {
  margin-bottom: 0;
}

.footer-info-list-2.footer-contect.footer-info-font-inc ul {
  margin: 38px 0 0;
}

.footer-info-list-2.footer-contect.footer-info-font-inc ul li {
  font-size: 14px;
  margin: 0 0 16px;
}

.footer-info-list-2.footer-contect.footer-list-hm10 ul li {
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .footer-info-list-2.footer-contect.footer-list-hm10 ul li {
    margin: 0 0 14px;
  }
}

.footer-info-list-2.footer-contect.footer-list-hm10 ul li:last-child {
  margin-bottom: 0;
}

.footer-info-list-2.footer-list-hm10 h3.footer-title {
  text-transform: capitalize;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.contact-page-map {
  width: 100%;
}

.contact-page-map #contact-map {
  height: 300px;
}

.footer-payment {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .footer-payment {
    text-align: center;
  }
}

.footer-plr {
  padding: 0 50px;
}

@media only screen and (max-width: 767px) {
  .footer-plr {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-top.pt-95 {
    padding-top: 65px;
  }
  .footer-top.pb-80 {
    padding-bottom: 34px;
  }
  .footer-top.pb-35 {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .logo-res-center {
    text-align: center;
  }
  .social-icon-center-res {
    text-align: center;
  }
  .footer-top.pb-80 {
    padding-bottom: 40px;
  }
  .footer-top.pb-35 {
    padding-bottom: 0px;
  }
  .footer-top.pb-60 {
    padding-bottom: 10px;
  }
  .res-mrg-xs {
    margin: 10px 0;
  }
}

/*-------- 9. Others style ---------*/
.mfp-bg {
  z-index: 9999;
}

.mfp-wrap {
  z-index: 99999;
}

.support-lists {
  position: fixed;
  top: 70%;
  right: 50px;
  list-style: none;
  width: 60px;
  background: #FFFFFF;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .support-lists {
    right: 38px;
    width: 40px;
    top: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .support-lists {
    right: 15px;
    width: 50px;
    top: 75%;
    display: none;
  }
}

.support-lists ul li {
  display: block;
}

.support-lists ul li a {
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  color: #535353;
}

.support-lists ul li a:hover {
  color: #fc4f4f;
}

.view-demo-area {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  z-index: 9999;
}

.view-demo-area > a {
  color: #fff;
  background: #282828;
  font-size: 14px;
  line-height: 18px;
  border: none;
  width: 76px;
  height: 76px;
  text-align: left;
  padding: 20px 10px 10px;
  position: absolute;
  right: 520px;
  top: 60%;
  transition: all 400ms ease-out;
  transform: translate(520px, 0);
}

@media only screen and (max-width: 767px) {
  .view-demo-area > a {
    right: 200px;
    transform: translate(200px, 0);
    font-size: 12px;
    width: 60px;
    height: 60px;
    padding: 10px 10px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .view-demo-area > a {
    right: 400px;
    transform: translate(400px, 0);
  }
}

.view-demo-area .view-demo-aside-active {
  position: absolute;
  width: 520px;
  background: #fff;
  top: 0;
  right: 0;
  height: 100%;
  padding: 50px 50px;
  box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.13);
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  transform: translate(520px, 0);
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .view-demo-area .view-demo-aside-active {
    transform: translate(200px, 0);
    width: 200px;
    padding: 40px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .view-demo-area .view-demo-aside-active {
    transform: translate(400px, 0);
    width: 400px;
  }
}

.view-demo-area .view-demo-aside-active .view-demo-wrap .view-demo-tab-list {
  margin-bottom: 34px;
}

.view-demo-area .view-demo-aside-active .view-demo-wrap .view-demo-tab-list a {
  color: #282828;
  display: inline-block;
  border-bottom: 2px solid transparent;
  padding: 0 0 3px;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .view-demo-area .view-demo-aside-active .view-demo-wrap .view-demo-tab-list a {
    margin-bottom: 10px;
  }
}

.view-demo-area .view-demo-aside-active .view-demo-wrap .view-demo-tab-list a:last-child {
  margin-right: 0px;
}

.view-demo-area .view-demo-aside-active .view-demo-wrap .view-demo-tab-list a.active {
  border-bottom: 2px solid #fc4f4f;
}

.view-demo-area.demo-visible > a {
  transform: translate(0px, 0);
}

.view-demo-area.demo-visible .view-demo-aside-active {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.demo-item .demo-item-img {
  position: relative;
  margin: 0 0 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .demo-item .demo-item-img {
    margin: 0 0 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .demo-item .demo-item-img {
    margin: 0 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .demo-item .demo-item-img {
    margin: 0 0 15px;
  }
}

.demo-item .demo-item-img::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all .3s;
  background: #FC4F4F;
  opacity: 0;
  pointer-events: none;
}

.demo-item .demo-item-img a {
  display: block;
}

.demo-item .demo-item-img a img {
  width: 100%;
}

.demo-item .demo-item-img a span {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  font-size: 13px;
  color: #fff;
  transition: all .3s ease 0s;
}

.demo-item .demo-item-content h4 {
  font-size: 13px;
  margin: 0;
  color: #282828;
}

.demo-item .demo-item-content h4 a {
  color: #282828;
}

.demo-item:hover .demo-item-img::before {
  opacity: 0.9;
}

.demo-item:hover .demo-item-img a span {
  opacity: 1;
  visibility: visible;
}

.discover-more-btn a {
  display: inline-block;
  color: #fff;
  background-color: #fc4f4f;
  line-height: 1;
  padding: 15px 30px;
}

.discover-more-btn a:hover {
  background-color: #262626;
}

.modal-backdrop.show {
  opacity: .7;
}

.modal-dialog {
  margin: 9% auto 8%;
  max-width: 1133px;
  width: 1133px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog {
    width: 960px;
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 720px;
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}

.modal-dialog .modal-content {
  border-radius: 0;
}

.modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0;
  right: -13px;
  top: -54px;
  position: absolute;
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header {
    right: 0px;
    top: -34px;
  }
}

.modal-dialog .modal-content .modal-header .close {
  color: #fff;
  cursor: pointer;
  opacity: 1;
  padding: 0;
  margin: 0;
  width: 54px;
  height: 54px;
  line-height: 57px;
  background-color: #292929;
  border-radius: 100%;
  font-size: 18px;
  text-shadow: none;
  font-weight: 300;
  border: none;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header .close {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 15px;
  }
}

.modal-dialog .modal-content .modal-header .close:hover {
  color: #fc4f4f;
  transform: rotate(90deg);
}

.modal-dialog .modal-content .modal-body {
  padding: 0px;
}

.product-details-content {
  padding: 34px 40px 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content {
    padding: 15px 35px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content {
    padding: 20px 35px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content {
    padding: 15px 15px 15px;
  }
}

.product-details-content .pro-dec-next-prev {
  text-align: right;
}

.product-details-content .pro-dec-next-prev a {
  font-size: 20px;
  margin-left: 7px;
}

.product-details-content .pro-dec-next-prev a:first-child {
  margin-left: 0;
}

.product-details-content h2 {
  font-size: 24px;
  margin: 0 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 20px;
  }
}

.product-details-content h2.uppercase {
  text-transform: uppercase;
}

.product-details-content > h3 {
  font-size: 38px;
  margin: 36px 0 32px;
  line-height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content > h3 {
    margin: 20px 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content > h3 {
    margin: 10px 0 10px;
    font-size: 30px;
  }
}

.product-details-content .product-details-peragraph p {
  font-size: 14px;
  line-height: 1.714286;
  color: #2F2F2F;
}

.product-details-content .product-details-action-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 32px 0 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 26px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-action-wrap {
    margin: 30px 0 30px;
    padding: 10px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-details-action-wrap {
    margin: 30px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-wrap {
    margin: 20px 0 20px;
    display: flex;
    padding: 15px 0;
    margin: 25px 0 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-action-wrap {
    display: flex;
  }
}

.product-details-content .product-details-action-wrap .product-details-cart {
  margin: 0 10px 0 10px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-cart {
    margin: 0 0px 10px 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-cart {
    margin: 0 26px 0 10px;
  }
}

.product-details-content .product-details-action-wrap .product-details-cart a {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  padding: 22px 50px;
  background-color: #262626;
  font-weight: bold;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content .product-details-action-wrap .product-details-cart a {
    padding: 22px 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-action-wrap .product-details-cart a {
    padding: 22px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-cart a {
    padding: 22px 22px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-cart a {
    padding: 22px 30px;
  }
}

.product-details-content .product-details-action-wrap .product-details-cart a:hover {
  background-color: #fc4f4f;
}

.product-details-content .product-details-action-wrap .product-details-cart.product-dec-cart-mrg-none {
  margin: 0 10px 0 0px;
}

.product-details-content .product-details-action-wrap .product-details-wishlist a, .product-details-content .product-details-action-wrap .product-details-compare a {
  display: inline-block;
  font-size: 22px;
  color: #262626;
  background-color: transparent;
  padding: 17px 22px;
}

.product-details-content .product-details-action-wrap .product-details-wishlist a:hover, .product-details-content .product-details-action-wrap .product-details-compare a:hover {
  background-color: #fc4f4f;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-wishlist.affiliate-res-xs {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-wishlist.affiliate-res-xs {
    margin-bottom: 0px;
  }
}

.product-details-content .product-details-action-wrap .product-details-compare {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-compare.affiliate-res-xs {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-compare.affiliate-res-xs {
    margin-left: 10px;
  }
}

.product-details-content .product-details-info a {
  color: #2F2F2F;
  font-size: 12px;
  margin-right: 16px;
}

.product-details-content .product-details-info a:last-child {
  margin-right: 0;
}

.product-details-content .product-details-info a i {
  font-size: 16px;
  padding-right: 8px;
  position: relative;
  top: 3px;
}

.product-details-content .product-details-info a:hover {
  color: #fc4f4f;
}

.product-details-content .product-details-meta {
  margin: 25px 0 30px;
}

.product-details-content .product-details-meta > span {
  display: block;
  font-size: 12px;
  margin: 0 0 5px;
  font-weight: 600;
  line-height: 20px;
}

.product-details-content .product-details-meta > span a {
  font-weight: 400;
}

.product-details-content .product-details-meta > span a:hover {
  color: #fc4f4f;
  text-decoration: underline;
}

.product-details-content .product-details-meta > span:last-child {
  margin: 0 0 0px;
}

.product-details-content.p-dec-content-edit {
  padding: 0px 80px 20px 35px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-content.p-dec-content-edit {
    padding: 0px 0px 20px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content.p-dec-content-edit {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.p-dec-content-edit {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.p-dec-content-edit {
    padding: 30px 0px 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.p-dec-content-edit {
    padding: 30px 0px 0px 0px;
  }
}

.product-details-content.p-dec-content-edit .product-details-ratting-wrap {
  display: flex;
  margin: 23px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.p-dec-content-edit .product-details-ratting-wrap {
    margin: 10px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.p-dec-content-edit .product-details-ratting-wrap {
    margin: 10px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.p-dec-content-edit .product-details-ratting-wrap {
    margin: 10px 0 0;
  }
}

.product-details-content.p-dec-content-edit .product-details-ratting-wrap .product-details-ratting i {
  color: #d5d6d8;
  font-size: 14px;
  margin-left: 2px;
}

.product-details-content.p-dec-content-edit .product-details-ratting-wrap .product-details-ratting i.yellow {
  color: #f5b223;
}

.product-details-content.p-dec-content-edit .product-details-ratting-wrap a {
  color: #262626;
  font-size: 12px;
  margin-left: 10px;
}

.product-details-content.p-dec-content-edit > h3 {
  margin: 32px 0 57px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.p-dec-content-edit > h3 {
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.p-dec-content-edit > h3 {
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.p-dec-content-edit > h3 {
    margin: 10px 0 10px;
  }
}

.product-details-content.p-dec-content-edit .product-details-peragraph ul {
  margin: 30px 0 0;
}

@media only screen and (max-width: 767px) {
  .product-details-content.p-dec-content-edit .product-details-peragraph ul {
    margin: 10px 0 0;
  }
}

.product-details-content.p-dec-content-edit .product-details-peragraph ul li {
  font-size: 12px;
  line-height: 1;
  margin: 0 0 29px;
  list-style: inside circle;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.p-dec-content-edit .product-details-peragraph ul li {
    margin: 0 0 10px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.p-dec-content-edit .product-details-peragraph ul li {
    margin: 0 0 10px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.p-dec-content-edit .product-details-peragraph ul li {
    line-height: 24px;
    margin: 0 0 12px;
  }
}

.product-details-content.p-dec-content-edit .product-details-peragraph ul li:last-child {
  margin: 0 0 0px;
}

.product-details-content.p-dec-content-edit .product-details-action-wrap {
  margin: 50px 0 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.p-dec-content-edit .product-details-action-wrap {
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.p-dec-content-edit .product-details-action-wrap {
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.p-dec-content-edit .product-details-action-wrap {
    margin: 20px 0 20px;
  }
}

.product-details-content.p-dec-content-edit .product-details-action-wrap.dec-action-border-none {
  border-top: none;
  margin: 0px 0 30px;
  padding: 18px 0 26px;
}

.product-details-content.p-dec-content-edit .product-details-meta {
  margin: 46px 0 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.p-dec-content-edit .product-details-meta {
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.p-dec-content-edit .product-details-meta {
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.p-dec-content-edit .product-details-meta {
    margin: 20px 0 20px;
  }
}

.product-details-content.p-dec-content-edit.p-dec-padding-none {
  padding: 0px 0px 20px 35px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-content.p-dec-content-edit.p-dec-padding-none {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content.p-dec-content-edit.p-dec-padding-none {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.p-dec-content-edit.p-dec-padding-none {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.p-dec-content-edit.p-dec-padding-none {
    padding: 30px 0px 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.p-dec-content-edit.p-dec-padding-none {
    padding: 30px 0px 0px 0px;
  }
}

.product-details-quality {
  margin: 7px 0 0;
}

.product-details-quality .cart-plus-minus {
  display: inline-block;
  height: 56px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100px;
  text-align: center;
}

.product-details-quality .cart-plus-minus .qtybutton {
  color: #282828;
  cursor: pointer;
  float: inherit;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 24px;
}

.product-details-quality .cart-plus-minus .qtybutton.dec {
  height: 54px;
  top: 17px;
  left: 4px;
  font-weight: 400;
  font-size: 22px;
}

.product-details-quality .cart-plus-minus .qtybutton.inc {
  height: 54px;
  top: 18px;
  right: 4px;
  font-weight: 400;
  font-size: 22px;
}

.product-details-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #282828;
  font-size: 14px;
  height: 54px;
  line-height: 54px;
  margin: 0;
  padding: 0px;
  text-align: center;
  width: 100px;
  font-weight: 600;
}

.product-details-quality.pro-quality-border .cart-plus-minus input {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.product-details-quality.quality-width-cart .cart-plus-minus {
  width: 80px;
}

.product-details-quality.quality-width-cart .cart-plus-minus input {
  width: 80px;
}

.quickview-slider-active {
  position: relative;
}

.quickview-slider-active a span {
  display: inline-block;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #262626;
  color: #fff;
  font-size: 10px;
  min-width: 90px;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
}

.quickview-slider-active.owl-carousel > .owl-nav div {
  color: #000;
  display: inline-block;
  font-size: 24px;
  left: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  border: 1px solid #e1e2e2;
  background-color: #ffffff;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 52px;
  border-radius: 100%;
}

.quickview-slider-active.owl-carousel > .owl-nav div.owl-next {
  right: 20px;
  left: auto;
}

.quickview-slider-active.owl-carousel > .owl-nav div:hover {
  color: #fc4f4f;
}

.social-icon-style-3 a {
  border: 1px solid #e1e1e1;
  color: #777;
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 100%;
  text-align: center;
  font-size: 16px;
  margin: 0 7px 0 0;
}

.social-icon-style-3 a:hover {
  color: #fff;
  border: 1px solid transparent;
}

.social-icon-style-3 a:hover.facebook {
  background-color: #3b5999;
}

.social-icon-style-3 a:hover.twitter {
  background-color: #55acee;
}

.social-icon-style-3 a:hover.linkedin {
  background-color: #0173a9;
}

.social-icon-style-3 a:hover.pinterest {
  background-color: #b90f16;
}

.social-icon-style-3 a:hover.google-plus {
  background-color: #dd4b39;
}

.notification-wrapper {
  position: relative;
  padding: 20px 0;
}

.notification-wrapper .notification-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .notification-wrapper .notification-content {
    display: block;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .notification-wrapper .notification-content {
    display: flex;
  }
}

.notification-wrapper .notification-content h3 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 18px;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .notification-wrapper .notification-content h3 {
    letter-spacing: 2px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .notification-wrapper .notification-content h3 {
    letter-spacing: 0px;
    font-size: 16px;
  }
}

.notification-wrapper .notification-content .notification-btn {
  margin-left: 41px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .notification-wrapper .notification-content .notification-btn {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .notification-wrapper .notification-content .notification-btn {
    margin-left: 0px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .notification-wrapper .notification-content .notification-btn {
    margin-left: 20px;
    margin-top: 0px;
  }
}

.notification-wrapper .notification-content .notification-btn a {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 2px solid #fff;
  color: #fff;
  padding: 16px 48px 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .notification-wrapper .notification-content .notification-btn a {
    padding: 12px 25px 14px;
  }
}

@media only screen and (max-width: 767px) {
  .notification-wrapper .notification-content .notification-btn a {
    padding: 7px 25px 9px;
  }
}

.notification-wrapper .notification-content .notification-btn a:hover {
  border: 2px solid #262626;
  background-color: #262626;
}

.notification-wrapper .notification-close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  line-height: 1;
}

.notification-wrapper .notification-close button {
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 40px;
  padding: 0;
  transition: all 250ms ease-out;
}

.notification-wrapper .notification-close button:hover {
  transform: rotate(90deg);
  color: #262626;
}

.single-choose {
  display: flex;
}

.single-choose .choose-icon {
  margin-right: 36px;
  margin-top: -19px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-choose .choose-icon {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-choose .choose-icon {
    margin-right: 15px;
    margin-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-choose .choose-icon {
    margin-right: 15px;
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-choose .choose-icon {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-choose .choose-icon {
    margin-top: 0px;
    margin-right: 15px;
  }
}

.single-choose .choose-icon i {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 50px;
  border-radius: 100%;
  background-color: #a8d1dd;
  color: #262626;
  text-align: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-choose .choose-icon i {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-choose .choose-icon i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-choose .choose-icon i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-choose .choose-icon i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

.single-choose .choose-content h3 {
  color: #fff;
  font-size: 24px;
  margin: 0 0 21px;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-choose .choose-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-choose .choose-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-choose .choose-content h3 {
    font-size: 20px;
    margin: 0 0 15px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .single-choose .choose-content h3 {
    font-size: 18px;
    margin: 0 0 8px;
    line-height: 32px;
  }
}

.single-choose .choose-content p {
  color: rgba(255, 255, 255, 0.84);
  line-height: 1.714286;
  width: 78%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-choose .choose-content p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .single-choose .choose-content p {
    width: 100%;
  }
}

.main-sidebar-right, .main-sidebar-right-2 {
  margin-left: 400px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-sidebar-right, .main-sidebar-right-2 {
    margin-left: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-sidebar-right, .main-sidebar-right-2 {
    margin-left: 260px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-sidebar-right, .main-sidebar-right-2 {
    margin-left: 260px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-sidebar-right, .main-sidebar-right-2 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right, .main-sidebar-right-2 {
    margin-left: 0px;
  }
}

.main-sidebar-right-2-wrap1 {
  padding: 100px 15px 0 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-sidebar-right-2-wrap1 {
    padding: 100px 15px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-sidebar-right-2-wrap1 {
    padding: 100px 15px 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-sidebar-right-2-wrap1 {
    padding: 80px 40px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right-2-wrap1 {
    padding: 50px 15px 0 15px;
  }
}

.main-sidebar-right-2-wrap2 {
  padding: 50px 90px 0 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-sidebar-right-2-wrap2 {
    padding: 50px 15px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-sidebar-right-2-wrap2 {
    padding: 50px 15px 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-sidebar-right-2-wrap2 {
    padding: 50px 40px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right-2-wrap2 {
    padding: 50px 15px 0 15px;
  }
}

.main-sidebar-right-2-wrap3 {
  padding: 100px 60px 0 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-sidebar-right-2-wrap3 {
    padding: 100px 15px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-sidebar-right-2-wrap3 {
    padding: 100px 15px 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-sidebar-right-2-wrap3 {
    padding: 80px 40px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right-2-wrap3 {
    padding: 50px 15px 0 15px;
  }
}

.main-sidebar-right-2-wrap4 {
  padding: 100px 15px 0 15px;
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right-2-wrap4 {
    padding: 50px 15px 0 15px;
  }
}

.main-sidebar-right-3 {
  margin-left: 120px;
  margin-right: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-sidebar-right-3 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right-3 {
    margin-left: 0px;
    margin-right: 60px;
  }
}

.error-area {
  height: 100vh;
  align-items: center;
  display: flex;
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-area {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .error-area {
    height: auto;
    padding: 50px 0;
  }
}

.error-content img {
  width: 648px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .error-content img {
    width: 450px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .error-content img {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-content img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content img {
    width: 280px;
  }
}

.error-content h3 {
  font-weight: 200;
  font-size: 46px;
  margin: 3px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-content h3 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h3 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h3 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content h3 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error-content h3 {
    font-size: 30px;
  }
}

.error-content p {
  font-weight: 300;
  font-size: 18px;
  font-family: "Alegreya", serif;
  margin: 16px 0 32px;
  color: #262626;
}

.comming-soon-img {
  background-image: url("../../assets/images/bg/bg-comming-soon-01.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.comming-soon2-img {
  background-image: url("../../assets/images/bg/bg-comming-soon-02.jpg");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.comming-soon3-img {
  background-image: url("../../assets/images/bg/bg-comming-soon-03.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 400px auto;
  height: 100vh;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .comming-soon3-img {
    background-size: 280px auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .comming-soon3-img {
    background-size: 250px auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comming-soon3-img {
    background-size: 200px auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comming-soon3-img {
    background-size: 200px auto;
  }
}

@media only screen and (max-width: 767px) {
  .comming-soon3-img {
    background-size: 250px auto;
  }
}

.comming-soon-area {
  height: 100vh;
}

.comming-soon-area .comming-soon-align {
  height: 100%;
}

.comming-soon-content {
  margin: 0 0 154px 250px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .comming-soon-content {
    margin: 0 0 100px 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comming-soon-content {
    margin: 0 0 100px 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comming-soon-content {
    margin: 0 0 100px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .comming-soon-content {
    margin: 0 0 80px 30px;
  }
}

.comming-soon-content h3 {
  font-size: 24px;
  color: #fff;
  margin: 0;
  position: relative;
  padding-left: 98px;
}

@media only screen and (max-width: 767px) {
  .comming-soon-content h3 {
    padding-left: 40px;
    font-size: 20px;
  }
}

.comming-soon-content h3:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 76px;
  height: 2px;
}

@media only screen and (max-width: 767px) {
  .comming-soon-content h3:before {
    width: 20px;
  }
}

.comming-soon-content h1 {
  font-size: 100px;
  font-family: "Alegreya", serif;
  color: #fff;
  line-height: 142px;
  margin: 0px 0 70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .comming-soon-content h1 {
    line-height: 100px;
    font-size: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .comming-soon-content h1 {
    line-height: 100px;
    font-size: 80px;
    margin: 0px 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comming-soon-content h1 {
    line-height: 80px;
    font-size: 60px;
    margin: 0px 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comming-soon-content h1 {
    line-height: 70px;
    font-size: 60px;
    margin: 10px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .comming-soon-content h1 {
    line-height: 40px;
    font-size: 30px;
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .comming-soon-content h1 {
    line-height: 60px;
    font-size: 50px;
    margin: 10px 0 30px;
  }
}

.comming-soon-content.comming-soon-content-black {
  margin: 0 0 0px 85px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .comming-soon-content.comming-soon-content-black {
    margin: 0 0 0px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .comming-soon-content.comming-soon-content-black {
    margin: 0 0 0px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comming-soon-content.comming-soon-content-black {
    margin: 0 0 0px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comming-soon-content.comming-soon-content-black {
    margin: 50px 50px 50px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .comming-soon-content.comming-soon-content-black {
    margin: 50px 15px 50px 15px;
  }
}

.comming-soon-content.comming-soon-content-black h3 {
  color: #262626;
}

.comming-soon-content.comming-soon-content-black h3:before {
  background-color: #262626;
}

.comming-soon-content.comming-soon-content-black h1 {
  color: #262626;
  font-size: 80px;
  margin: 0px 0 55px;
  line-height: 118px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .comming-soon-content.comming-soon-content-black h1 {
    font-size: 65px;
    margin: 0px 0 30px;
    line-height: 110px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .comming-soon-content.comming-soon-content-black h1 {
    font-size: 60px;
    margin: 0px 0 30px;
    line-height: 110px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comming-soon-content.comming-soon-content-black h1 {
    font-size: 50px;
    margin: 0px 0 30px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comming-soon-content.comming-soon-content-black h1 {
    font-size: 55px;
    margin: 0px 0 30px;
    line-height: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .comming-soon-content.comming-soon-content-black h1 {
    font-size: 30px;
    margin: 0px 0 10px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .comming-soon-content.comming-soon-content-black h1 {
    font-size: 45px;
    margin: 0px 0 20px;
    line-height: 60px;
  }
}

.timer3-position {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 57px;
}

@media only screen and (max-width: 767px) {
  .timer3-position {
    bottom: 30px;
  }
}

.timer-style-3 {
  margin: 0 0 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .timer-style-3 {
    margin: 0 0 37px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .timer-style-3 {
    margin: 0 0 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-3 {
    margin: 0 0 27px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-3 {
    margin: 0 0 20px;
  }
}

.timer-style-3 div span {
  display: inline-block;
  color: #fff;
  margin: 0 70px 0 0;
  text-align: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .timer-style-3 div span {
    margin: 0 40px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .timer-style-3 div span {
    margin: 0 40px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-3 div span {
    margin: 0 15px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .timer-style-3 div span {
    margin: 0 25px 0 0;
  }
}

.timer-style-3 div span > span {
  font-size: 32px;
  font-weight: 500;
  margin: 0 0px 0;
}

.timer-style-3 div span > p {
  color: #fff;
}

.comming-soon-form {
  margin-right: 375px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .comming-soon-form {
    margin-right: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .comming-soon-form {
    margin-right: 160px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comming-soon-form {
    margin-right: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comming-soon-form {
    margin-right: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .comming-soon-form {
    margin-right: 15px;
  }
}

.comming-soon-form form {
  position: relative;
}

.comming-soon-form form input {
  background-color: transparent;
  border: none;
  color: #777777;
  height: 44px;
  padding: 0 80px 0 0;
  border-bottom: 1px solid #262626;
}

.comming-soon-form form .mc-news {
  display: none;
}

.comming-soon-form form .clear {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.comming-soon-form form .clear input {
  border: none;
  width: auto;
  color: #262626;
  padding: 0 0px 0 0;
}

.comming-soon-form form .clear input:hover {
  color: #fc4f4f;
}

.comming-soon2-img img {
  width: 100%;
}

.coming-soon-area-2 {
  position: relative;
}

.coming-soon-area-2 .coming-soon-img {
  height: 100vh;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area-2 .coming-soon-img {
    position: static;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area-2 .coming-soon-img {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.coming-soon-area-2 .coming-soon-img img {
  height: 100%;
  transition: all 0.3s linear 0s;
  will-change: transform, top;
  z-index: 0;
  width: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area-2 .height-100vh {
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area-2 .height-100vh {
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose-area.pb-100 {
    padding-bottom: 50px;
  }
}

/* Preloader Css */
.preloader {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  z-index: 99999999;
}

.preloader .loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

.preloader .loading-center .loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 150px;
  width: 150px;
  margin-top: -75px;
  margin-left: -75px;
}

.preloader .loading-center .loading-center-absolute .object {
  width: 20px;
  height: 20px;
  background-color: #fc4f4f;
  float: left;
  margin-right: 20px;
  margin-top: 65px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}

.preloader .loading-center .loading-center-absolute .object.object_one {
  -webkit-animation: object_one 1.5s infinite;
  animation: object_one 1.5s infinite;
}

.preloader .loading-center .loading-center-absolute .object.object_two {
  -webkit-animation: object_two 1.5s infinite;
  animation: object_two 1.5s infinite;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.preloader .loading-center .loading-center-absolute .object.object_three {
  -webkit-animation: object_three 1.5s infinite;
  animation: object_three 1.5s infinite;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes object_one {
  75% {
    -webkit-transform: scale(0);
  }
}

@keyframes object_one {
  75% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}

@-webkit-keyframes object_two {
  75% {
    -webkit-transform: scale(0);
  }
}

@keyframes object_two {
  75% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}

@-webkit-keyframes object_three {
  75% {
    -webkit-transform: scale(0);
  }
}

@keyframes object_three {
  75% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}

/*-------- 10. Categories style ---------*/
.single-categories .img-zoom {
  overflow: hidden;
}

.single-categories .img-zoom a {
  display: block;
}

.single-categories .img-zoom a img {
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(1);
}

.single-categories .categorie-posution-1 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

.single-categories .categorie-posution-2 {
  position: absolute;
  right: 50px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

.single-categories .categorie-posution-3 {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-categories .categorie-posution-3 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-categories .categorie-posution-3 {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories .categorie-posution-3 {
    left: 15px;
  }
}

.single-categories .categories-content span {
  font-size: 16px;
  color: #fff;
}

.single-categories .categories-content h3 {
  font-size: 24px;
  color: #fff;
  line-height: 40px;
  margin: 10px 0px 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-categories .categories-content h3 {
    font-size: 21px;
    line-height: 34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-categories .categories-content h3 {
    font-size: 21px;
    line-height: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories .categories-content h3 {
    font-size: 21px;
    line-height: 34px;
  }
}

.single-categories .categories-content h3 a {
  color: #fff;
}

.single-categories .categories-content .categories-btn a {
  font-size: 11px;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  display: inline-block;
  border: 1px solid #fff;
  padding: 16px 25px 15px;
}

.single-categories .categories-content .categories-btn.categories-btn-black a {
  background-color: #262626;
  border: 1px solid #262626;
}

.single-categories .categories-content.categories-content-black span {
  color: #262626;
}

.single-categories .categories-content.categories-content-black h3 {
  color: #262626;
}

.single-categories .categories-content.categories-content-black h3 a {
  color: #262626;
}

.single-categories:hover::before {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  pointer-events: none;
}

.single-categories:hover .img-zoom a img {
  transform: scale(1.05);
}

.dot-style-2 .owl-dots {
  text-align: center;
  margin-top: 26px;
}

@media only screen and (max-width: 767px) {
  .dot-style-2 .owl-dots {
    margin-top: 15px;
  }
}

.dot-style-2 .owl-dots .owl-dot {
  background-color: #E7E7E7;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 5px;
  transition: all .3s ease 0s;
}

.dot-style-2 .owl-dots .owl-dot.active, .dot-style-2 .owl-dots .owl-dot:hover {
  background-color: #fc4f4f;
}

.dot-style-2.dot-style-2-inc .owl-dots .owl-dot {
  background-color: rgba(0, 0, 0, 0.37);
  width: 18px;
  height: 18px;
  margin: 0 8px;
}

.dot-style-2.dot-style-2-inc .owl-dots .owl-dot.active, .dot-style-2.dot-style-2-inc .owl-dots .owl-dot:hover {
  background-color: #fc4f4f;
}

.dot-style-2.dot-style-2-black .owl-dots .owl-dot {
  background-color: rgba(0, 0, 0, 0.34);
}

.dot-style-2.dot-style-2-black .owl-dots .owl-dot.active, .dot-style-2.dot-style-2-black .owl-dots .owl-dot:hover {
  background-color: #262626;
}

.single-categories-2 .categories-img {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  overflow: hidden;
}

.single-categories-2 .categories-img a {
  display: block;
}

.single-categories-2 .categories-img a img {
  width: 100%;
  transition: all .35s ease 0s;
}

.single-categories-2 span {
  color: #282828;
  display: block;
  line-height: 1;
  margin: 23px 0 0;
}

.single-categories-2:hover a img {
  transform: scale(1.2);
}

.single-categories-2:hover span {
  color: #fc4f4f;
}

/*-------- 11. Service style ---------*/
.service-wrap .service-icon {
  margin: 0 0 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap .service-icon {
    margin: 0 0 10px;
  }
}

.service-wrap .service-icon i {
  font-size: 50px;
}

.service-wrap .service-content h4 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap .service-content h4 {
    letter-spacing: 1px;
  }
}

.service-wrap .service-content p {
  font-size: 12px;
  color: #777;
  margin: 0 auto;
  line-height: 1.714286;
  width: 68%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-wrap .service-content p {
    width: 98%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap .service-content p {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap .service-content p {
    width: 90%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service-wrap .service-content p {
    width: 77%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-wrap .service-content.service-content-14 h4 {
    font-size: 15px;
    letter-spacing: 1px;
  }
}

.service-wrap .service-content.service-content-14 p {
  width: 86%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-wrap .service-content.service-content-14 p {
    width: 98%;
  }
}

.service-wrap-2 {
  display: flex;
  align-items: center;
}

.service-wrap-2 .service-icon-2 {
  margin-right: 18px;
}

.service-wrap-2 .service-icon-2 i {
  display: inline-block;
  font-size: 30px;
  color: #262626;
  width: 62px;
  height: 62px;
  line-height: 62px;
  border-radius: 100%;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.service-wrap-2 .service-content-2 h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 8px;
}

.service-wrap-2 .service-content-2 p {
  font-size: 12px;
  color: #535353;
}

.service-wrap-3 .service-icon-3 {
  margin: 0 0 25px;
}

.service-wrap-3 .service-icon-3 i {
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 30px;
  width: 76px;
  height: 76px;
  line-height: 76px;
  border-radius: 76px;
  text-align: center;
}

.service-wrap-3 .service-content-3 h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 17px;
}

.service-wrap-3 .service-content-3 p {
  width: 72%;
  margin: 0 auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-wrap-3 .service-content-3 p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap-3 .service-content-3 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap-3 .service-content-3 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-3 .service-content-3 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-area.pb-100 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .service-area.pb-100 {
    padding-bottom: 20px;
  }
}

/*-------- 12. Instagram style ---------*/
.instagram-wrap-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: -15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-wrap-2 {
    justify-content: flex-start;
    margin-right: 0px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-2 {
    justify-content: flex-start;
    margin-right: 0px;
    margin-left: -5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-wrap-2 {
    margin-left: -10px;
  }
}

.instagram-wrap-2 .single-instafeed {
  flex: 0 0 25.333%;
  margin: 0 15px 30px;
  list-style: none;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .instagram-wrap-2 .single-instafeed {
    margin: 0 10px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-wrap-2 .single-instafeed {
    margin: 0 10px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-wrap-2 .single-instafeed {
    margin: 0 5px 10px;
    flex: 0 0 27.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-wrap-2 .single-instafeed {
    flex: 0 0 19.333%;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-2 .single-instafeed {
    margin: 0 5px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-wrap-2 .single-instafeed {
    margin: 0 10px 20px;
  }
}

.instagram-wrap-2 .single-instafeed a img {
  width: 100%;
  filter: grayscale(100%);
}

.instagram-wrap-2 .single-instafeed:hover a img {
  filter: grayscale(0%);
}

.instagram-wrap {
  display: flex;
  flex-wrap: wrap;
}

.instagram-wrap .single-instafeed {
  position: relative;
  flex: 0 0 20%;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap .single-instafeed {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-wrap .single-instafeed {
    flex: 0 0 33.333%;
  }
}

.instagram-wrap .single-instafeed > a {
  display: block;
  position: relative;
}

.instagram-wrap .single-instafeed > a::before {
  background-color: rgba(84, 89, 95, 0.6);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 9;
}

.instagram-wrap .single-instafeed > a img {
  width: 100%;
}

.instagram-wrap .single-instafeed > .instagram-hvr-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  z-index: 99;
}

.instagram-wrap .single-instafeed > .instagram-hvr-content span {
  font-size: 20px;
  margin: 0 5px;
  color: #fff;
}

.instagram-wrap .single-instafeed > .instagram-hvr-content span i {
  color: #6ec1e4;
  margin-right: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-wrap .single-instafeed > .instagram-hvr-content span {
    font-size: 18px;
  }
}

.instagram-wrap .single-instafeed:hover > a::before {
  opacity: 1;
  visibility: visible;
}

.instagram-wrap .single-instafeed:hover > .instagram-hvr-content {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap.black-instagram .instagram-item {
    margin-bottom: 15px;
  }
}

.instagram-wrap.black-instagram .instagram-item > a::before {
  background-color: rgba(255, 255, 255, 0.63);
}

.instagram-wrap.black-instagram .instagram-item > .instagram-hvr-content span {
  color: #262626;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-wrap.black-instagram .instagram-item > .instagram-hvr-content span {
    font-size: 14px;
  }
}

.instagram-wrap.black-instagram .instagram-item > .instagram-hvr-content span i {
  color: #262626;
}

.instagram-wrap.instagram-wrap-full-white .instagram-item > a::before {
  background-color: rgba(0, 0, 0, 0.49);
}

.instagram-wrap.instagram-wrap-full-white .instagram-item > .instagram-hvr-content span {
  color: #fff;
}

.instagram-wrap.instagram-wrap-full-white .instagram-item > .instagram-hvr-content span i {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap.mb-70 {
    margin-bottom: 20px;
  }
}

.custom-instagram-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-left: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-instagram-row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-instagram-row {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-instagram-row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-instagram-row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-instagram-row {
    margin-right: -7.5px;
    margin-left: -7.5px;
  }
}

.custom-instagram-col {
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 30px;
  padding-left: 30px;
  position: relative;
  min-height: 1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-instagram-col {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-instagram-col {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-instagram-col {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-instagram-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-instagram-col {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 7.5px;
    padding-left: 7.5px;
  }
}

.instagram-wrap-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: -15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-wrap-3 {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-wrap-3 {
    justify-content: flex-start;
    margin-right: 0px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-3 {
    justify-content: flex-start;
    margin-right: 0px;
    margin-left: -5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-wrap-3 {
    margin-left: -10px;
  }
}

.instagram-wrap-3 .single-instafeed {
  flex: 0 0 25.333%;
  margin: 0 15px 30px;
  list-style: none;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .instagram-wrap-3 .single-instafeed {
    margin: 0 10px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-wrap-3 .single-instafeed {
    margin: 0 10px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-wrap-3 .single-instafeed {
    margin: 0 5px 10px;
    flex: 0 0 27.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-wrap-3 .single-instafeed {
    flex: 0 0 19.333%;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-3 .single-instafeed {
    margin: 0 5px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-wrap-3 .single-instafeed {
    margin: 0 10px 20px;
  }
}

.instagram-wrap-3 .single-instafeed a {
  position: relative;
  display: block;
}

.instagram-wrap-3 .single-instafeed a::before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  content: '';
  transition: all .3s ease 0s;
}

.instagram-wrap-3 .single-instafeed a img {
  width: 100%;
}

.instagram-wrap-3 .single-instafeed a:hover::before {
  background-color: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 767px) {
  .instagram-area.pt-70 {
    padding-top: 20px;
  }
}

/*-------- 13. Animation style ---------*/
@keyframes lastudioFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lastudioZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes lastudioZoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes lastudioMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveUpBig {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveDown {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveDownBig {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveLeft {
  0% {
    opacity: 0;
    transform: translateX(25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioMoveLeftBig {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioMoveRight {
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioMoveRightBig {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioFallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes lastudioFlipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes lastudioFlipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes moveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes columnMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes edit-button-pulse {
  0% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0.6);
  }
  30% {
    box-shadow: 0 0 2px 10px rgba(183, 8, 78, 0);
  }
  100% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0);
  }
}

/*-------- 14. Banner style ---------*/
.parallax-img {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.parallax-ptb-1 {
  padding: 210px 0 196px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .parallax-ptb-1 {
    padding: 160px 0 146px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .parallax-ptb-1 {
    padding: 150px 0 136px;
  }
}

@media only screen and (max-width: 767px) {
  .parallax-ptb-1 {
    padding: 70px 0 70px;
  }
}

.banner-wrap {
  position: relative;
  overflow: hidden;
}

.banner-wrap .banner-img a {
  display: block;
}

.banner-wrap .banner-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .35s ease 0s;
}

.banner-wrap .banner-position-1 {
  position: absolute;
  right: 145px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-1 {
    right: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-1 {
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-1 {
    right: 0px;
    left: 0;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-1 {
    right: 50px;
    left: inherit;
  }
}

.banner-wrap .banner-position-2 {
  position: absolute;
  right: 0px;
  left: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

.banner-wrap .banner-position-3 {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-3 {
    left: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-3 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-3 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-3 {
    left: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-3 {
    left: 30px;
  }
}

.banner-wrap .banner-position-4 {
  position: absolute;
  right: 80px;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-4 {
    right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-4 {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-4 {
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-4 {
    right: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-4 {
    right: 30px;
  }
}

.banner-wrap .banner-position-5 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
}

.banner-wrap .banner-position-6 {
  position: absolute;
  left: 80px;
  bottom: 40px;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-position-6 {
    left: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-6 {
    left: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-6 {
    left: 30px;
    bottom: 30px;
    padding: 0 100px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-6 {
    left: 15px;
    bottom: 15px;
    padding: 0 10px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-6 {
    left: 30px;
    bottom: 30px;
  }
}

.banner-wrap .banner-position-7 {
  position: absolute;
  left: 0px;
  right: 0;
  text-align: center;
  bottom: 40px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-7 {
    padding: 0 60px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-7 {
    padding: 0 10px 0 10px;
    bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-7 {
    bottom: 30px;
  }
}

.banner-wrap .banner-position-8 {
  position: absolute;
  top: 115px;
  left: 0;
  right: 0;
  z-index: 99;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-position-8 {
    top: 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-8 {
    top: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-8 {
    top: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-8 {
    top: 45px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-8 {
    top: 115px;
  }
}

.banner-wrap .banner-position-9 {
  position: absolute;
  top: 152px;
  right: 105px;
  z-index: 9;
  text-align: right;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-position-9 {
    top: 100px;
    right: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-9 {
    top: 100px;
    right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-9 {
    top: 70px;
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-9 {
    top: 70px;
    right: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-9 {
    top: 152px;
    right: 105px;
  }
}

.banner-wrap .banner-position-10 {
  position: absolute;
  right: 130px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-10 {
    right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-10 {
    right: 50px;
  }
}

.banner-wrap .banner-position-11 {
  position: absolute;
  right: 0px;
  left: 0px;
  text-align: center;
  top: 115px;
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-11 {
    top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-11 {
    top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-11 {
    top: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-11 {
    top: 100px;
  }
}

.banner-wrap .banner-position-12 {
  position: absolute;
  left: 80px;
  bottom: 115px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-12 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-12 {
    left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-12 {
    left: 15px;
    bottom: 30px;
  }
}

.banner-wrap .banner-position-13 {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-13 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-13 {
    left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-13 {
    left: 0px;
    right: 0;
    text-align: center;
  }
}

.banner-wrap .banner-position-14 {
  position: absolute;
  left: 0px;
  right: 0;
  top: 115px;
  z-index: 99;
}

.banner-wrap .banner-content-1 span {
  color: #fff;
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 span {
    font-size: 22px;
  }
}

.banner-wrap .banner-content-1 h2 {
  color: #fff;
  font-size: 120px;
  line-height: 95px;
  margin: 12px 0 33px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 95px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 85px;
    line-height: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 50px;
  }
}

.banner-wrap .banner-content-2 {
  text-align: center;
}

.banner-wrap .banner-content-2 span {
  font-size: 18px;
}

.banner-wrap .banner-content-2 h3 {
  font-size: 34px;
  margin: 10px 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 27px;
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 22px;
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 19px;
    margin: 10px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 25px;
  }
}

.banner-wrap .banner-content-3 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 9;
}

.banner-wrap .banner-content-3 h3 {
  font-weight: 300;
  font-size: 24px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-3 h3 a {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  width: 280px;
  text-align: center;
  padding: 23px 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 h3 a {
    width: 220px;
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 a {
    width: 220px;
    padding: 10px 10px;
  }
}

.banner-wrap .banner-content-4 {
  top: 100%;
  position: absolute;
  left: 80px;
  z-index: 9;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-4 {
    left: 15px;
  }
}

.banner-wrap .banner-content-4 h4 {
  font-size: 18px;
  margin: 0;
  color: #fff;
}

.banner-wrap .banner-content-4 h4 a {
  color: #fff;
}

.banner-wrap .banner-content-5 h3 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
  color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 18px;
  }
}

.banner-wrap .banner-content-5 h2 {
  font-size: 80px;
  margin: 11px 0 13px;
  color: #fff;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 50px;
    margin: 8px 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 40px;
    margin: 8px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 40px;
    margin: 8px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 30px;
    margin: 8px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 40px;
    margin: 8px 0 10px;
  }
}

.banner-wrap .banner-content-5 .banner-price span {
  font-size: 24px;
  color: #fff;
  font-weight: 300;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 .banner-price span {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-5 .banner-price span {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 .banner-price span {
    font-size: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-5 .banner-price span {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-5 .banner-price span.old-price {
  opacity: .4;
  margin-right: 5px;
}

.banner-wrap .banner-content-5.banner-content-black h3 {
  color: #262626;
}

.banner-wrap .banner-content-5.banner-content-black h2 {
  color: #262626;
}

.banner-wrap .banner-content-5.banner-content-black .banner-price span {
  color: #000;
}

.banner-wrap .banner-content-5.banner-content-black .banner-price span.old-price {
  opacity: .6;
  margin-right: 5px;
}

.banner-wrap .banner-content-5.banner-content-black .banner-price span.new-price {
  color: #fc4f4f;
}

.banner-wrap .banner-content-6 {
  position: absolute;
  bottom: 165px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 {
    bottom: 130px;
  }
}

.banner-wrap .banner-content-6 h3 {
  font-size: 18px;
  letter-spacing: 0px;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  line-height: 1;
  transition: all .3s ease 0s;
}

.banner-wrap .banner-content-6 h2 {
  font-size: 36px;
  color: #fff;
  margin: 38px 0 45px;
  text-transform: uppercase;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 30px;
    margin: 30px 0 37px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 30px;
    margin: 30px 0 37px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 25px;
    margin: 25px 0 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 23px;
    margin: 20px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 20px;
    margin: 10px 0 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 30px;
    margin: 15px 0 25px;
  }
}

.banner-wrap .banner-content-6 h2 a {
  color: #fff;
}

.banner-wrap .banner-content-7 a img {
  transition: all .3s ease 0s;
  transform: scale(1);
  width: 587px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-7 a img {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-7 a img {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-7 a img {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-7 a img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7 a img {
    width: 200px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-7 a img {
    width: 250px;
  }
}

.banner-wrap .banner-content-8 h3 {
  color: #fff;
  font-size: 36px;
  font-family: "Alegreya", serif;
  margin: 0 0 26px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-8 h3 {
    font-size: 30px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 h3 {
    font-size: 20px;
    margin: 0 0 7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-8 h3 {
    font-size: 30px;
    margin: 0 0 12px;
  }
}

.banner-wrap .banner-content-8 p {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.714286;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-8 p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 p {
    font-size: 13px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-8 p {
    font-size: 15px;
    line-height: 23px;
  }
}

.banner-wrap .banner-content-9 h3 {
  font-size: 18px;
  margin: 0 0 10px;
  line-height: 1;
  transform: translateY(0px);
  transition: transform 0.35s;
}

.banner-wrap .banner-content-9 h2 {
  font-size: 36px;
  margin: 0 0 0px;
  line-height: 1;
  transform: translateY(0px);
  transition: transform 0.45s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 h2 {
    font-size: 25px;
  }
}

.banner-wrap .banner-content-9 span.banner-badge {
  position: absolute;
  bottom: -75px;
  background: #FC4F4F;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  font-size: 10px;
  padding: 18px 0px;
  color: #fff;
  font-weight: 400;
  right: 21%;
  line-height: 1.4;
  transform: translateY(0px);
  transition: transform 0.45s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 span.banner-badge {
    right: 52%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 span.banner-badge {
    right: 52%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-9 span.banner-badge {
    right: 21%;
  }
}

.banner-wrap .banner-content-9 span.banner-badge strong {
  display: block;
  font-size: 16px;
  font-weight: 400;
}

.banner-wrap .banner-content-9 span.banner-badge-2 {
  position: absolute;
  bottom: -6px;
  background: #FC4F4F;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  font-size: 10px;
  padding: 18px 0px;
  color: #fff;
  font-weight: 400;
  left: -105%;
  line-height: 1.4;
  text-align: center;
  transform: translateY(0px);
  transition: transform 0.45s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-9 span.banner-badge-2 {
    left: -70%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-9 span.banner-badge-2 {
    left: -70%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 span.banner-badge-2 {
    left: -55%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 span.banner-badge-2 {
    left: -55%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-9 span.banner-badge-2 {
    left: -105%;
  }
}

.banner-wrap .banner-content-9 span.banner-badge-2 strong {
  display: block;
  font-size: 16px;
  font-weight: 400;
}

.banner-wrap .banner-content-10 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 49%;
  transform: translateY(-100%);
  width: 100%;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

.banner-wrap .banner-content-10 h3 {
  font-size: 32px;
  font-weight: 300;
  color: #fff;
  margin: 0;
  line-height: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 25px;
  }
}

.banner-wrap .banner-content-10 h3 a {
  color: #fff;
}

.banner-wrap .banner-content-10.banner-content-10-dec h3 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1;
  margin: 0;
  line-height: 20px;
  font-weight: 400;
}

.banner-wrap .banner-content-11 {
  bottom: 65px;
  top: auto;
  position: absolute;
  right: 0px;
  left: 0px;
  padding: 25px 0 28px;
  transition: transform 0.35s;
  background-color: #A8D1DD;
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  z-index: 9;
}

.banner-wrap .banner-content-11 h3 {
  margin: 0;
  font-size: 16px;
}

.banner-wrap .banner-content-11 h3 a {
  color: #ffffff;
}

.banner-wrap .banner-content-12 h4 {
  margin: 0;
  font-size: 18px;
  line-height: 1.4074;
  transform: translateY(0px);
  transition: transform 0.35s, -webkit-transform 0.35s;
}

.banner-wrap .banner-content-13 span {
  font-size: 18px;
  line-height: 1.4074;
}

.banner-wrap .banner-content-13 h2 {
  font-size: 62px;
  margin: 20px 0 32px;
  line-height: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-13 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-13 h2 {
    font-size: 32px;
    line-height: 30px;
    margin: 10px 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-13 h2 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-13 h2 {
    font-size: 32px;
    line-height: 28px;
  }
}

.banner-wrap .banner-content-14 {
  position: absolute;
  left: 80px;
  z-index: 99;
  top: 100%;
  transition: opacity 0.35s, transform 0.35s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-14 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-14 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-14 {
    left: 20px;
  }
}

.banner-wrap .banner-content-14 span {
  font-size: 16px;
  display: block;
}

.banner-wrap .banner-content-14 h3 {
  font-size: 32px;
  margin: 7px 0 0;
}

.banner-wrap .banner-content-14.b-content-14-white span {
  color: #fff;
}

.banner-wrap .banner-content-14.b-content-14-white h3 {
  color: #fff;
}

.banner-wrap .banner-content-15 {
  position: absolute;
  left: 80px;
  bottom: 90px;
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-15 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-15 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-15 {
    left: 20px;
    bottom: 30px;
  }
}

.banner-wrap .banner-content-15 span {
  font-size: 16px;
  display: block;
}

.banner-wrap .banner-content-15 h3 {
  font-size: 32px;
  margin: 7px 0 0;
}

.banner-wrap .banner-content-15.b-content-15-white span {
  color: #fff;
}

.banner-wrap .banner-content-15.b-content-15-white h3 {
  color: #fff;
}

.banner-wrap .banner-content-16 h4 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  line-height: 1.8em;
  margin: 0 0 24px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-16 h4 {
    font-size: 16px;
    margin: 0 0 12px;
  }
}

.banner-wrap .banner-content-17 h4 {
  color: #fff;
  font-size: 20px;
  margin: 0 0 14px;
  line-height: 1;
  transform: translateY(0px);
  display: block;
  transition: all .35s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-17 h4 {
    font-size: 18px;
  }
}

.banner-wrap .banner-content-17 span {
  display: block;
  color: #fff;
  transform: translateY(0px);
  display: block;
  transition: all .25s ease 0s;
}

.banner-wrap .banner-content-18 h4 {
  color: #fff;
  font-size: 20px;
  margin: 0 0 11px;
  line-height: 1.4074;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-18 h4 {
    font-size: 15px;
    margin: 0 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-18 h4 {
    font-size: 18px;
  }
}

.banner-wrap .banner-content-18 span {
  display: block;
  color: #fff;
}

.banner-wrap .banner-content-19 span {
  font-size: 18px;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-19 span {
    font-size: 14px;
  }
}

.banner-wrap .banner-content-19 h3 {
  font-size: 46px;
  color: #ffffff;
  margin: 13px 0 28px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-19 h3 {
    margin: 7px 0 15px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-19 h3 {
    font-size: 35px;
    margin: 6px 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-19 h3 {
    font-size: 35px;
    margin: 6px 0 12px;
  }
}

.banner-wrap .banner-content-19 .btn-style-3 a {
  display: inline-block;
  background-color: #fc4f4f;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
  padding: 18px 35px 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-19 .btn-style-3 a {
    padding: 10px 20px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-19 .btn-style-3 a {
    padding: 10px 20px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-19.banner-position-13 {
    text-align: left !important;
    left: 15px;
  }
}

.banner-wrap .btn-style-2-position {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9;
}

.banner-wrap:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.banner-wrap:hover.default-overlay-2::before {
  background-color: #000;
  opacity: .2;
  z-index: 1;
  pointer-events: none;
}

.banner-wrap.banner-zoom:hover a img {
  transform: scale(1.05);
}

.banner-wrap:hover .banner-content-3 h3 a {
  letter-spacing: 1px;
}

.banner-wrap:hover .banner-content-4 {
  top: 85%;
  transform: translateY(-85%);
}

.banner-wrap:hover .banner-content-6 h3, .banner-wrap:hover .banner-content-8 h3 {
  letter-spacing: 1px;
}

.banner-wrap:hover .banner-content-5 .btn-style-2 a {
  background-color: #fc4f4f;
  border: 2px solid #fc4f4f;
}

.banner-wrap:hover .banner-content-7 img {
  transform: scale(1.05);
}

.banner-wrap:hover .banner-content-9 h3 {
  transform: translateY(-30px);
}

.banner-wrap:hover .banner-content-9 h2 {
  transform: translateY(-30px);
}

.banner-wrap:hover .banner-content-9 .span.banner-badge, .banner-wrap:hover .banner-content-9 span.banner-badge-2 {
  transform: translateY(-30px);
}

.banner-wrap:hover .banner-content-10 {
  top: 50%;
  transform: translateY(-50%);
  visibility: visible;
  opacity: 1;
}

.banner-wrap:hover .banner-content-11 {
  transform: translate3d(0, 91%, 0);
  opacity: 1;
}

.banner-wrap:hover .btn-style-2 a {
  background-color: #fc4f4f;
  border: 2px solid #fc4f4f;
}

.banner-wrap:hover .btn-style-2 a.btn-2-text-black {
  color: #fff;
}

.banner-wrap:hover .btn-style-2 a.btn-hover-19 {
  background-color: #aa4039;
  border: 2px solid #aa4039;
}

.banner-wrap:hover .banner-content-12 h4 {
  transform: translateY(-38px);
}

.banner-wrap:hover .banner-content-14 {
  top: 84%;
  transform: translateY(-84%);
}

.banner-wrap:hover .banner-content-17 h4 {
  transform: translateY(-30px);
}

.banner-wrap:hover .banner-content-17 span {
  transform: translateY(-30px);
}

.banner-wrap:hover .banner-content-19 .btn-style-3 a {
  background-color: #aa4039;
}

@media only screen and (max-width: 767px) {
  .banner-wrap.mb-40 {
    margin-bottom: 30px;
  }
}

.banner-slider-icon {
  position: absolute;
  transform: translateY(-50%);
  transition: all 250ms ease-out;
  background-color: rgba(0, 0, 0, 0.28);
  font-size: 50px;
  width: 110px;
  height: 110px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-slider-icon {
    font-size: 20px;
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-slider-icon {
    font-size: 20px;
    width: 60px;
    height: 60px;
  }
}

.banner-slider-icon:hover {
  background-color: #262626;
}

.banner-slider-icon i {
  line-height: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-slider-icon i {
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-slider-icon i {
    line-height: 60px;
  }
}

.banner-slider-icon.slider-next {
  right: calc(50% - 110px) !important;
  bottom: calc(0% - 55px) !important;
  top: auto;
  left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-slider-icon.slider-next {
    right: calc(50% - 60px) !important;
    bottom: calc(0% - 30px) !important;
  }
}

@media only screen and (max-width: 767px) {
  .banner-slider-icon.slider-next {
    right: calc(50% - 60px) !important;
    bottom: calc(0% - 30px) !important;
  }
}

.banner-slider-icon.slider-prev {
  left: calc(50% - 110px) !important;
  bottom: calc(0% - 55px) !important;
  top: auto;
  right: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-slider-icon.slider-prev {
    left: calc(50% - 60px) !important;
    bottom: calc(0% - 30px) !important;
  }
}

@media only screen and (max-width: 767px) {
  .banner-slider-icon.slider-prev {
    left: calc(50% - 60px) !important;
    bottom: calc(0% - 30px) !important;
  }
}

.banner-bg-content h2 {
  color: #fff;
  font-size: 64px;
  margin: 0;
  line-height: 90px;
}

.banner-bg-content h2.pr-1 {
  padding-right: 140px !important;
}

@media only screen and (max-width: 767px) {
  .banner-bg-content h2.pr-1 {
    padding-right: 50px !important;
  }
}

.banner-bg-content h2.pl-1 {
  padding-left: 140px !important;
}

@media only screen and (max-width: 767px) {
  .banner-bg-content h2.pl-1 {
    padding-left: 50px !important;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-bg-content h2 {
    font-size: 55px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-bg-content h2 {
    font-size: 55px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-bg-content h2 {
    font-size: 45px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-content h2 {
    font-size: 35px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-content h2 {
    font-size: 17px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-bg-content h2 {
    font-size: 25px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-content.pb-70 {
    padding-bottom: 70px;
  }
}

.single-banner-slider-wrap {
  margin-left: 260px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner-slider-wrap {
    margin-left: 98px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner-slider-wrap {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.single-banner-slider-wrap .single-banner-slider-content {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 60px 70px 100px;
  max-width: 540px;
}

@media only screen and (max-width: 767px) {
  .single-banner-slider-wrap .single-banner-slider-content {
    max-width: 100%;
    padding: 30px 15px 40px;
  }
}

.single-banner-slider-wrap .single-banner-slider-content span {
  color: #a8d1dd;
  font-size: 16px;
}

.single-banner-slider-wrap .single-banner-slider-content h3 {
  color: #fff;
  font-size: 36px;
  margin: 15px 0 15px;
}

@media only screen and (max-width: 767px) {
  .single-banner-slider-wrap .single-banner-slider-content h3 {
    font-size: 25px;
    margin: 10px 0 10px;
  }
}

.single-banner-slider-wrap .single-banner-slider-content p {
  color: #fff;
  margin: 0 0 39px;
  line-height: 1.714286;
}

@media only screen and (max-width: 767px) {
  .single-banner-slider-wrap .single-banner-slider-content p {
    margin: 0 0 20px;
  }
}

.single-banner-slider-wrap .single-banner-slider-content .banner-slider-btn a {
  letter-spacing: 2px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  width: 43%;
  display: inline-block;
  border: 2px solid #fff;
  line-height: 1;
  padding: 14px 30px 13px;
  text-align: center;
}

.single-banner-slider-wrap .single-banner-slider-content .banner-slider-btn a:hover {
  border: 2px solid #a8d1dd;
  background-color: #a8d1dd;
}

.owl-item .single-banner-slider-wrap * {
  animation-duration: 1.3s;
  animation-fill-mode: both;
}

.owl-item.active .single-banner-slider-content.slider-animated-1 {
  animation-delay: .2s;
  animation-name: fadeInUp;
}

.banner-slider-active-3 .owl-nav {
  position: absolute;
  left: 718px;
  bottom: 223px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-slider-active-3 .owl-nav {
    left: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-slider-active-3 .owl-nav {
    left: auto;
    bottom: 94px;
    right: 50px;
  }
}

.banner-slider-active-3 .owl-nav div {
  display: inline-block;
  color: #fff;
  font-size: 39px;
  margin: 0 7px;
  transition: all .3s ease 0s;
}

.banner-slider-active-3 .owl-nav div:hover {
  color: #a8d1dd;
}

.banner-bg-ptb {
  padding: 225px 0 225px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-bg-ptb {
    padding: 125px 0 125px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-bg-ptb {
    padding: 100px 0 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-ptb {
    padding: 100px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-ptb {
    padding: 80px 0 80px;
  }
}

.banner-bg-content-2 {
  padding-left: 155px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-bg-content-2 {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-bg-content-2 {
    padding-left: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-content-2 {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-2 {
    padding-left: 20px;
  }
}

.banner-bg-content-2 h3 {
  color: #ffffff;
  font-size: 46px;
  font-weight: 300;
  margin: 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-2 h3 {
    font-size: 25px;
  }
}

.banner-bg-content-2 h2 {
  color: #ffffff;
  font-size: 64px;
  font-weight: bold;
  margin: 28px 0 50px;
  line-height: 50px;
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-2 h2 {
    font-size: 55px;
    margin: 20px 0 30px;
  }
}

.single-banner-slider-4 {
  position: relative;
}

.single-banner-slider-4 .banner-slider-4-content {
  position: absolute;
  bottom: 85px;
  left: 30px;
  z-index: 99;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-banner-slider-4 .banner-slider-4-content {
    left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-slider-4 .banner-slider-4-content {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner-slider-4 .banner-slider-4-content {
    left: 15px;
  }
}

.single-banner-slider-4 .banner-slider-4-content h3 {
  line-height: 1.4074;
  color: #fff;
  font-size: 36px;
  opacity: 0;
  transform: translateY(20px);
  transition: all .4s ease-in-out .1s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-banner-slider-4 .banner-slider-4-content h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-banner-slider-4 .banner-slider-4-content h3 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-slider-4 .banner-slider-4-content h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner-slider-4 .banner-slider-4-content h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner-slider-4 .banner-slider-4-content h3 {
    font-size: 30px;
  }
}

.single-banner-slider-4 .banner-slider-4-content h3 a {
  color: #fff;
}

.single-banner-slider-4:hover .banner-slider-4-content h3 {
  opacity: 1;
  transform: translateY(0px);
}

.single-banner-slider-4:hover.default-overlay::before {
  background-color: #000;
  opacity: .2;
  z-index: 1;
  pointer-events: none;
}

.banner-slider-active-4 .owl-nav {
  position: absolute;
  top: 50%;
  transform: rotateY(-50%);
  right: -74px;
  transform: rotate(-180deg);
}

@media only screen and (max-width: 767px) {
  .banner-slider-active-4 .owl-nav {
    right: -40px;
  }
}

.banner-slider-active-4 .owl-nav div i {
  color: rgba(17, 17, 17, 0.2);
  font-size: 40px;
  line-height: 25px;
  display: block;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .banner-slider-active-4 .owl-nav div i {
    font-size: 24px;
  }
}

.banner-slider-active-4 .owl-nav div i:hover {
  color: #111111;
}

.banner-pr-10 {
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  .banner-pr-10 {
    padding-right: 0px;
  }
}

.banner-pl-10 {
  padding-left: 10px;
}

@media only screen and (max-width: 767px) {
  .banner-pl-10 {
    padding-left: 0px;
  }
}

.banner-pr-15 {
  padding-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-pr-15.pr-15-none {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-pr-15 {
    padding-right: 0px;
  }
}

.banner-pl-15 {
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-pl-15.pl-15-none {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-pl-15 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-slider.pt-180 {
    padding-top: 100px;
  }
  .single-banner-slider.pb-180 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.pb-100 {
    padding-bottom: 70px;
  }
  .banner-area.banner-area-hm11.pb-100 {
    padding-bottom: 50px;
  }
  .single-banner-slider.pt-180 {
    padding-top: 100px;
  }
  .single-banner-slider.pb-180 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.pb-60 {
    padding-bottom: 30px;
  }
  .banner-area.pb-100 {
    padding-bottom: 20px;
  }
  .banner-bg-area.mb-100 {
    margin-bottom: 50px;
  }
  .single-banner-slider.pt-180 {
    padding-top: 50px;
  }
  .single-banner-slider.pb-180 {
    padding-bottom: 50px;
  }
  .banner-mrg-xs-none {
    margin-bottom: 0;
  }
}

/*-------- 15. Blog style ---------*/
.blog-all-mrg {
  margin: 0 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-all-mrg {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-all-mrg {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-all-mrg {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-all-mrg {
    margin: 0 0px;
  }
}

.blog-wrap .blog-img img {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-img.mb-35 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img.mb-35 {
    margin-bottom: 15px;
  }
}

.blog-wrap .blog-content h3 {
  font-weight: 600;
  font-size: 32px;
  font-family: "Alegreya", serif;
  margin: 0 0 11px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content h3 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content h3 {
    font-size: 24px;
  }
}

.blog-wrap .blog-content p {
  font-weight: 300;
  font-size: 16px;
  color: #777;
  line-height: 1.714286;
  margin: 8px 0 23px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content p {
    margin: 8px 0 10px;
  }
}

.blog-wrap .blog-content .blog-btn a {
  display: inline-block;
  font-size: 12px;
  letter-spacing: 5px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #262626;
}

.blog-wrap .blog-content .blog-btn a:hover {
  border-bottom: 1px solid #fc4f4f;
}

.blog-wrap .blog-content.blog-content-modify p {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  margin: 30px 0 23px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content.blog-content-modify p {
    margin: 15px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content.blog-content-modify p {
    margin: 10px 0 10px;
  }
}

.blog-wrap .blog-content.blog-content-modify .blog-btn a {
  color: #2f2f2f;
  border-bottom: 1px solid #262626;
}

.blog-wrap .blog-content.blog-content-modify .blog-btn a:hover {
  color: #fc4f4f;
  border-bottom: 1px solid #fc4f4f;
}

.blog-meta ul {
  display: flex;
  flex-wrap: wrap;
}

.blog-meta ul li {
  margin-right: 10px;
  color: #777777;
  font-size: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-meta ul li {
    margin-right: 4px;
  }
}

.blog-meta ul li:last-child {
  margin-right: 0px;
}

.blog-meta ul li a {
  color: #262626;
  font-size: 12px;
}

.blog-meta ul li a:hover {
  color: #fc4f4f;
}

.blog-meta.light-sky-meta ul li a {
  color: #262626;
}

.blog-meta.light-sky-meta ul li a:hover {
  color: #A8D1DD;
}

.blog-wrap-2 .blog-img-2 img {
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-2 .blog-img-2.mb-50 {
    margin-bottom: 31px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-img-2.mb-50 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-img-2.mb-50 {
    margin-bottom: 25px;
  }
}

.blog-wrap-2 .blog-content-2 {
  padding: 0 40px 55px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 0 30px 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 0 25px 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 0 20px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 0 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 0 15px 30px;
  }
}

.blog-wrap-2 .blog-content-2 h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 20px;
  font-family: "Alegreya", serif;
  text-transform: uppercase;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 20px;
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 18px;
    margin: 0 0 7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 22px;
  }
}

.blog-wrap-2 .blog-content-2 h3 a {
  color: #262626;
}

.blog-wrap-2 .blog-content-2 h3 a:hover {
  color: #A8D1DD;
}

.blog-wrap-2 .blog-content-2 p {
  font-size: 16px;
  line-height: 1.714286;
  color: rgba(83, 83, 83, 0.78);
  margin: 19px 0 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-2 .blog-content-2 p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-content-2 p {
    margin: 8px 0 20px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 p {
    margin: 6px 0 15px;
    font-size: 14px;
  }
}

.blog-btn-2 a {
  display: inline-block;
  color: #2f2f2f;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 800;
  line-height: 1;
  padding: 16px 50px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-btn-2.blog-btn-2-sky a:hover {
  background-color: #A8D1DD;
  color: #fff;
  border: 1px solid #A8D1DD;
}

.blog-btn-2.blog-btn-2-red a {
  padding: 19px 40px 18px;
}

@media only screen and (max-width: 767px) {
  .blog-btn-2.blog-btn-2-red a {
    padding: 19px 30px 18px;
  }
}

.blog-btn-2.blog-btn-2-red a:hover {
  background-color: #fc4f4f;
  color: #fff;
  border: 1px solid #fc4f4f;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-btn-2.blog-btn-2-red.mt-35 {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-btn-2.blog-btn-2-red.mt-35 {
    margin-top: 15px;
  }
}

.blog-wrap-3 .blog-img-3 {
  position: relative;
}

.blog-wrap-3 .blog-img-3 > a {
  display: block;
  position: relative;
}

.blog-wrap-3 .blog-img-3 > a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  transition: all .3s ease 0s;
}

.blog-wrap-3 .blog-img-3 > a img {
  width: 100%;
}

.blog-wrap-3 .blog-img-3 img {
  width: 100%;
}

.blog-wrap-3 .blog-img-3 .blog-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 999;
}

.blog-wrap-3 .blog-img-3 .blog-video a {
  background-color: #fff;
  width: 68px;
  height: 68px;
  line-height: 68px;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  display: inline-block;
}

.blog-wrap-3 .blog-img-3 .blog-video a i {
  line-height: 68px;
}

.blog-wrap-3 .blog-img-3 .blog-music-icon {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
}

.blog-wrap-3 .blog-img-3 .blog-music-icon a {
  color: #2f2f2f;
}

.blog-wrap-3 .blog-img-3 .blog-music-icon a i {
  width: 66px;
  height: 66px;
  text-align: center;
  line-height: 66px;
  background-color: #fff;
  font-size: 20px;
  color: #2f2f2f;
}

.blog-wrap-3 > .quote-content {
  background-color: #f4f4f4;
  background-repeat: no-repeat;
  background-position: top right;
  padding: 6% 8%;
  position: relative;
  z-index: 9;
  background-image: url(../images/icon-img/quote.png);
}

.blog-wrap-3 > .quote-content p {
  font-size: 18px;
  color: #2F2F2F;
  line-height: 1.714286;
  margin: 0 0 18px;
  position: relative;
  z-index: 99;
}

.blog-wrap-3 > .quote-content p a {
  color: #2F2F2F;
}

.blog-wrap-3 > .quote-content span {
  font-size: 14px;
  color: #2F2F2F;
  position: relative;
  z-index: 99;
}

.blog-wrap-3 > .quote-content::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  transition: all .3s ease 0s;
}

.blog-wrap-3 .blog-content-3 .blog-category a {
  font-size: 11px;
  color: #fc4f4f;
}

.blog-wrap-3 .blog-content-3 .blog-category a:hover {
  text-decoration: underline;
}

.blog-wrap-3 .blog-content-3 h3 {
  font-size: 32px;
  font-weight: 700;
  font-family: "Alegreya", serif;
  margin: 6px 0 10px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-3 .blog-content-3 h3 {
    font-size: 25px;
  }
}

.blog-wrap-3 .blog-content-3 p {
  font-size: 16px;
  line-height: 1.714286;
  margin: 28px 0 33px;
  color: #535353;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-3 .blog-content-3 p {
    font-size: 14px;
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-3 .blog-content-3.ml-150 {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-3 .blog-content-3.ml-150 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-3.mb-70 {
    margin-bottom: 40px;
  }
}

.blog-wrap-3:hover .blog-btn-2-red a {
  background-color: #fc4f4f;
  color: #fff;
  border: 1px solid #fc4f4f;
}

.blog-wrap-3:hover .blog-img-3 a:before {
  opacity: .2;
  visibility: visible;
}

.blog-wrap-3:hover .quote-content:before {
  opacity: .2;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-3.blog-wrap-3-res-xs.mb-50 {
    margin-bottom: 10px;
  }
}

.sidebar-search form {
  position: relative;
}

.sidebar-search form input {
  background-color: transparent;
  color: #989898;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 2px 40px 2px 20px;
}

.sidebar-search form .button-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: #262626;
  background-color: transparent;
  border: none;
  padding: 5px 20px;
  transition: all .3s ease 0s;
}

.sidebar-search form .button-search:hover {
  color: #fc4f4f;
}

.sidebar-post-wrap .single-sidebar-post {
  display: flex;
  border-bottom: 1px solid #DCDCDC;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.sidebar-post-wrap .single-sidebar-post:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-img {
  flex: 0 0 80px;
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-img a img {
  width: 100%;
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content {
  margin-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-post-wrap .single-sidebar-post .sidebar-post-content {
    margin-left: 12px;
  }
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 {
  font-weight: 600;
  font-size: 18px;
  margin: -5px 0 4px;
  line-height: 1.2;
  font-family: "Alegreya", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 {
    font-size: 16px;
  }
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content span {
  font-size: 11px;
  color: #535353;
}

@media only screen and (max-width: 767px) {
  .sidebar-post-wrap .single-sidebar-post.mb-40 {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-post-wrap.mt-45 {
    margin-top: 30px;
  }
}

.archives-wrap select {
  -moz-appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #262626;
  font-size: 14px;
  height: 50px;
  padding: 2px 20px;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 18px center;
}

@media only screen and (max-width: 767px) {
  .archives-wrap.mt-40 {
    margin-top: 20px;
  }
}

.banner-sidebar-banner {
  position: relative;
  overflow: hidden;
}

.banner-sidebar-banner a img {
  width: 100%;
  transform: scale(1);
  transition: all .3s ease 0s;
}

.banner-sidebar-banner .banner-sidebar-content {
  position: absolute;
  left: 35px;
  bottom: 45px;
}

.banner-sidebar-banner .banner-sidebar-content h3 {
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 5px;
  color: #fff;
  font-family: "Alegreya", serif;
}

.banner-sidebar-banner .banner-sidebar-content h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0px;
  color: #fff;
  font-family: "Alegreya", serif;
}

.banner-sidebar-banner:hover a > img {
  transform: scale(1.2);
}

.blog-details-content > h3 {
  font-size: 42px;
  font-weight: bold;
  margin: 0 0 12px;
  font-family: "Alegreya", serif;
}

@media only screen and (max-width: 767px) {
  .blog-details-content > h3 {
    font-size: 25px;
  }
}

.blog-details-content .blog-details-meta ul li {
  display: inline-block;
  color: #535353;
}

.blog-details-content .blog-details-meta ul li a {
  color: #535353;
}

.blog-details-content .blog-details-img {
  margin: 39px 0 35px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-img {
    margin: 20px 0 20px;
  }
}

.blog-details-content .blog-details-img img {
  width: 100%;
}

.blog-details-content .blog-details-paragraph p {
  font-size: 16px;
  line-height: 1.88;
  color: #535353;
  margin: 0 0 22px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-paragraph p {
    font-size: 15px;
    margin: 0 0 15px;
  }
}

.blog-details-content .blog-details-paragraph .blog-details-middle-img {
  margin: 50px 0 50px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-paragraph .blog-details-middle-img {
    margin: 30px 0 30px;
  }
}

.blog-details-content .blog-details-paragraph .blog-details-middle-img img {
  width: 100%;
}

.blog-details-content .blog-details-paragraph > .quote-content-2 {
  background-color: #f4f4f4;
  background-repeat: no-repeat;
  background-position: top right;
  padding: 6% 8%;
  position: relative;
  z-index: 99;
  margin: 50px 0;
  background-image: url(../images/icon-img/quote.png);
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-paragraph > .quote-content-2 {
    margin: 30px 0;
  }
}

.blog-details-content .blog-details-paragraph > .quote-content-2 p {
  font-size: 18px;
  color: #2F2F2F;
  line-height: 1.714286;
  margin: 0 0 18px;
}

.blog-details-content .blog-details-paragraph > .quote-content-2 span {
  font-size: 14px;
  color: #2F2F2F;
}

.blog-details-content .blog-details-paragraph h3.blog-dec-middle-title {
  font-size: 36px;
  font-weight: bold;
  margin: 0 0 33px;
  font-family: "Alegreya", serif;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-paragraph h3.blog-dec-middle-title {
    font-size: 25px;
    margin: 0 0 20px;
  }
}

.blog-details-content .blog-details-tag-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 46px 0 48px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-tag-social {
    margin: 25px 0 25px;
  }
}

.blog-details-content .blog-details-tag-social .blog-details-tag ul {
  display: flex;
}

.blog-details-content .blog-details-tag-social .blog-details-tag ul li {
  font-size: 13px;
  color: #535353;
  margin-right: 3px;
}

.blog-details-content .blog-details-tag-social .blog-details-tag ul li a {
  color: #262626;
  font-size: 13px;
}

.blog-details-content .blog-details-tag-social .blog-details-tag ul li a:hover {
  color: #fc4f4f;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-tag-social .blog-details-social {
    margin-top: 10px;
  }
}

.blog-details-content .blog-details-tag-social .blog-details-social ul {
  display: flex;
  align-items: center;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li {
  margin-left: 8px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-tag-social .blog-details-social ul li {
    margin-left: 4px;
  }
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li:first-child {
  margin-left: 0;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  border-radius: 100%;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a.facebook {
  background-color: #5678bf;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a.facebook:hover {
  background-color: #3b5999;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a.twitter {
  background-color: #73c2fd;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a.twitter:hover {
  background-color: #55acee;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a.linkedin {
  background-color: #007bb6;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a.linkedin:hover {
  background-color: #0173a9;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a.pinterest {
  background-color: #c32026;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a.pinterest:hover {
  background-color: #b90f16;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a.google {
  background-color: #dd4b39;
}

.blog-details-content .blog-details-tag-social .blog-details-social ul li a.google:hover {
  background-color: #dd4b39;
}

.blog-details-content .next-prev-wrap {
  display: flex;
  justify-content: space-between;
}

.blog-details-content .next-prev-wrap .next-prev-conent > h4 {
  font-size: 18px;
  font-weight: 700;
  font-family: "Alegreya", serif;
  margin: 0 0 11px;
}

.blog-details-content .next-prev-wrap .next-prev-conent > h4 a {
  font-family: "Alegreya", serif;
}

.blog-details-content .next-prev-wrap .next-prev-conent > a {
  color: #989898;
  font-size: 12px;
}

.blog-details-content .next-prev-wrap .next-prev-conent > a:hover {
  color: #fc4f4f;
}

.blog-details-content .next-prev-wrap .next-prev-conent.prev-wrap {
  text-align: right;
}

.blog-details-content .blog-comments-area {
  margin: 31px 0 45px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-comments-area {
    margin: 30px 0 30px;
  }
}

.blog-details-content .blog-comments-area h4 {
  font-size: 36px;
  font-family: "Alegreya", serif;
  margin: 0 0 20px;
}

.blog-details-content .blog-comments-area .blog-comments-wrap {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel {
  padding: 50px 50px 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel {
    padding: 30px 20px 30px;
  }
}

.blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel:last-child {
  border-bottom: none;
}

.blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment {
    flex-wrap: wrap;
  }
  .blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment.ml-80 {
    margin-left: 0;
  }
}

.blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-img {
  flex: 0 0 94px;
  margin-right: 50px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-img {
    margin-bottom: 10px;
  }
}

.blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-img img {
  width: 100%;
}

.blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content p {
  font-size: 16px;
  color: #535353;
  font-family: "Alegreya", serif;
}

.blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply {
  display: flex;
}

.blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply h5 {
  font-weight: 600;
  color: #262626;
  font-size: 12px;
  margin: 0 10px 0 0;
  line-height: 1;
}

.blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply h5 span {
  font-size: 12px;
  color: #8d8d8d;
  display: inline-block;
  line-height: 1;
}

.blog-details-content .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply a {
  display: inline-block;
  line-height: 1;
  font-size: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content .comment-form {
    margin-bottom: 50px;
  }
  .blog-details-content .comment-form.mrg-none {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content .comment-form {
    margin-bottom: 30px;
  }
  .blog-details-content .comment-form.mrg-none {
    margin-bottom: 0;
  }
}

.blog-details-content .comment-form > h3 {
  font-size: 36px;
  margin: 0 0 7px;
  font-family: "Alegreya", serif;
}

.blog-details-content .comment-form p {
  font-size: 12px;
  color: #535353;
  margin: 0 0 45px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .comment-form p {
    margin: 0 0 20px;
  }
}

.blog-details-content .comment-form p span {
  color: red;
}

.blog-details-content .comment-form form .leave-form {
  margin: 0 0 15px;
}

.blog-details-content .comment-form form .leave-form label {
  display: block;
  margin: 0 0 7px;
}

.blog-details-content .comment-form form .leave-form label span {
  color: red;
}

.blog-details-content .comment-form form .leave-form input {
  background-color: transparent;
  height: 50px;
  border: 1px solid #CDCDCD;
}

.blog-details-content .comment-form form .leave-form input:focus {
  border: 1px solid #262626;
}

.blog-details-content .comment-form form .leave-form textarea {
  background-color: transparent;
  height: 150px;
  border: 1px solid #CDCDCD;
}

.blog-details-content .comment-form form .leave-form textarea:focus {
  border: 1px solid #262626;
}

.blog-details-content .comment-form form .text-submit {
  margin-top: 7px;
}

.blog-details-content .comment-form form .text-submit input {
  height: auto;
  border: none;
  display: inline-block;
  padding: 14px 33px;
  font-weight: 600;
  background-color: #262626;
  color: #fff;
  width: auto;
  letter-spacing: 2px;
  font-size: 12px;
  text-transform: uppercase;
}

.blog-details-content .comment-form form .text-submit input:hover {
  background-color: #fc4f4f;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-area.pb-100 {
    padding-bottom: 50px;
  }
  .pagination-mrg {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area.pb-100 {
    padding-bottom: 20px;
  }
  .pagination-mrg {
    margin-top: 30px;
  }
}

/*-------- 16. Video style ---------*/
.video-area {
  position: relative;
  padding-top: 80px;
  overflow: hidden;
}

.jarallax-position {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  perspective: 1200px;
  z-index: 2;
}

.jarallax-position .parallax-img-2 {
  position: absolute;
  left: 30px;
  top: 0%;
  height: 200%;
  width: 100%;
  background-repeat: no-repeat;
  will-change: transform;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .jarallax-position .parallax-img-2 {
    height: 500%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .jarallax-position .parallax-img-2 {
    height: 500%;
  }
}

@media only screen and (max-width: 767px) {
  .jarallax-position .parallax-img-2 {
    left: -80px;
    height: 100%;
    width: 200%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .jarallax-position .parallax-img-2 {
    left: -30px;
    height: 100%;
    width: 100%;
  }
}

.video-content {
  padding: 195px 0 160px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-content {
    padding: 105px 0 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-content {
    padding: 90px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .video-content {
    padding: 80px 0 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .video-content {
    padding: 100px 0 100px;
  }
}

.video-content h3 {
  color: rgba(255, 255, 255, 0.42);
  font-size: 120px;
  font-weight: 500;
  margin: 0;
  line-height: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-content h3 {
    font-size: 100px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-content h3 {
    font-size: 80px;
    line-height: 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-content h3 {
    font-size: 70px;
    line-height: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .video-content h3 {
    font-size: 35px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .video-content h3 {
    font-size: 55px;
    line-height: 50px;
  }
}

.video-content h1 {
  color: #ffffff;
  font-size: 250px;
  font-weight: 400;
  line-height: 230px;
  text-shadow: 10px 0px 32px rgba(0, 0, 0, 0.14);
  margin: 21px 0 8px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .video-content h1 {
    font-size: 175px;
    line-height: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-content h1 {
    font-size: 150px;
    line-height: 190px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-content h1 {
    font-size: 120px;
    line-height: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-content h1 {
    font-size: 80px;
    line-height: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .video-content h1 {
    font-size: 35px;
    line-height: 50px;
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .video-content h1 {
    font-size: 55px;
    line-height: 60px;
    margin: 10px 0 40px;
  }
}

.video-content .video-btn {
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: all .5s;
  z-index: 9;
}

.video-content .video-btn:before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  opacity: 0;
  transition: all .5s;
  z-index: 1;
}

.video-content .video-btn a {
  position: relative;
  z-index: 2;
  display: inline-block;
}

.video-content .video-btn a img {
  border-radius: 50% 50% 50% 50%;
  box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-content .video-btn a img {
    width: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .video-content .video-btn a img {
    width: 65px;
  }
}

.video-content .video-btn:hover:before {
  opacity: 1;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px;
}

.video-content .video-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  margin: -10px;
}

.video-area-ptb-2 {
  padding-top: 291px;
  padding-bottom: 265px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .video-area-ptb-2 {
    padding-top: 191px;
    padding-bottom: 165px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-area-ptb-2 {
    padding-top: 191px;
    padding-bottom: 165px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area-ptb-2 {
    padding-top: 171px;
    padding-bottom: 145px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-area-ptb-2 {
    padding-top: 171px;
    padding-bottom: 145px;
  }
}

@media only screen and (max-width: 767px) {
  .video-area-ptb-2 {
    padding-top: 90px;
    padding-bottom: 100px;
  }
}

.video-content-2 h2 {
  color: #fff;
  font-size: 120px;
  font-family: "Alegreya", serif;
  text-transform: uppercase;
  font-style: italic;
  margin: 0 0 25px;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-content-2 h2 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-content-2 h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-content-2 h2 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .video-content-2 h2 {
    font-size: 40px;
  }
}

.video-content-2 .video-btn-2 {
  position: relative;
  display: inline-block;
  transition: all .5s;
  cursor: pointer;
}

.video-content-2 .video-btn-2 a {
  position: relative;
  z-index: 2;
}

.video-content-2 .video-btn-2:before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  opacity: 0;
  transition: all .5s;
  z-index: 1;
}

.video-content-2 .video-btn-2:hover:before {
  opacity: 1;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px;
}

.video-content-2 .video-btn-2:hover {
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  margin: -10px;
  border-radius: 100%;
}

/*-------- 17. Brand logo style ---------*/
.single-brand-logo {
  padding: 10px 60px 10px 60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-brand-logo {
    padding: 10px 30px 10px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-brand-logo {
    padding: 10px 30px 10px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-brand-logo {
    padding: 10px 0px 10px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-brand-logo {
    padding: 10px 20px 10px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-brand-logo {
    padding: 10px 0px 10px 0px;
  }
}

.single-brand-logo a {
  display: block;
}

.single-brand-logo a img {
  width: 100%;
}

.single-brand-logo-2 {
  padding: 10px 10px 10px 10px;
}

.single-brand-logo-2 a {
  display: block;
}

.single-brand-logo-2 a img {
  width: 100%;
  transform: scale(1);
  transition: all .3s ease 0s;
}

.single-brand-logo-2:hover a img {
  transform: scale(1.2);
}

/*-------- 18. Testimonial style ---------*/
.testimonial-active.owl-carousel .owl-item img {
  width: auto;
  margin: 0 auto;
}

.single-testimonial p {
  line-height: 1.8em;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-family: "Alegreya", serif;
  width: 80%;
  margin: 32px auto 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial p {
    width: 85%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial p {
    margin: 20px auto 25px;
    font-size: 17px;
    width: 100%;
  }
}

.single-testimonial .client-info img {
  border-radius: 100%;
}

.single-testimonial .client-info span {
  color: #fff;
  font-size: 18px;
  font-family: "Alegreya", serif;
  margin: 25px 0 0;
  display: block;
}

.single-testimonial-2 p {
  font-size: 20px;
  color: #151515;
  margin: 39px auto 32px;
  width: 58%;
  line-height: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-2 p {
    width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-2 p {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 p {
    font-size: 16px;
    width: 95%;
    line-height: 30px;
    margin: 20px auto 22px;
  }
}

.single-testimonial-2 .client-img-2 img {
  border-radius: 100%;
}

.single-testimonial-2 .client-info-2 {
  margin: 25px 0 0;
}

.single-testimonial-2 .client-info-2 h3 {
  color: #151515;
  font-size: 24px;
  font-weight: bold;
  margin: 0px 0 7px;
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .client-info-2 h3 {
    font-size: 20px;
    margin: 0px 0 5px;
  }
}

.single-testimonial-2 .client-info-2 span {
  font-size: 16px;
}

/*-------- 19. Breadcrumb style ---------*/
.breadcrumb-ptb-1 {
  padding: 85px 0 95px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-ptb-1 {
    padding: 75px 0 85px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-ptb-1 {
    padding: 45px 0 55px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-1 {
    padding: 35px 0 45px;
  }
}

.breadcrumb-ptb-2 {
  padding: 200px 0 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-ptb-2 {
    padding: 45px 0 55px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-2 {
    padding: 35px 0 45px;
  }
}

.breadcrumb-ptb-3 {
  padding: 50px 0 50px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-3 {
    padding: 30px 0 30px;
  }
}

.breadcrumb-ptb-5 {
  padding: 250px 0 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-ptb-5 {
    padding: 50px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-5 {
    padding: 30px 0 30px;
  }
}

.breadcrumb-bg-1 {
  background: #fff url("../../assets/images/bg/breadcrumb-bg-1.jpg") repeat-x center bottom;
}

.breadcrumb-bg-2 {
  background: #FFEBEB url("../../assets/images/bg/breadcrumb-bg-2.png") no-repeat right bottom;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-bg-2 {
    background-size: 600px auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-bg-2 {
    background-size: 500px auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-bg-2 {
    background-size: 400px auto;
  }
}

.breadcrumb-bg-3 {
  background: url("../../assets/images/bg/breadcrumb-bg-3.jpg");
  background-size: cover;
  background-position: 100% 0%;
}

.breadcrumb-bg-4 {
  background: url("../../assets/images/bg/breadcrumb-bg-4.jpg");
  background-repeat: repeat;
  background-position: center center;
  background-size: cover;
}

.breadcrumb-content {
  position: relative;
  z-index: 9;
}

.breadcrumb-content .breadcrumb-title {
  margin: 0 0 8px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title {
    margin: 0 0 10px;
  }
}

.breadcrumb-content .breadcrumb-title h2 {
  font-size: 42px;
  font-weight: bold;
  font-family: "Alegreya", serif;
  margin: 0;
  line-height: 52px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

.breadcrumb-content ul li {
  display: inline-block;
  margin: 0 2px;
  text-transform: capitalize;
  color: #777777;
  font-size: 12px;
}

.breadcrumb-content ul li span {
  margin-right: 4px;
  display: inline-block;
  margin: 6px 4px 0 0;
  line-height: 1;
  position: relative;
  top: 1px;
}

.breadcrumb-content ul li a {
  text-transform: capitalize;
  color: #262626;
}

.breadcrumb-content ul li a:hover {
  color: #fc4f4f;
}

.breadcrumb-content.breadcrumb-white .breadcrumb-title h2 {
  color: #fff;
}

.breadcrumb-content.breadcrumb-white ul li {
  color: #fff;
}

.breadcrumb-content.breadcrumb-white ul li span {
  color: #fff;
}

.breadcrumb-content.breadcrumb-white ul li a {
  color: #fff;
}

.breadcrumb-content.breadcrumb-white ul li a:hover {
  color: #fc4f4f;
}

.breadcrumb-content.breadcrumb-font-inc ul li {
  font-size: 14px;
}

.breadcrumb-content.breadcrumb-font-inc ul li span {
  font-size: 14px;
}

.breadcrumb-content.breadcrumb-font-inc ul li a {
  font-size: 14px;
}

.breadcrumb-ptb-4 {
  padding: 440px 0 59px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-ptb-4 {
    padding: 300px 0 59px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-ptb-4 {
    padding: 240px 0 59px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-ptb-4 {
    padding: 240px 0 59px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-ptb-4 {
    padding: 80px 0 80px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-4 {
    padding: 50px 0 50px;
  }
}

.breadcrumb-content-2 h2 {
  font-size: 120px;
  color: #fff;
  margin: 0 0 0 -50px;
  line-height: 1em;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-content-2 h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-content-2 h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content-2 h2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content-2 h2 {
    font-size: 40px;
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content-2 h2 {
    font-size: 35px;
    margin: 0 0 0 0px;
  }
}

/*-------- 20. Shop style ---------*/
.shop-top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar {
    display: block;
  }
}

.shop-top-bar .shop-top-bar-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: flex;
  }
}

.shop-top-bar .shop-top-bar-left .shop-top-show {
  line-height: 1;
}

.shop-top-bar .shop-top-bar-left .shop-top-show span {
  display: block;
  line-height: 1;
}

.shop-top-bar .shop-top-bar-left .shop-page-list {
  margin-left: 45px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-left: 45px;
    margin-top: 0px;
  }
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li {
  display: inline-block;
  color: #535353;
  margin-right: 11px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li:last-child {
  margin-right: 0px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active {
  border-bottom: 1px solid #262626;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active a {
  color: #262626;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li a {
  color: #777777;
}

.shop-top-bar .shop-top-bar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-top-bar .shop-top-bar-right .shop-filter {
  line-height: 1;
  position: relative;
}

.shop-top-bar .shop-top-bar-right .shop-filter a {
  color: #282828;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i {
  color: #282828;
  margin-left: 3px;
  transition: all .4s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i.angle-up {
  position: absolute;
  right: 1px;
  top: 0px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active {
  color: #fc4f4f;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i {
  color: #fc4f4f;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by {
  position: relative;
  padding: 30px 0;
  line-height: 1;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span {
  color: #282828;
  position: relative;
  cursor: pointer;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i {
  color: #282828;
  margin-left: 3px;
  transition: all .4s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i.angle-up {
  position: absolute;
  right: 3px;
  top: 1px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul {
  right: 0;
  top: 100%;
  position: absolute;
  background-color: #fff;
  padding: 7px;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
  transition: all 250ms ease-out;
  margin-top: 30px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li {
  display: block;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a {
  padding: 5px 10px;
  white-space: pre;
  display: block;
  border-radius: 4px;
  font-size: 0.92em;
  color: #777777;
  line-height: 24px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a:hover {
  color: #fff;
  background-color: #262626;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li.active a {
  color: #fff;
  background-color: #262626;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: -5px;
}

.shop-top-bar .shop-top-bar-right .shop-tab a {
  color: #535353;
  font-size: 18px;
  margin-right: 10px;
}

.shop-top-bar .shop-top-bar-right .shop-tab a:last-child {
  margin-right: 0;
}

.shop-top-bar .shop-top-bar-right .shop-tab a.active {
  color: #262626;
}

.product-filter-wrapper {
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  font-size: 12px;
  padding: 48px 40px 18px;
  display: none;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

.product-filter-wrapper .product-filter h5 {
  font-size: 16px;
  margin: 0 0 50px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: "Alegreya", serif;
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper .product-filter h5 {
    margin: 0 0 10px;
  }
}

.product-filter-wrapper .product-filter .price-filter ul li, .product-filter-wrapper .product-filter .product-size ul li, .product-filter-wrapper .product-filter .product-color ul li, .product-filter-wrapper .product-filter .product-brands ul li {
  margin-bottom: 7px;
  display: block;
}

.product-filter-wrapper .product-filter .price-filter ul li:last-child, .product-filter-wrapper .product-filter .product-size ul li:last-child, .product-filter-wrapper .product-filter .product-color ul li:last-child, .product-filter-wrapper .product-filter .product-brands ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-filter .price-filter ul li a, .product-filter-wrapper .product-filter .product-size ul li a, .product-filter-wrapper .product-filter .product-color ul li a, .product-filter-wrapper .product-filter .product-brands ul li a {
  color: #535353;
  font-size: 12px;
}

.product-filter-wrapper .product-filter .price-filter ul li a:hover, .product-filter-wrapper .product-filter .product-size ul li a:hover, .product-filter-wrapper .product-filter .product-color ul li a:hover, .product-filter-wrapper .product-filter .product-brands ul li a:hover {
  color: #fc4f4f;
}

.product-filter-wrapper .product-filter .product-color ul, .product-filter-wrapper .product-filter .product-size ul, .product-filter-wrapper .product-filter .product-brands ul {
  width: 100%;
}

.product-filter-wrapper .product-filter .product-color ul li, .product-filter-wrapper .product-filter .product-size ul li, .product-filter-wrapper .product-filter .product-brands ul li {
  width: 50%;
  display: inline-block;
  float: left;
}

.pro-pagination-style ul li {
  display: inline-block;
  margin: 0 1px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li {
    margin: 0 4px;
  }
}

.pro-pagination-style ul li a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #535353;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.pro-pagination-style ul li a.active, .pro-pagination-style ul li a:hover {
  background-color: #262626;
  color: #fff;
  border: 1px solid #262626;
}

.pro-pagination-style ul li a i {
  line-height: 50px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li a i {
    line-height: 40px;
  }
}

.pro-pagination-style ul li:last-child {
  position: relative;
  top: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-pagination-style.ml-150 {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style.mt-35 {
    margin-top: 0;
  }
  .pro-pagination-style.mt-50 {
    margin-top: 20px;
  }
  .pro-pagination-style.mt-30 {
    margin-top: 10px;
  }
  .pro-pagination-style.ml-150 {
    margin-left: 0px;
  }
  .pro-pagination-style.ml-50 {
    margin-left: 100px;
  }
}

.shop-list-wrap .row div[class^="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap.mb-70 {
    margin-bottom: 40px;
  }
}

.shop-list-wrap:hover .shop-list-quickview {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
}

.custom-col-32-shop {
  flex: 0 0 32%;
  max-width: 32%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-32-shop {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-32-shop {
    flex: 0 0 45%;
    max-width: 45%;
  }
}

.custom-col-68-shop {
  flex: 0 0 68%;
  max-width: 68%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .custom-col-68-shop {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-68-shop {
    flex: 0 0 55%;
    max-width: 55%;
  }
}

.shop-list-content {
  margin-top: 6px;
}

.shop-list-content h3 {
  font-size: 24px;
  margin: 0 0 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-list-content h3 {
    margin: 0 0 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-content h3 {
    margin: 0 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-content h3 {
    font-size: 20px;
    margin: 0 0 10px;
  }
}

.shop-list-content .pro-list-price span {
  font-size: 18px;
}

.shop-list-content .pro-list-price span.old {
  color: #777;
  margin-right: 5px;
  text-decoration: line-through;
}

.shop-list-content p {
  color: #535353;
  margin: 10px 0 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-list-content p {
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-content p {
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-content p {
    margin: 10px 0 20px;
  }
}

.shop-list-content .product-list-action {
  display: flex;
  align-items: center;
}

.shop-list-content .product-list-action > a {
  display: inline-block;
  font-weight: bold;
  color: #262626;
  font-size: 18px;
  text-align: center;
}

.shop-list-content .product-list-action > a:hover {
  color: #fc4f4f;
}

.shop-list-content .product-list-action .pro-list-actioncart {
  margin: 0 54px 0;
}

.shop-list-content .product-list-action .pro-list-actioncart a {
  font-size: 12px;
  color: #535353;
  text-transform: uppercase;
}

.shop-list-content .product-list-action .pro-list-actioncart a:hover {
  color: #fc4f4f;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-content.ml-20 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-content {
    margin-top: 10px;
  }
  .shop-list-content.ml-20 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-content {
    margin-top: 0px;
  }
}

.shop-list-content.shop-list-mrg-dec {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .shop-list-content.shop-list-mrg-dec {
    margin-top: 10px;
  }
}

.shop-list-content.shop-list-mrg-dec h3 {
  margin: 0 0 5px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-list-content.shop-list-mrg-dec h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-list-content.shop-list-mrg-dec h3 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-list-content.shop-list-mrg-dec h3 {
    font-size: 18px;
  }
}

.shop-list-content.shop-list-mrg-dec p {
  margin: 10px 0 20px;
}

.shop-collection-wrap {
  overflow: hidden;
}

.shop-collection-wrap .collection-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .35s ease 0s;
}

.shop-collection-wrap .shop-collection-content {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 21px 20px 22px;
  min-width: 180px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collection-wrap .shop-collection-content {
    padding: 16px 20px 17px;
    min-width: 158px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collection-wrap .shop-collection-content {
    padding: 16px 20px 17px;
    min-width: 158px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collection-wrap .shop-collection-content {
    padding: 15px 20px 16px;
    min-width: 150px;
  }
}

.shop-collection-wrap .shop-collection-content h4 {
  font-size: 18px;
  margin: 0;
  color: #fff;
  transition: all .35s ease 0s;
  text-align: center;
  letter-spacing: 0px;
}

.shop-collection-wrap .shop-collection-content h4 a {
  color: #fff;
}

.shop-collection-wrap:hover .shop-collection-content h4 {
  letter-spacing: 1px;
}

.shop-collection-wrap:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.shop-collection-wrap:hover .collection-img a img {
  transform: scale(1.05);
}

.shop-collection-title {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .shop-collection-title {
    margin-bottom: 25px;
  }
}

.shop-collection-title h3 {
  font-size: 46px;
  margin: 0;
  line-height: 35px;
}

@media only screen and (max-width: 767px) {
  .shop-collection-title h3 {
    font-size: 35px;
    line-height: 28px;
  }
}

.shop-collection-wrap-2 {
  position: relative;
}

.shop-collection-wrap-2 .collection-img-2 a img {
  width: 100%;
}

.shop-collection-wrap-2 .shop-collection-content-2 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 18%;
  z-index: 9;
}

.shop-collection-wrap-2 .shop-collection-content-2 h4 {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 16px;
  margin: 0;
  opacity: 0;
  transition: all .4s ease-in-out .1s;
  transform: translateY(20px);
}

.shop-collection-wrap-2 .shop-collection-content-2 h4 a {
  color: #ffffff;
}

.shop-collection-wrap-2 .shop-collection-content-2 p {
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2px;
  opacity: 0;
  transform: translateY(20px);
  transition: all .4s ease-in-out .3s;
  width: 80%;
  margin: 12px auto 30px;
}

@media only screen and (max-width: 767px) {
  .shop-collection-wrap-2 .shop-collection-content-2 p {
    width: 90%;
  }
}

.shop-collection-wrap-2 .shop-collection-content-2 .collection-btn a {
  display: inline-block;
  line-height: 1;
  color: #fff;
  font-size: 12px;
  letter-spacing: 2px;
  padding: 15px 32px 16px;
  text-transform: uppercase;
  font-weight: 600;
  border: 2px solid #fff;
  opacity: 0;
  transform: translateY(20px);
  transition: all .4s ease-in-out .4s;
}

.shop-collection-wrap-2 .shop-collection-content-2 .collection-btn a:hover {
  background-color: #fc4f4f;
  border: 2px solid #fc4f4f;
}

.shop-collection-wrap-2:hover .shop-collection-content-2 h4, .shop-collection-wrap-2:hover .shop-collection-content-2 p, .shop-collection-wrap-2:hover .shop-collection-content-2 .collection-btn a {
  opacity: 1;
  transform: translateY(0px);
}

.shop-collection-wrap-2:hover.default-overlay:before {
  background-color: #000;
  opacity: .2;
  z-index: 1;
  pointer-events: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-collection-wrap-2.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collection-wrap-2.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collection-wrap-2.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collection-wrap-2.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-area.pb-40 {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-area.pb-40 {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-area.pb-40 {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-area .tab-content.pt-30 {
    padding-top: 0px;
  }
  .shop-area.pt-70 {
    padding-top: 50px;
  }
  .shop-area.pb-40 {
    padding-bottom: 20px;
  }
}

/*-------- 21. Sidebar style ---------*/
.sidebar-widget {
  display: block;
  overflow: hidden;
}

.sidebar-widget h4.pro-sidebar-title {
  font-size: 32px;
  margin: 0;
  position: relative;
  font-weight: 300;
  font-family: "Alegreya", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget h4.pro-sidebar-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget h4.pro-sidebar-title {
    font-size: 25px;
  }
}

.sidebar-widget h4.pro-sidebar-title2 {
  font-size: 24px;
  margin: 0;
  position: relative;
  font-weight: 600;
  font-family: "Alegreya", serif;
}

.sidebar-widget .sidebar-widget-list ul li {
  padding-bottom: 27px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-widget-list ul li {
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-list ul li {
    padding-bottom: 10px;
  }
}

.sidebar-widget .sidebar-widget-list ul li:last-child {
  padding-bottom: 0px;
}

.sidebar-widget .sidebar-widget-list ul li a {
  color: #535353;
}

.sidebar-widget .sidebar-widget-list ul li a:hover {
  color: #fc4f4f;
  text-decoration: underline;
}

.sidebar-widget .sidebar-widget-list ul li span {
  float: right;
  color: #2F2F2F;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-widget-list.mt-50 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-list.mt-50 {
    margin-top: 17px;
  }
  .sidebar-widget .sidebar-widget-list.mt-40 {
    margin-top: 17px;
  }
}

.sidebar-widget .price-filter #slider-range {
  background: #eee none repeat scroll 0 0;
  border: medium none;
  border-radius: 50px;
  height: 5px;
  margin-bottom: 12px;
  margin-left: auto;
}

.sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #262626 none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 23px;
  margin-left: 0;
  width: 23px;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
  top: -10px;
  cursor: ew-resize;
}

.sidebar-widget .price-filter .price-slider-amount {
  margin: 22px 0 0;
}

.sidebar-widget .price-filter .price-slider-amount .label-input {
  margin: 25px 0 0;
  display: flex;
  align-items: center;
}

.sidebar-widget .price-filter .price-slider-amount .label-input span {
  color: #535353 !important;
  margin-right: 5px;
}

.sidebar-widget .price-filter .price-slider-amount .label-input input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 14px;
  font-weight: 400;
  height: auto;
  color: #535353 !important;
}

.sidebar-widget .price-filter .price-slider-amount button {
  width: 140px;
  background-color: transparent;
  padding: 8px 10px;
  margin-top: 15px;
  border: 2px solid #262626;
  font-size: 11px;
  font-weight: 700;
  order: 2;
  color: #262626;
  transition: all 0.3s;
  text-transform: uppercase;
}

.sidebar-widget .price-filter .price-slider-amount button:hover {
  background-color: #fc4f4f;
  color: #fff;
  border: 2px solid #fc4f4f;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .price-filter.mt-60 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .price-filter.mt-60 {
    margin-top: 35px;
  }
}

.sidebar-widget .sidebar-widget-size ul li {
  display: inline-block;
  margin-right: 7px;
}

.sidebar-widget .sidebar-widget-size ul li a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 11px 26px;
  display: block;
  margin-bottom: 10px;
  transition: all 0.3s;
  color: #535353;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-size ul li a {
    padding: 6px 10px;
  }
}

.sidebar-widget .sidebar-widget-size ul li a:hover {
  background-color: #fc4f4f;
  color: #fff;
  border: 1px solid #fc4f4f;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-widget-size.mt-45 {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-size.mt-45 {
    margin-top: 20px;
  }
}

.sidebar-widget .sidebar-widget-color ul li {
  margin-bottom: 16px;
  display: block;
  width: 50%;
  float: left;
}

.sidebar-widget .sidebar-widget-color ul li a {
  color: #535353;
}

.sidebar-widget .sidebar-widget-color ul li a:hover {
  color: #fc4f4f;
}

.sidebar-widget .sidebar-widget-color ul li a span {
  color: #535353;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-widget-color.mt-40 {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-color.mt-40 {
    margin-top: 15px;
  }
}

.sidebar-widget .sidebar-widget-tag ul li {
  display: inline-block;
  padding-bottom: 10px;
}

.sidebar-widget .sidebar-widget-tag ul li a {
  color: #535353;
}

.sidebar-widget .sidebar-widget-tag ul li a span {
  color: #535353;
}

.sidebar-widget .sidebar-widget-tag ul li a:hover {
  color: #fc4f4f;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-widget-tag.mt-45 {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-tag.mt-45 {
    margin-top: 20px;
  }
  .sidebar-widget .sidebar-widget-tag.mt-35 {
    margin-top: 20px;
  }
}

.sidebar-widget .shop-sidebar-banner {
  position: relative;
  overflow: hidden;
}

.sidebar-widget .shop-sidebar-banner a img {
  width: 100%;
  transform: scale(1);
  transition: all .35s ease 0s;
}

.sidebar-widget .shop-sidebar-banner .shop-sidebar-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  z-index: 99;
}

.sidebar-widget .shop-sidebar-banner .shop-sidebar-content h5 {
  color: #ffffff;
  font-size: 16px;
  margin: 0 0 12px;
}

.sidebar-widget .shop-sidebar-banner .shop-sidebar-content h3 {
  color: #ffffff;
  font-size: 44px;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h3 {
    font-size: 30px;
  }
}

.sidebar-widget .shop-sidebar-banner:hover a img {
  transform: scale(1.05);
}

.sidebar-widget .shop-sidebar-banner:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget.mb-65 {
    margin-bottom: 45px;
  }
  .sidebar-widget.mb-70 {
    margin-bottom: 50px;
  }
  .sidebar-widget.mb-60 {
    margin-bottom: 40px;
  }
  .sidebar-widget.mb-45 {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget.mb-65 {
    margin-bottom: 35px;
  }
  .sidebar-widget.mb-70 {
    margin-bottom: 40px;
  }
  .sidebar-widget.mb-60 {
    margin-bottom: 30px;
  }
  .sidebar-widget.mb-45 {
    margin-bottom: 15px;
  }
  .sidebar-widget.mb-50 {
    margin-bottom: 30px;
  }
  .sidebar-widget.mb-55 {
    margin-bottom: 30px;
  }
  .sidebar-widget.mt-60 {
    margin-top: 30px;
  }
}

.shhop-pl-35 {
  padding-left: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shhop-pl-35 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shhop-pl-35 {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shhop-pl-35 {
    padding-left: 0px;
  }
}

.shop-sidebar-style.shop-sidebar-mrg {
  margin-right: 115px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-sidebar-style.shop-sidebar-mrg {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-sidebar-style.shop-sidebar-mrg {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-style.shop-sidebar-mrg {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sidebar-style.shop-sidebar-mrg {
    margin-right: 0px;
  }
}

/*-------- 22. Product details style ---------*/
.easyzoom-style {
  position: relative;
}

.easyzoom-style a.easyzoom-pop-up {
  position: absolute;
  right: 25px;
  bottom: 25px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  text-align: center;
  border-radius: 100%;
  z-index: 999;
}

.easyzoom-style a.easyzoom-pop-up.video-popup {
  color: #fff;
  font-size: 22px;
  width: 60px;
  height: 60px;
  line-height: 62px;
  background-color: #262626;
}

.easyzoom-style a.easyzoom-pop-up.img-popup-position {
  bottom: 100px;
}

.easyzoom-style a.easyzoom-pop-up:hover {
  color: #fc4f4f;
}

.easyzoom-style .easyzoom > a {
  transition: none;
}

.easyzoom-style .easyzoom > a img {
  width: 100%;
  transition: none;
}

.easyzoom-style .easyzoom-popup > a img {
  width: 100%;
}

.easyzoom-style span.product-sale {
  background-color: #262626;
  display: inline-block;
  color: #fff;
  left: 20px;
  top: 20px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 100%;
  text-align: center;
  position: absolute;
  font-weight: 400;
  z-index: 999;
  font-size: 10px;
  text-transform: uppercase;
}

.product-dec-right {
  float: right;
  position: relative;
  width: calc(100% - 160px);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-dec-right {
    width: calc(100% - 120px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-right {
    width: calc(100% - 120px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-right {
    width: calc(100% - 90px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-right {
    width: calc(100% - 130px);
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-right {
    width: calc(100% - 100px);
  }
}

.product-dec-left {
  position: relative;
  float: left;
  width: 160px;
  padding-right: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-dec-left {
    width: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-left {
    width: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-left {
    width: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-left {
    width: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-left {
    width: 100px;
  }
}

.product-dec-left .product-dec-small {
  margin: 0 0 20px;
  cursor: pointer;
}

.product-dec-left .product-dec-small img {
  width: 100%;
}

.product-dec-left .product-dec-small.slick-active {
  border: 2px solid transparent;
}

.product-dec-left .product-dec-small.slick-active.slick-current {
  border: 2px solid #262626;
}

.product-dec-left .product-dec-small:last-child {
  margin: 0 0 0px;
}

.product-dec-left span.pro-dec-icon {
  position: absolute;
  top: 100%;
  left: 50%;
  right: auto;
  font-size: 20px;
  cursor: pointer;
  color: #262626;
  z-index: 9;
  transform: translateX(-50%);
}

@media only screen and (max-width: 767px) {
  .product-dec-left span.pro-dec-icon {
    top: 93%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-dec-left span.pro-dec-icon {
    top: 100%;
  }
}

.product-dec-left span.pro-dec-icon:hover {
  color: #fc4f4f;
}

.product-dec-left span.pro-dec-icon.pro-dec-prev {
  margin-left: -25px;
}

.product-dec-left span.pro-dec-icon.pro-dec-next {
  margin-right: -25px;
}

.product-dec-small-style2 {
  width: 450px;
  margin: 13px auto 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-small-style2 {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style2 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style2 {
    width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style2 {
    width: 100%;
  }
}

.product-dec-small-style2 .product-dec-small {
  margin: 0 5px 0px 5px;
  cursor: pointer;
}

.product-dec-small-style2 .product-dec-small img {
  width: 100%;
}

.product-dec-small-style2 .product-dec-small.slick-active {
  border: 2px solid transparent;
}

.product-dec-small-style2 .product-dec-small.slick-active.slick-current {
  border: 2px solid #262626;
}

.product-dec-small-style2 .product-dec-small:last-child {
  margin: 0 0 0px;
}

.product-dec-small-style2 span.pro-dec-icon {
  color: #262626;
  font-size: 20px;
  position: absolute;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
}

.product-dec-small-style2 span.pro-dec-icon:hover {
  color: #fc4f4f;
}

.product-dec-small-style2 span.pro-dec-prev {
  left: -5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style2 span.pro-dec-prev {
    left: -3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style2 span.pro-dec-prev {
    left: -4%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style2 span.pro-dec-prev {
    left: -2%;
  }
}

.product-dec-small-style2 span.pro-dec-next {
  left: auto;
  right: -5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style2 span.pro-dec-next {
    right: -3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style2 span.pro-dec-next {
    right: -4%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style2 span.pro-dec-next {
    right: -2%;
  }
}

.product-dec-slider.slick-vertical .slick-slide,
.product-dec-small-style2.slick-vertical .slick-slide {
  border: 2px solid transparent;
}

.product-dec-slider .slick-slide,
.product-dec-small-style2 .slick-slide {
  border: 2px solid transparent;
}

.single-pro-details-sidebar {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-pro-details-sidebar {
    margin-top: 30px;
  }
}

.single-pro-details-sidebar:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
  pointer-events: none;
}

.single-pro-details-sidebar > a {
  display: block;
  position: relative;
  transition: all .3s ease 0s;
}

.single-pro-details-sidebar .pro-details-sidebar-content {
  position: absolute;
  left: 30px;
  bottom: 35px;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-pro-details-sidebar .pro-details-sidebar-content {
    left: 10px;
    bottom: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-pro-details-sidebar .pro-details-sidebar-content {
    left: 10px;
    bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-pro-details-sidebar .pro-details-sidebar-content {
    left: 10px;
    bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-pro-details-sidebar .pro-details-sidebar-content {
    left: 10px;
    bottom: 20px;
  }
}

.single-pro-details-sidebar .pro-details-sidebar-content h4 {
  font-size: 32px;
  color: #fff;
  line-height: 1.4074;
  font-weight: bold;
  margin: 0 0 10px;
  border-bottom: 1px solid #fff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-pro-details-sidebar .pro-details-sidebar-content h4 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-pro-details-sidebar .pro-details-sidebar-content h4 {
    font-size: 18px;
    line-height: 1.2074;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-pro-details-sidebar .pro-details-sidebar-content h4 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-pro-details-sidebar .pro-details-sidebar-content h4 {
    font-size: 22px;
  }
}

.single-pro-details-sidebar .pro-details-sidebar-content h4 a {
  color: #fff;
}

.single-pro-details-sidebar .pro-details-sidebar-content p {
  font-size: 24px;
  color: #fff;
  margin: 0 0 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-pro-details-sidebar .pro-details-sidebar-content p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-pro-details-sidebar .pro-details-sidebar-content p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-pro-details-sidebar .pro-details-sidebar-content p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-pro-details-sidebar .pro-details-sidebar-content p {
    font-size: 18px;
  }
}

.single-pro-details-sidebar:hover:before {
  opacity: .4;
  background-color: #000;
  visibility: visible;
}

.slider-nav-style-3 {
  position: relative;
}

.slider-nav-style-3 .owl-nav div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 20px;
  left: 20px;
  z-index: 9;
}

.slider-nav-style-3 .owl-nav div:hover {
  color: #fc4f4f;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-nav-style-3 .owl-nav div {
    left: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-nav-style-3 .owl-nav div {
    left: 10px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-nav-style-3 .owl-nav div {
    left: 10px;
    font-size: 15px;
  }
}

.slider-nav-style-3 .owl-nav div.owl-next {
  left: auto;
  right: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-nav-style-3 .owl-nav div.owl-next {
    right: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-nav-style-3 .owl-nav div.owl-next {
    right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-nav-style-3 .owl-nav div.owl-next {
    right: 10px;
  }
}

.description-review-topbar {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-topbar {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-topbar {
    margin-bottom: 20px;
  }
}

.description-review-topbar a {
  font-size: 18px;
  padding: 21px 60px 22px;
  text-align: center;
  color: #A3A3A3;
  line-height: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 20px;
}

.description-review-topbar a:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .description-review-topbar a {
    padding: 16px 40px 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .description-review-topbar a {
    padding: 16px 30px 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .description-review-topbar a {
    padding: 14px 20px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-topbar a {
    font-size: 15px;
    padding: 10px 10px 12px;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-topbar a {
    font-size: 13px;
    padding: 10px 10px 12px;
    margin-bottom: 5px;
    margin-right: 8px;
  }
}

.description-review-topbar a.active {
  color: #2F2F2F;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .pro-details-banner {
    margin-bottom: 20px;
  }
}

.pro-details-banner a {
  position: relative;
}

.pro-details-banner a img {
  width: 100%;
}

.pro-details-banner a::after {
  content: '';
  background-image: url(../images/icon-img/btn-play-3.png);
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 82px;
  height: 82px;
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
  transition: all .3s;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
}

.pro-details-banner a::before {
  content: '';
  width: 82px;
  height: 82px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
  transition: all .3s;
  border-radius: 50%;
}

.pro-details-banner:hover a::before {
  opacity: 0.4;
  transform: scale(1.6);
  background: rgba(255, 255, 255, 0.3);
}

.pro-details-banner:hover a::after {
  transform: scale(0.9);
  box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.3);
}

.product-dec-content {
  padding-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-content {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-content {
    padding-left: 0px;
  }
}

.product-dec-content p {
  line-height: 1.88;
  margin: 0;
  font-size: 16px;
  color: #777;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content p {
    font-size: 14px;
  }
}

.product-dec-content ul {
  list-style-position: inside;
  margin: 30px 0 31px 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-content ul {
    margin: 15px 0 30px 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content ul {
    margin: 15px 0 30px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-content ul {
    margin: 15px 0 20px 12px;
  }
}

.product-dec-content ul li {
  margin-bottom: 20px;
  list-style: disc;
  line-height: 1.88;
  font-size: 16px;
  color: #777;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content ul li {
    font-size: 14px;
  }
}

.product-dec-content ul li:last-child {
  margin-bottom: 0;
}

.additional-info ul li {
  margin-bottom: 3px;
}

.additional-info ul li:last-child {
  margin-bottom: 0;
}

.additional-info ul li span {
  width: 215px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .additional-info ul li span {
    width: 70px;
  }
}

.review-wrapper h2 {
  font-size: 36px;
  margin: 0 0 37px;
  font-family: "Alegreya", serif;
}

@media only screen and (max-width: 767px) {
  .review-wrapper h2 {
    font-size: 18px;
    margin: 0 0 18px;
  }
}

.single-review {
  margin: 0 0 60px;
  padding: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
}

@media only screen and (max-width: 767px) {
  .single-review {
    padding: 30px;
    display: block;
    margin: 0 0 30px;
  }
}

.single-review .review-img img {
  border-radius: 100%;
}

.single-review .review-content {
  margin-left: 50px;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content {
    margin-left: 0px;
  }
}

.single-review .review-content .review-top-wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 0 33px;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content .review-top-wrap {
    display: block;
    margin: 10px 0 10px;
  }
}

.single-review .review-content .review-top-wrap .review-name h5 {
  font-size: 12px;
  margin: 0;
  color: #535353;
}

.single-review .review-content .review-top-wrap .review-name h5 span {
  font-weight: bold;
  font-size: 14px;
  color: #262626;
}

.single-review .review-content .review-top-wrap .review-rating {
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content .review-top-wrap .review-rating {
    margin: 10px 0 0px;
  }
}

.single-review .review-content .review-top-wrap .review-rating i {
  font-size: 14px;
  color: #d5d6d8;
  margin-left: 2px;
}

.single-review .review-content .review-top-wrap .review-rating i.yellow {
  color: #f5b223;
}

.single-review .review-content p {
  font-size: 16px;
  color: #535353;
  line-height: 1.714286;
  margin: 0;
  font-family: "Alegreya", serif;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content p {
    font-size: 15px;
  }
}

.ratting-form-wrapper > span {
  display: block;
  line-height: 1;
  font-family: "Alegreya", serif;
}

.ratting-form-wrapper p {
  font-size: 12px;
  margin: 9px 0 0;
  color: #535353;
}

.ratting-form-wrapper p span {
  color: red;
}

.ratting-form-wrapper .ratting-form {
  margin: 30px 0 0;
}

.ratting-form-wrapper .ratting-form .star-box-wrap {
  display: flex;
  margin: 16px 0 29px;
}

.ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star {
  margin-right: 17px;
  display: flex;
}

.ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star a {
  color: #535353;
}

.ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star:hover i {
  color: #f5b223;
}

.ratting-form-wrapper .ratting-form .rating-form-style label {
  margin: 0 0 5px;
  color: #535353;
}

.ratting-form-wrapper .ratting-form .rating-form-style label span {
  color: red;
}

.ratting-form-wrapper .ratting-form .rating-form-style input, .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 50px;
  padding: 5px 10px;
}

.ratting-form-wrapper .ratting-form .rating-form-style input:focus, .ratting-form-wrapper .ratting-form .rating-form-style textarea:focus {
  border: 1px solid #262626;
}

.ratting-form-wrapper .ratting-form .rating-form-style textarea {
  height: 150px;
}

.ratting-form-wrapper .ratting-form .form-submit {
  margin-top: 20px;
}

.ratting-form-wrapper .ratting-form .form-submit input {
  padding: 10px 35px;
  line-height: 30px;
  color: #fff;
  border-width: 0;
  font-weight: 600;
  display: inline-block;
  width: auto;
  height: auto;
  letter-spacing: 2px;
  font-size: 12px;
  text-transform: uppercase;
  background: #262626;
}

.ratting-form-wrapper .ratting-form .form-submit input:hover {
  background: #fc4f4f;
}

.pro-dec-brand-img img {
  width: 140px;
}

@media only screen and (max-width: 767px) {
  .pro-dec-brand-img {
    margin: 0 0 20px;
  }
}

.pro-dec-brand-content p {
  margin: 0;
  color: #535353;
}

.about-shiping-content p {
  color: #535353;
  line-height: 1.714286;
  margin-bottom: 30px;
}

.about-shiping-content p:last-child {
  margin-bottom: 0;
}

.sidebar-subscribe-wrap {
  background-color: #f9f0f0;
  padding: 30px 20px 35px 20px;
  margin-top: 45px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-subscribe-wrap {
    padding: 20px 10px 20px 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-subscribe-wrap {
    padding: 20px 10px 20px 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-subscribe-wrap {
    padding: 20px 10px 20px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-subscribe-wrap {
    padding: 20px 10px 20px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-subscribe-wrap {
    margin-top: 30px;
  }
}

.sidebar-subscribe-wrap .sidebar-subscribe-content i {
  color: #2f2f2f;
  font-size: 40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content i {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content i {
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content i {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content i {
    font-size: 25px;
  }
}

.sidebar-subscribe-wrap .sidebar-subscribe-content h5 {
  font-size: 22px;
  font-family: "Alegreya", serif;
  margin: 8px 0 18px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content h5 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content h5 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content h5 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content h5 {
    font-size: 16px;
  }
}

.sidebar-subscribe-wrap .sidebar-subscribe-content p {
  font-size: 10px;
  font-weight: 300;
  line-height: 1.4074;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form {
  margin-top: 12px;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form .mc-form > input {
  background-color: transparent;
  border: none;
  color: #262626;
  border-bottom: 1px solid #e0e0e8;
  padding: 2px 0 2px 0;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form .mc-form .mc-news {
  display: none;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form .mc-form .clear {
  margin-top: 20px;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form .mc-form .clear > input {
  border: none;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  background-color: #262626;
  height: 42px;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form .mc-form .clear > input:hover {
  background-color: #fc4f4f;
}

.pro-details-group {
  margin: 40px 0 0;
}

@media only screen and (max-width: 767px) {
  .pro-details-group {
    margin: 15px 0 0;
  }
}

.pro-details-group .single-group {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.pro-details-group .single-group .pro-dec-title-stock {
  margin: 5px 0 0 25px;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-title h5 {
  font-size: 12px;
  margin: 0 0 3px;
  text-transform: uppercase;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-title h5 a:hover {
  color: #fc4f4f;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-price-stock span {
  color: #262626;
  font-size: 14px;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-price-stock span.old-price {
  text-decoration: line-through;
  margin-left: 5px;
}

.sidebar-active.product-dec2-52 {
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-active.product-dec2-52.is-affixed {
  padding-left: 0px;
  padding-right: 0px;
}

.sidebar-active.product-dec2-52.is-affixed .inner-wrapper-sticky {
  padding-left: 15px;
  padding-right: 15px;
}

.configurable-wrap {
  margin: 55px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 27px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap {
    margin: 20px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .configurable-wrap {
    margin: 20px 0 0;
  }
}

.configurable-wrap .configurable-color {
  display: flex;
  align-items: center;
  margin: 0 0px 8px 0;
}

.configurable-wrap .configurable-color > span {
  font-size: 12px;
  font-weight: bold;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-color > span {
    margin-right: 10px;
  }
}

.configurable-wrap .configurable-color ul li {
  display: inline-block;
  margin-right: 3px;
}

.configurable-wrap .configurable-color ul li:last-child {
  margin-bottom: 0;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 4px 10px 6px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.blue {
  background-color: #5097f7;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.brown {
  background-color: #7a5816;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.green {
  background-color: #81d742;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.pink {
  background-color: #ff0c94;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.violet {
  background-color: #8224e3;
}

.configurable-wrap .configurable-size {
  display: flex;
  align-items: center;
}

.configurable-wrap .configurable-size > span {
  font-size: 12px;
  font-weight: bold;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-size > span {
    margin-right: 10px;
  }
}

.configurable-wrap .configurable-size ul li {
  display: inline-block;
  margin-right: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-size ul li {
    margin-right: 5px;
  }
}

.configurable-wrap .configurable-size ul li:last-child {
  margin-bottom: 0;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor {
  font-size: 12px;
  display: inline-block;
  position: relative;
  color: #262626;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor:hover {
  color: #fc4f4f;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 4px 10px 6px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.affiliate-btn {
  margin: 0 20px 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .affiliate-btn {
    margin: 0 20px 5px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .affiliate-btn {
    margin: 0 20px 20px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .affiliate-btn {
    margin: 0 20px 0 0px;
  }
}

.affiliate-btn a {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  padding: 15px 40px 20px;
  font-weight: bold;
  background-color: #262626;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .affiliate-btn a {
    padding: 20px 26px;
  }
}

@media only screen and (max-width: 767px) {
  .affiliate-btn a {
    padding: 20px 30px;
  }
}

.affiliate-btn a:hover {
  background-color: #fc4f4f;
}

@media only screen and (max-width: 767px) {
  .pro-dec-small-device {
    margin-bottom: 30px;
  }
}

/*-------- 23. Cart style ---------*/
@media only screen and (max-width: 767px) {
  .cart-main-area.pb-100 {
    padding-bottom: 60px;
  }
}

h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 11px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  color: #8A8A8A;
  letter-spacing: 1px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 0 33px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 0 0 33px 0;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0 20px 33px 20px;
  }
}

.cart-table-content table tbody > tr td {
  font-size: 14px;
  padding: 0px 0px 40px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    padding: 0px 0px 40px;
  }
}

.cart-table-content table tbody > tr td.product-remove {
  width: 30px;
}

.cart-table-content table tbody > tr td.product-remove a {
  color: #777777;
}

.cart-table-content table tbody > tr td.product-img {
  width: 90px;
}

.cart-table-content table tbody > tr td.product-name {
  width: 370px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 200px;
    padding-left: 20px;
    padding-right: 0px;
  }
}

.cart-table-content table tbody > tr td.product-name a {
  font-size: 12px;
  color: #535353;
}

.cart-table-content table tbody > tr td.product-name a:hover {
  color: #fc4f4f;
}

.cart-table-content table tbody > tr td.product-price {
  width: 90px;
}

.cart-table-content table tbody > tr td.product-price span {
  color: #262626;
  font-size: 16px;
}

.cart-table-content table tbody > tr td.cart-quality {
  width: 110px;
}

.cart-table-content table tbody > tr td.product-total {
  width: 70px;
}

.cart-table-content table tbody > tr td.product-total span {
  font-weight: 600;
  color: #262626;
  font-size: 16px;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #232323;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  padding: 7px 8px;
  text-transform: capitalize;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #fc4f4f;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #CDCDCD;
  padding: 18px 0 0;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
  }
}

.cart-shiping-update-wrapper .discount-code {
  position: relative;
  flex: 0 0 50%;
}

.cart-shiping-update-wrapper .discount-code input {
  padding: 0 110px 0 0;
  border: none;
  height: 34px;
  font-size: 12px;
  color: #282828;
  background-color: transparent;
  border-bottom: 1px solid #D8D8D8;
}

.cart-shiping-update-wrapper .discount-code button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  transition: all .3s ease 0s;
}

.cart-shiping-update-wrapper .discount-code button:hover {
  color: #fc4f4f;
}

.cart-shiping-update-wrapper .cart-clear a {
  font-weight: 600;
  display: inline-block;
  margin: 3px 0 0 7px;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear {
    margin-top: 20px;
  }
}

.grand-total-wrap {
  background: #F9F9F9;
  padding: 54px 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

.grand-total-wrap h4 {
  font-weight: 800;
  font-size: 16px;
  margin: 0;
}

.grand-total-wrap .grand-total-content {
  padding: 50px 0 35px;
}

.grand-total-wrap .grand-total-content ul li {
  font-weight: bold;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 16px;
  margin-bottom: 17px;
  font-size: 16px;
}

.grand-total-wrap .grand-total-content ul li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.grand-total-wrap .grand-total-content ul li span {
  float: right;
  font-weight: 400;
  font-size: 14px;
  margin-right: 75px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grand-total-wrap .grand-total-content ul li span {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap .grand-total-content ul li span {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap .grand-total-content ul li span {
    margin-right: 0px;
  }
}

.grand-total-wrap .grand-btn {
  margin-left: -30px;
  margin-right: -30px;
}

.grand-total-wrap .grand-btn a {
  background-color: #262626;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: block;
  padding: 21px 10px 21px;
}

.grand-total-wrap .grand-btn a:hover {
  background-color: #fc4f4f;
}

/*-------- 24. Checkout style ---------*/
.customer-zone > p {
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
  font-size: 16px;
  color: #262626;
}

.customer-zone > p::before {
  content: "\f058";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: fontawesome;
  left: 30px;
}

.checkout-login-info {
  display: none;
  margin-top: 30px;
}

.checkout-login-info p {
  color: #535353;
  margin: 0 0 22px;
  font-size: 16px;
}

.checkout-login-info form input[type="text"] {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  width: 48%;
  color: #535353;
  font-size: 16px;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .checkout-login-info form input[type="text"] {
    font-size: 14px;
  }
}

.checkout-login-info form input[type="text"]:focus {
  border: 1px solid #262626;
}

.checkout-login-info form input[type="submit"] {
  background: #262626 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 45px;
  padding: 5px 30px 7px;
  text-transform: capitalize;
  font-weight: 400;
  width: inherit;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .checkout-login-info form input[type="submit"] {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info form input[type="submit"] {
    padding: 5px 16px;
    margin-left: 5px;
  }
}

.checkout-login-info form input[type="submit"]:hover {
  background-color: #fc4f4f;
}

.billing-info-wrap h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 55px;
}

.billing-info-wrap h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -15px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.billing-info-wrap .billing-info label {
  display: block;
  margin: 0 0 10px;
  font-size: 16px;
}

.billing-info-wrap .billing-info label abbr {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #262626;
}

.billing-info-wrap .billing-info input:-moz-placeholder {
  opacity: 1;
  color: #262626;
}

.billing-info-wrap .billing-info input:-webkit-placeholder {
  opacity: 1;
  color: #262626;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #262626;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select label {
  display: block;
  margin: 0 0 10px;
  font-size: 16px;
}

.billing-info-wrap .billing-select label abbr {
  color: red;
}

.billing-info-wrap .billing-select .select2-container {
  display: block;
}

.billing-info-wrap .billing-select .select2-container .select2-choice {
  border: 1px solid #e6e6e6;
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 50px;
  padding: 2px 20px;
  color: #262626;
  cursor: pointer;
  font-size: 16px;
}

.billing-info-wrap .billing-select .select2-container .select2-choice .select2-chosen {
  height: 50px;
  font-size: 16px;
}

.billing-info-wrap .billing-select .select2-container span {
  font-size: 16px;
}

.billing-info-wrap .additional-info-wrap {
  margin: 3px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  display: block;
  margin: 0 0 10px;
  padding: 5px 0 0;
  font-size: 16px;
  color: #262626;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  padding: 20px;
  color: #262626;
  font-size: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-100 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

.select2-container--default .select2-selection--single {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  cursor: pointer;
  display: block;
  height: 50px;
  user-select: none;
  -webkit-user-select: none;
  line-height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 50px;
  color: #262626;
  line-height: 50px;
  padding: 0px 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 50px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 34px;
  background-color: transparent;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #0073aa;
  color: #fff;
}

.your-order-area {
  padding: 40px 44px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 45px;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -14px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #CDCDCD;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  padding: 0 0 7px;
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 18px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #CDCDCD;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 16px;
  margin: 0 0 11px;
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 25px 0 0;
}

.your-order-area .your-order-wrap .payment-method h5 {
  text-transform: uppercase;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method p {
  color: #535353;
  margin: 14px 0 0;
  line-height: 1.88;
}

.your-order-area .your-order-wrap .condition-wrap {
  margin: 40px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap p {
  color: #535353;
  line-height: 1.88;
  margin: 0;
}

.your-order-area .your-order-wrap .condition-wrap p a {
  color: #535353;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form {
  margin-top: 10px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 0 5px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span span.star {
  color: red;
}

.your-order-area .Place-order a {
  background-color: #262626;
  display: block;
  cursor: pointer;
  padding: 22px 50px 21px;
  width: 100%;
  color: #fff;
  text-align: center;
}

.your-order-area .Place-order a:hover {
  background-color: #fc4f4f;
}

@media only screen and (max-width: 767px) {
  .checkout-main-area.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 25. Compare style ---------*/
.compare-table .table tbody tr th.first-column {
  min-width: 200px;
  color: #262626;
  margin: 0;
  background-color: #f2f2f2;
  font-weight: 400;
  padding: 10px 10px;
  font-size: 13px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 150px;
    padding: 10px 20px;
  }
}

.compare-table .table tbody tr:first-child th {
  padding-top: 122px;
}

.compare-table .table tbody tr td {
  text-align: center;
  padding: 11px 15px;
  vertical-align: middle;
  border-color: #e7e7e7;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 293px;
  vertical-align: bottom;
  padding: 9px 10px 17px;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 220px;
  }
}

.compare-table .table tbody tr td.product-image-title .compare-remove a {
  font-size: 13px;
  color: #535353;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a i {
  padding-right: 2px;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a:hover {
  color: #fc4f4f;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin: 2px 0 6px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  width: auto;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #fc4f4f;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .pro-title {
  display: block;
  overflow: hidden;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a {
  float: left;
  clear: both;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 5px;
  display: block;
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a:hover {
  color: #fc4f4f;
}

.compare-table .table tbody tr td.product-image-title .compare-btn {
  margin: 14px 0 0;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a {
  background-color: #343538;
  padding: 5px 13px 6px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a:hover {
  background-color: #fc4f4f;
}

.compare-table .table tbody tr td.pro-desc p {
  margin: 0;
  font-size: 13px;
  line-height: 1.88;
  color: #535353;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 14px;
  font-weight: 400;
  color: #343538;
}

.compare-table .table tbody tr td.pro-sku, .compare-table .table tbody tr td.pro-weight, .compare-table .table tbody tr td.pro-dimensions {
  font-size: 13px;
  font-weight: 400;
  color: #535353;
}

.compare-table .table tbody tr td.pro-stock p {
  font-size: 13px;
  color: #535353;
  line-height: 1;
}

/*------ end Compare Page Wrapper -----*/
/*-------- 26. Login register style ---------*/
.login-register-wrap h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.login-register-wrap h3 i {
  margin-right: 3px;
}

.login-register-wrap .login-register-form {
  margin: 30px 0 0;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form {
    margin: 10px 0 0;
  }
}

.login-register-wrap .login-register-form .sin-login-register {
  margin: 0 0 21px;
}

.login-register-wrap .login-register-form .sin-login-register label {
  font-size: 16px;
}

.login-register-wrap .login-register-form .sin-login-register label span {
  color: red;
}

.login-register-wrap .login-register-form .sin-login-register input {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 50px;
  padding: 5px 10px;
}

.login-register-wrap .login-register-form .sin-login-register input:focus {
  border: 1px solid #262626;
}

.login-register-wrap .login-register-form .login-register-btn-remember {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form .login-register-btn-remember {
    margin: 0 0 10px;
  }
}

.login-register-wrap .login-register-form .login-register-btn-remember .login-register-remember {
  margin-left: 25px;
}

.login-register-wrap .login-register-form .login-register-btn-remember .login-register-remember input {
  width: 16px;
  height: auto;
}

.login-register-wrap .login-register-form .login-register-btn-remember .login-register-remember label {
  line-height: 1;
  margin: 0;
  font-size: 16px;
}

.login-register-wrap .login-register-form a {
  font-size: 14px;
  color: #fc4f4f;
}

.login-register-wrap .login-register-form a:hover {
  text-decoration: underline;
}

.login-register-wrap .login-register-form p {
  color: #535353;
  font-size: 14px;
  margin: 0 0 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrap.mr-70 {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-register-wrap.mr-70 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-wrap.mr-70 {
    margin-right: 0;
  }
  .login-register-wrap.register-wrap {
    margin-top: 30px;
  }
}

.login-register-btn button {
  border: none;
  background-color: #262626;
  color: #fff;
  font-size: 16px;
  padding: 17px 50px 20px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .login-register-btn button {
    padding: 14px 30px 17px;
  }
}

.login-register-btn button:hover {
  background-color: #fc4f4f;
}

/*-------- 27. My account style ---------*/
.myaccount-tab-menu {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f7f7f7;
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu {
    padding: 10px 10px 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .myaccount-tab-menu {
    padding: 0px 0px 0px;
  }
}

.myaccount-tab-menu a {
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  padding: 13px 30px 12px;
  text-transform: capitalize;
  float: left;
  margin: 0 3px 0 3px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .myaccount-tab-menu a {
    padding: 10px 15px 9px;
  }
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu a {
    padding: 4px 6px;
    font-size: 14px;
    margin: 0 2px 3px 2px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .myaccount-tab-menu a {
    font-size: 13px;
    margin: 0 2px 0px 2px;
  }
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #fc4f4f;
  border-color: #fc4f4f;
  color: #ffffff;
}

#myaccountContent {
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #myaccountContent {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-bottom: 30px;
  }
}

.myaccount-content p {
  color: #535353;
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content .welcome {
  margin: 0 0 29px;
}

.myaccount-content .welcome p {
  color: #535353;
}

.myaccount-content .welcome p a {
  color: #535353;
}

.myaccount-content .welcome p a:hover {
  color: #fc4f4f;
}

.myaccount-content .welcome strong {
  color: #535353;
}

.myaccount-content > p {
  color: #535353;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 25px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item label span {
  color: red;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #262626;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #262626;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #262626;
  padding: 17px 32px;
  color: #fff;
  font-size: 14px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #fc4f4f;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #fc4f4f;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
}

/*-------- 28. Order tracking style ---------*/
.order-tracking-area {
  position: relative;
}

.order-tracking-area .order-tracking-img {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-area .order-tracking-img {
    width: 100%;
    position: static;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-area .order-tracking-img {
    width: 100%;
    position: static;
  }
}

.order-tracking-area .order-tracking-img img {
  height: 100%;
  width: 100%;
  transition: all 0.3s linear 0s;
  will-change: transform, top;
  z-index: 0;
  object-fit: cover;
}

.order-tracking-content {
  padding: 152px 165px 160px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .order-tracking-content {
    padding: 50px 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .order-tracking-content {
    padding: 50px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-tracking-content {
    padding: 50px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-content {
    padding: 30px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-content {
    padding: 30px 15px 50px;
  }
}

.order-tracking-content .order-tracking-title {
  margin: 0 0 55px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .order-tracking-content .order-tracking-title {
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .order-tracking-content .order-tracking-title {
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-tracking-content .order-tracking-title {
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-content .order-tracking-title {
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-content .order-tracking-title {
    margin: 0 0 25px;
  }
}

.order-tracking-content .order-tracking-title h3 {
  font-size: 36px;
  margin: 0 0 25px;
}

@media only screen and (max-width: 767px) {
  .order-tracking-content .order-tracking-title h3 {
    margin: 0 0 10px;
    font-size: 30px;
  }
}

.order-tracking-content .order-tracking-title ul li {
  color: #262626;
}

.order-tracking-content .order-tracking-title ul li a {
  color: #777;
}

.order-tracking-content .order-tracking-title ul li a:hover {
  color: #fc4f4f;
}

.order-tracking-content p {
  line-height: 1.714286;
  margin: 0;
  text-align: center;
  width: 98%;
  color: #2F2F2F;
}

.order-tracking-content .order-tracking-form {
  margin: 30px 0 0;
  text-align: left;
}

.order-tracking-content .order-tracking-form .sin-order-tracking {
  margin: 0 0 30px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 70px;
  padding: 10px 20px;
  color: #737373;
  font-size: 12px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input:focus {
  border: 1px solid #262626;
}

.order-tracking-content .order-tracking-form .order-track-btn {
  text-align: center;
}

.order-tracking-content .order-tracking-form .order-track-btn a {
  display: inline-block;
  font-weight: 700;
  color: #fff;
  background-color: #262626;
  line-height: 1;
  padding: 18px 40px;
}

.order-tracking-content .order-tracking-form .order-track-btn a:hover {
  background-color: #fc4f4f;
}

/*-------- 29. About us style ---------*/
.skill-img {
  overflow: hidden;
  margin-left: 30px;
}

.skill-img img {
  width: 100%;
  transform: scale(1);
  transition: all .3s ease 0s;
}

.skill-img:hover::before {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  pointer-events: none;
}

.skill-img:hover img {
  transform: scale(1.05);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-img {
    margin-left: 0px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-img {
    margin-left: 0px;
    margin-bottom: 20px;
  }
}

.skill-content {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .skill-content {
    margin-right: 0px;
  }
}

.skill-content h2 {
  font-size: 46px;
  margin: 0 0 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-content h2 {
    font-size: 30px;
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-content h2 {
    font-size: 30px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-content h2 {
    font-size: 19px;
    margin: 0px 0 10px;
  }
}

.skill-content p {
  color: #777;
  line-height: 1.8em;
  margin: 0;
}

.skill-bar {
  overflow: hidden;
  padding-top: 11px;
  margin-top: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-bar {
    margin-top: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-bar {
    margin-top: 5px;
  }
}

.skill-bar .skill-bar-item {
  padding-bottom: 38px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-bar .skill-bar-item {
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-bar .skill-bar-item {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-bar .skill-bar-item {
    padding-bottom: 20px;
  }
}

.skill-bar .skill-bar-item > span {
  font-size: 12px;
  text-align: center;
  z-index: 2;
}

.skill-bar .skill-bar-item .progress {
  background-color: #EFEFEF;
  border-radius: 0;
  box-shadow: none;
  height: 3px;
  margin-top: 9px;
  overflow: visible;
}

.skill-bar .skill-bar-item .progress .progress-bar {
  background-color: #232323;
  box-shadow: none;
  position: relative;
  overflow: visible;
}

.skill-bar .skill-bar-item .progress .progress-bar span.text-top {
  color: #535353;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: -34px;
}

.skill-bar .skill-bar-item:nth-child(1) .progress-bar {
  width: 95%;
}

.skill-bar .skill-bar-item:nth-child(2) .progress-bar {
  width: 85%;
}

.skill-bar .skill-bar-item:nth-child(3) .progress-bar {
  width: 90%;
}

.skill-bar .skill-bar-item:nth-child(4) .progress-bar {
  width: 90%;
}

.skill-bar .skill-bar-item:last-child {
  padding-bottom: 0;
}

.testimonial-active-2 .slick-list {
  padding: 0 400px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .testimonial-active-2 .slick-list {
    padding: 0 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-active-2 .slick-list {
    padding: 0 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-active-2 .slick-list {
    padding: 0 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-active-2 .slick-list {
    padding: 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2 .slick-list {
    padding: 0 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-active-2 .slick-list {
    padding: 0 100px;
  }
}

.single-testimonial-3 {
  background-color: #ffffff;
  border: 1px solid #262626;
  margin: 0px 30px 0px 30px;
  padding: 30px 60px 30px 60px;
  opacity: 0.3;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-testimonial-3 {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-3 {
    padding: 30px 30px 30px 30px;
    margin: 0px 15px 0px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-3 {
    padding: 30px 20px 30px 20px;
    margin: 0px 15px 0px 15px;
  }
}

.single-testimonial-3 i {
  color: #2F2F2F;
  font-size: 22px;
}

.single-testimonial-3 p {
  color: #535353;
  font-size: 18px;
  font-family: "Alegreya", serif;
  line-height: 1.8em;
  margin: 20px 0 0;
}

.single-testimonial-3 .client-info-wrap3 {
  display: flex;
  align-items: center;
  margin-top: 43px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-3 .client-info-wrap3 {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-3 .client-info-wrap3 {
    margin-top: 20px;
  }
}

.single-testimonial-3 .client-info-wrap3 .client-img-3 {
  flex: 0 0 80px;
  margin-right: 25px;
}

.single-testimonial-3 .client-info-wrap3 .client-img-3 img {
  width: 100%;
}

.single-testimonial-3 .client-info-wrap3 .client-info-3 h3 {
  font-size: 18px;
  margin: 0 0 5px;
  line-height: 1;
}

.single-testimonial-3 .client-info-wrap3 .client-info-3 span {
  font-size: 12px;
  color: #535353;
}

.single-testimonial-3.slick-active {
  opacity: 1;
}

.testimonial-active-2 .slick-dots {
  display: flex;
  justify-content: center;
  margin-top: 45px;
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2 .slick-dots {
    margin-top: 20px;
  }
}

.testimonial-active-2 .slick-dots li {
  margin: 0 7px;
}

.testimonial-active-2 .slick-dots li button {
  border: none;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.18);
  color: #262626;
  font-size: 0px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  transition: all .3s ease 0s;
}

.testimonial-active-2 .slick-dots li button:hover {
  background-color: #262626;
}

.testimonial-active-2 .slick-dots li.slick-active button {
  background-color: #262626;
}

.team-wrap {
  position: relative;
  overflow: hidden;
}

.team-wrap:after {
  height: 100px;
  width: 150px;
  opacity: 0.9;
  z-index: 9;
  bottom: -40px;
  left: -65px;
  content: "";
  transform: rotate(45deg);
  position: absolute;
  background-color: #fc4f4f;
  transition: all .3s ease 0s;
  opacity: 1;
  visibility: visible;
}

.team-wrap img {
  width: 100%;
}

.team-wrap .team-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

.team-wrap .team-content h4 {
  font-size: 24px;
  color: #fff;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-wrap .team-content h4 {
    font-size: 20px;
  }
}

.team-wrap .team-content span {
  font-size: 12px;
  color: #fff;
  margin: 3px 0 24px;
  display: block;
}

.team-wrap .team-content .team-social a {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 100%;
  margin: 0 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-wrap .team-content .team-social a {
    margin: 0 2px;
  }
}

.team-wrap .team-content .team-social a:hover {
  background-color: #2f2f2f;
  border: 1px solid #2f2f2f;
}

.team-wrap:hover:before {
  opacity: 0.9;
  background-color: #fc4f4f;
  pointer-events: none;
}

.team-wrap:hover:after {
  opacity: 0;
  visibility: hidden;
}

.team-wrap:hover .team-content {
  opacity: 1;
  visibility: visible;
}

/*-------- 30. Contact style ---------*/
.contact-form-area {
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-area {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-area {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-area {
    margin-right: 0px;
  }
}

.contact-form-area h2 {
  font-size: 36px;
  margin: 0 0 50px;
}

@media only screen and (max-width: 767px) {
  .contact-form-area h2 {
    font-size: 30px;
    margin: 0 0 20px;
  }
}

.contact-form-area input, .contact-form-area textarea {
  height: 62px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #535353;
  font-size: 12px;
  padding: 2px 20px;
  margin-bottom: 20px;
}

.contact-form-area input::-webkit-input-placeholder, .contact-form-area textarea::-webkit-input-placeholder {
  color: #535353;
  opacity: 1;
}

.contact-form-area input:focus, .contact-form-area textarea:focus {
  border: 1px solid #262626;
}

.contact-form-area textarea {
  padding: 20px 20px;
  height: 260px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-area textarea {
    height: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-area textarea {
    height: 120px;
  }
}

.contact-form-area button.submit {
  background-color: #262626;
  color: #fff;
  padding: 12px 40px 12px;
  border: none;
  font-size: 12px;
}

.contact-form-area button.submit:hover {
  background-color: #fc4f4f;
}

@media only screen and (max-width: 767px) {
  .contact-info-area {
    margin: 30px 0 0px;
  }
}

.contact-info-area h2 {
  font-size: 36px;
  margin: 0 0 50px;
}

@media only screen and (max-width: 767px) {
  .contact-info-area h2 {
    font-size: 30px;
    margin: 0 0 20px;
  }
}

.contact-info-area .contact-info-top .sin-contact-info-wrap {
  display: flex;
  margin: 0 0 25px;
}

.contact-info-area .contact-info-top .sin-contact-info-wrap .contact-icon i {
  color: #262626;
  font-size: 20px;
}

.contact-info-area .contact-info-top .sin-contact-info-wrap .contact-address {
  margin-left: 15px;
}

.contact-info-area .contact-info-top .sin-contact-info-wrap .contact-address span {
  font-weight: 700;
  display: inline-block;
}

.contact-info-area .contact-info-top .sin-contact-info-wrap .contact-address p {
  font-size: 12px;
  color: #535353;
  margin: 0;
}

.contact-info-area .contact-info-bottom {
  border-top: 1px solid rgba(119, 119, 119, 0.2);
  margin-top: 34px;
  padding-top: 33px;
}

.contact-info-area .contact-info-bottom ul li {
  font-size: 12px;
  margin: 0 0 15px;
  color: #777;
}

.contact-info-area .contact-info-bottom ul li:last-child {
  margin-bottom: 0;
}

.contact-info-area .contact-info-bottom ul li i {
  font-size: 20px;
  position: relative;
  top: 5px;
  margin-right: 14px;
  color: #262626;
}

.contact-info-area .contact-info-bottom .contact-info-social {
  margin: 40px 0 0;
}

.contact-info-area .contact-info-bottom .contact-info-social a {
  font-size: 20px;
  margin-right: 27px;
  color: #777;
}

.contact-info-area .contact-info-bottom .contact-info-social a:last-child {
  margin-right: 0;
}

.contact-info-area .contact-info-bottom .contact-info-social a:hover {
  color: #fc4f4f;
}

/*-------- 31. Faq style ---------*/
.faq-wrap {
  margin: 0 0 20px;
}

.faq-wrap h2 {
  color: #fc4f4f;
  font-size: 36px;
  margin: 0 0 48px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-wrap h2 {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-wrap h2 {
    margin: 0 0 20px;
    font-size: 23px;
  }
}

.faq-wrap .single-faq-wrap h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 23px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-wrap .single-faq-wrap h3 {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-wrap .single-faq-wrap h3 {
    margin: 0 0 10px;
    font-size: 18px;
  }
}

.faq-wrap .single-faq-wrap p {
  line-height: 28px;
  color: #777;
  margin: 0;
  width: 88%;
}

@media only screen and (max-width: 767px) {
  .faq-wrap .single-faq-wrap p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .faq-wrap .single-faq-wrap.mb-40 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-area.pb-35 {
    padding-bottom: 0px;
  }
}

/*-------- 32. Newsletter style ---------*/
.b-modal.__b-popup1__ {
  background-color: #333 !important;
  opacity: .9 !important;
}

.newletter-popup {
  width: 100%;
  background: #fff none repeat scroll 0 0;
  left: 0;
  margin: auto;
  position: fixed !important;
  right: 0;
  top: 22% !important;
  width: 1070px;
  display: none;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .newletter-popup {
    top: 15% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .newletter-popup {
    top: 15% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newletter-popup {
    width: 940px;
    top: 15% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newletter-popup {
    width: 700px;
    top: 15% !important;
  }
}

@media only screen and (max-width: 767px) {
  .newletter-popup {
    width: 290px;
    top: 10% !important;
    height: 300px !important;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .newletter-popup {
    width: 500px;
    height: 400px !important;
    overflow-y: auto;
  }
}

.newletter-popup > #popup2 {
  position: absolute;
  right: -18px;
  top: -30px;
  width: 54px;
  height: 54px;
  background-color: #292929;
  color: #fff;
  font-size: 18px;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .newletter-popup > #popup2 {
    right: 0px;
    top: 0px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

.newletter-popup > #popup2 > span i {
  line-height: 54px;
}

@media only screen and (max-width: 767px) {
  .newletter-popup > #popup2 > span i {
    line-height: 35px;
  }
}

.newletter-popup > #popup2:hover {
  color: #fc4f4f;
}

.newletter-popup .popup-wrapper-all {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all {
    display: block;
  }
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area {
  flex: 0 0 64%;
  padding: 56px 80px 33px 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-area {
    padding: 50px 30px 33px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-area {
    padding: 20px 30px 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-area {
    padding: 15px 15px 15px 15px;
    flex: 0 0 100%;
  }
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area span {
  color: #8a8a8a;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area h2 {
  font-family: "Alegreya", serif;
  font-size: 46px;
  line-height: 1em;
  margin: 8px 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-area h2 {
    margin: 8px 0 20px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-area h2 {
    margin: 8px 0 20px;
    font-size: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-area h2 {
    margin: 8px 0 15px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-area h2 {
    font-size: 25px;
  }
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form input {
  border: 1px solid #e0e0e8;
  height: auto;
  padding: 20px 20px 20px 20px;
  font-size: 12px;
  color: #262626;
  height: 62px;
  background-color: transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form input {
    height: 40px;
  }
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form input:focus {
  box-shadow: 0 0 10px #e0e0e8;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .mc-news {
  display: none;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 {
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 {
    margin-top: 15px;
  }
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 input {
  width: auto;
  height: auto;
  color: #fff;
  background-color: #262626;
  padding: 15px 40px 16px 40px;
  font-size: 16px;
  text-transform: capitalize;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 input:hover {
  background-color: #fc4f4f;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area .dont-show {
  display: flex;
  align-items: center;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area .dont-show input {
  width: auto;
  height: auto;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-area .dont-show label {
  color: #535353;
  display: block;
  margin: 0 0 0 5px;
}

.newletter-popup .popup-wrapper-all .popup-image {
  flex: 0 0 36%;
}

@media only screen and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all .popup-image {
    display: block;
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all .popup-image {
    display: block;
  }
}

.newletter-popup .popup-wrapper-all .popup-image img {
  width: 100%;
}

/*-------- 33. Wishlist style ---------*/
@media only screen and (max-width: 767px) {
  .wishlist-main-area.pb-100 {
    padding-bottom: 60px;
  }
}

.wishlist-table-content table {
  width: 100%;
}

.wishlist-table-content table thead {
  background-color: #f7f7f7;
}

.wishlist-table-content table thead > tr th {
  border-top: medium none;
  font-size: 16px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: 400;
  text-transform: capitalize;
  padding: 13px 20px 12px 10px;
  color: #8A8A8A;
  letter-spacing: 1px;
}

.wishlist-table-content table thead > tr th.wishlist-cart-none {
  width: 100px;
}

.wishlist-table-content table thead > tr th.wishlist-cart-none span {
  display: none;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table thead > tr th {
    padding: 13px 10px 12px;
  }
}

.wishlist-table-content table tbody > tr td {
  font-size: 12px;
  padding: 30px 0px 0px;
  color: #535353;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td {
    padding: 30px 12px 0px 10px;
  }
}

.wishlist-table-content table tbody > tr td.wishlist-remove {
  width: 30px;
}

.wishlist-table-content table tbody > tr td.wishlist-remove a {
  color: #989898;
  font-size: 16px;
}

.wishlist-table-content table tbody > tr td.wishlist-img {
  width: 80px;
}

.wishlist-table-content table tbody > tr td.wishlist-name {
  width: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content table tbody > tr td.wishlist-name {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td.wishlist-name {
    width: 150px;
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 0;
  }
}

.wishlist-table-content table tbody > tr td.wishlist-name a {
  font-size: 12px;
  display: block;
  margin: 0 0 5px;
  color: #535353;
}

.wishlist-table-content table tbody > tr td.wishlist-name a:hover {
  color: #fc4f4f;
}

.wishlist-table-content table tbody > tr td.wishlist-stock {
  width: 170px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content table tbody > tr td.wishlist-stock {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td.wishlist-stock {
    width: 100px;
  }
}

.wishlist-table-content table tbody > tr td.wishlist-stock span {
  font-size: 16px;
  font-weight: 400;
  color: #535353;
  display: inline-block;
  line-height: 1;
}

.wishlist-table-content table tbody > tr td.wishlist-price {
  width: 57px;
}

.wishlist-table-content table tbody > tr td.wishlist-price span {
  color: #262626;
  font-size: 16px;
}

.wishlist-table-content table tbody > tr td.wishlist-cart > a {
  display: inline-block;
  border: 1px solid #262626;
  line-height: 1;
  padding: 13px 20px;
  color: #262626;
  font-size: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wishlist-table-content table tbody > tr td.wishlist-cart > a {
    padding: 10px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content table tbody > tr td.wishlist-cart > a {
    padding: 10px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td.wishlist-cart > a {
    width: 100px;
    padding: 10px 12px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td.wishlist-cart > a {
    width: 94px;
    padding: 10px 10px;
  }
}

.wishlist-table-content table tbody > tr td.wishlist-cart > a:hover {
  color: #fc4f4f;
  border: 1px solid #fc4f4f;
}
