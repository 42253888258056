/*-------- 16. Video style ---------*/

.video-area {
    position: relative;
    padding-top: 80px;
    overflow: hidden;
}
.jarallax-position {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    perspective: 1200px;
    z-index: 2;
    .parallax-img-2 {
        position: absolute;
        left: 30px;
        top: 0%;
        height: 200%;
        width: 100%;
        background-repeat: no-repeat;
        will-change: transform;
        @media #{$xx-layout} {
            height: 500%;
        }
        @media #{$xl-layout} {
            height: 500%;
        }
        @media #{$xs-layout} {
            left: -80px;
            height: 100%;
            width: 200%;
        }
        @media #{$sm-layout} {
            left: -30px;
            height: 100%;
            width: 100%;
        }
    }
}
.video-content {
    padding: 195px 0 160px;
    @media #{$lg-layout} {
        padding: 105px 0 100px;
    }
    @media #{$md-layout} {
        padding: 90px 0 100px;
    }
    @media #{$xs-layout} {
        padding: 80px 0 80px;
    }
    @media #{$sm-layout} {
        padding: 100px 0 100px;
    }
    h3 {
        color:rgba(255,255,255,0.42);
        font-size: 120px;
        font-weight: 500;
        margin: 0;
        line-height: 100px;
        @media #{$xl-layout} {
            font-size: 100px;
            line-height: 80px;
        }
        @media #{$lg-layout} {
            font-size: 80px;
            line-height: 75px;
        }
        @media #{$md-layout} {
            font-size: 70px;
            line-height: 65px;
        }
        @media #{$xs-layout} {
            font-size: 35px;
            line-height: 40px;
        }
        @media #{$sm-layout} {
            font-size: 55px;
            line-height: 50px;
        }
    }
    h1 {
        color:#ffffff;
        font-size: 250px;
        font-weight: 400;
        line-height: 230px;
        text-shadow: 10px 0px 32px rgba(0,0,0,0.14);
        margin: 21px 0 8px;
        position: relative;
        z-index: 9;
        @media #{$xx-layout} {
            font-size: 175px;
            line-height: 200px;
        }
        @media #{$xl-layout} {
            font-size: 150px;
            line-height: 190px;
        }
        @media #{$lg-layout} {
            font-size: 120px;
            line-height: 150px;
        }
        @media #{$md-layout} {
            font-size: 80px;
            line-height: 120px;
        }
        @media #{$xs-layout} {
            font-size: 35px;
            line-height: 50px;
            margin: 10px 0 20px;
        }
        @media #{$sm-layout} {
            font-size: 55px;
            line-height: 60px;
            margin: 10px 0 40px;
        }
    }
    .video-btn {
        display: inline-block;
        border-radius: 50%;
        position: relative;
        transition: all .5s;
        z-index: 9;
        &:before {
            content: '';
            position: absolute;
            left: -5px;
            right: -5px;
            top: -5px;
            bottom: -5px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            opacity: 0;
            transition: all .5s;
            z-index: 1;
        }
        a {
            position: relative;
            z-index: 2;
            display: inline-block;
            img {
                border-radius: 50% 50% 50% 50%;
                box-shadow: 0px 0px 43px 0px rgba(0,0,0,0.1);
                @media #{$md-layout} {
                    width: 90px;
                }
                @media #{$xs-layout} {
                    width: 65px;
                }
            }
        }
        &:hover:before  {
            opacity: 1;
            left: -15px;
            right: -15px;
            top: -15px;
            bottom: -15px;
        }
        &:hover  {
            background: rgba(255, 255, 255, 0.2);
            padding: 10px;
            margin: -10px;
        }
    }
}

.video-area-ptb-2 {
    padding-top: 291px;
    padding-bottom: 265px;
    @media #{$xx-layout} {
        padding-top: 191px;
        padding-bottom: 165px;
    }
    @media #{$xl-layout} {
        padding-top: 191px;
        padding-bottom: 165px;
    }
    @media #{$lg-layout} {
        padding-top: 171px;
        padding-bottom: 145px;
    }
    @media #{$md-layout} {
        padding-top: 171px;
        padding-bottom: 145px;
    }
    @media #{$xs-layout} {
        padding-top: 90px;
        padding-bottom: 100px;
    }
}

.video-content-2 {
    h2 {
        color: #fff;
        font-size: 120px;
        font-family: $alegreya;
        text-transform: uppercase;
        font-style: italic;
        margin: 0 0 25px;
        line-height: 1;
        @media #{$xl-layout} {
            font-size: 100px;
        }
        @media #{$lg-layout} {
            font-size: 80px;
        }
        @media #{$md-layout} {
            font-size: 80px;
        }
        @media #{$xs-layout} {
            font-size: 40px;
        }
    }
    .video-btn-2 {
        position: relative;
        display: inline-block;
        transition: all .5s;
        cursor: pointer;
        a {
            position: relative;
            z-index: 2;
        }
        &:before {
            content: '';
            position: absolute;
            left: -5px;
            right: -5px;
            top: -5px;
            bottom: -5px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            opacity: 0;
            transition: all .5s;
            z-index: 1;
        }
        &:hover:before {
            opacity: 1;
            left: -15px;
            right: -15px;
            top: -15px;
            bottom: -15px;
        }
        &:hover {
            background: rgba(255, 255, 255, 0.2);
            padding: 10px;
            margin: -10px;
            border-radius: 100%;
        }
    }
}




