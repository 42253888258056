/*-------- 30. Contact style ---------*/

.contact-form-area {
    margin-right: 30px;
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    h2 {
        font-size: 36px;
        margin: 0 0 50px;
        @media #{$xs-layout} {
            font-size: 30px;
            margin: 0 0 20px;
        }
    }
    input , textarea {
        height: 62px;
        background-color: transparent;
        border: 1px solid rgba(0,0,0,0.15);
        color: #535353;
        font-size: 12px;
        padding: 2px 20px;
        margin-bottom: 20px;
        &::-webkit-input-placeholder {
            color: #535353;
            opacity: 1;
        }
        &:focus {
            border: 1px solid #262626;
        }
    }
    textarea { 
        padding: 20px 20px;
        height: 260px;
        @media #{$md-layout} {
            height: 160px
        }
        @media #{$xs-layout} {
            height: 120px
        }
    }
    button {
        &.submit {
            background-color: #262626;
            color: #fff;
            padding: 12px 40px 12px;
            border: none;
            font-size: 12px;
            &:hover {
                background-color: $theme-color;
            }
        }
    }
}

.contact-info-area {
    @media #{$xs-layout} {
        margin: 30px 0 0px;
    }
    h2 {
        font-size: 36px;
        margin: 0 0 50px;
        @media #{$xs-layout} {
            font-size: 30px;
            margin: 0 0 20px;
        }
    }
    .contact-info-top {
        .sin-contact-info-wrap {
            display: flex;
            margin: 0 0 25px;
            .contact-icon {
                i {
                    color: #262626;
                    font-size: 20px;
                }
            }
            .contact-address {
                margin-left: 15px;
                span {
                    font-weight: 700;
                    display: inline-block;
                }
                p {
                    font-size: 12px;
                    color: #535353;
                    margin: 0;
                }
            }
        }
    }
    .contact-info-bottom {
        border-top: 1px solid rgba(119,119,119,0.2);
        margin-top: 34px;
        padding-top: 33px;
        ul {
            li {
                font-size: 12px;
                margin: 0 0 15px;
                color: #777;
                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    font-size: 20px;
                    position: relative;
                    top: 5px;
                    margin-right: 14px;
                    color: #262626;
                }
            }
        }
        .contact-info-social {
            margin: 40px 0 0;
            a {
                font-size: 20px;
                margin-right: 27px;
                color: #777;
                &:last-child {
                    margin-right: 0;
                }
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}




