
/*-------- 13. Animation style ---------*/

@keyframes lastudioFade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes lastudioZoomIn {
	0% {
		opacity: 0;
		transform: scale(0.75);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes lastudioZoomOut {
	0% {
		opacity: 0;
		transform: scale(1.1);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes lastudioMoveUp {
	0% {
		opacity: 0;
		transform: translateY(25px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes lastudioMoveUpBig {
	0% {
		opacity: 0;
		transform: translateY(100px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes lastudioMoveDown {
	0% {
		opacity: 0;
		transform: translateY(-25px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes lastudioMoveDownBig {
	0% {
		opacity: 0;
		transform: translateY(-100px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes lastudioMoveLeft {
	0% {
		opacity: 0;
		transform: translateX(25px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes lastudioMoveLeftBig {
	0% {
		opacity: 0;
		transform: translateX(100px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes lastudioMoveRight {
	0% {
		opacity: 0;
		transform: translateX(-25px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes lastudioMoveRightBig {
	0% {
		opacity: 0;
		transform: translateX(-100px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes lastudioFallPerspective {
	0% {
		opacity: 0;
		transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
	}

	100% {
		opacity: 1;
		transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
	}
}

@keyframes lastudioFlipInX {
	0% {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		animation-timing-function: ease-in;
	}

	60% {
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}

	80% {
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}

	100% {
		transform: perspective(400px);
	}
}

@keyframes lastudioFlipInY {
	0% {
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		animation-timing-function: ease-in;
	}

	60% {
		transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		opacity: 1;
	}

	80% {
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}

	100% {
		transform: perspective(400px);
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes zoomIn {
	0% {
		opacity: 0;
		transform: scale(0.75);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes zoomOut {
	0% {
		opacity: 0;
		transform: scale(1.1);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}


@keyframes moveUp {
	0% {
		opacity: 0;
		transform: translateY(25px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes columnMoveUp {
	0% {
		opacity: 0;
		transform: translateY(25px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fallPerspective {
	0% {
		opacity: 0;
		transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
	}

	100% {
		opacity: 1;
		transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
	}
}

@keyframes edit-button-pulse {
	0% {
		box-shadow: 0 0 2px 0 rgba( 183, 8, 78, 0.6);
	}
	30% {
		box-shadow: 0 0 2px 10px rgba( 183, 8, 78, 0);
	}
	100% {
		box-shadow: 0 0 2px 0 rgba( 183, 8, 78, 0);
	}
}
