/*-------- 29. About us style ---------*/

.skill-img {
    overflow: hidden;
    margin-left: 30px;
    img {
        width: 100%;
        transform: scale(1);
        transition: all .3s ease 0s;
    }
    &:hover::before {
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 1;
        pointer-events: none;
    }
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
    @media #{$md-layout} {
        margin-left: 0px;
        margin-bottom: 40px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-bottom: 20px; 
    }
}

.skill-content {
    margin-right: 30px;
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    h2 {
        font-size: 46px;
        margin: 0 0 18px;
        @media #{$lg-layout} {
            font-size: 30px;
            margin: 0 0 15px;
        }
        @media #{$md-layout} {
            font-size: 30px;
            margin: 0 0 15px;
        }
        @media #{$xs-layout} {
            font-size: 19px;
            margin: 0px 0 10px;
        }
    }
    p {
        color: #777;
        line-height: 1.8em;
        margin: 0;
    }
}

.skill-bar {
    overflow: hidden;
    padding-top: 11px;
    margin-top: 25px;
    @media #{$lg-layout} {
        margin-top: 14px;
    }
    @media #{$xs-layout} {
        margin-top: 5px;
    }
    .skill-bar-item {
        padding-bottom: 38px;
        position: relative;
        @media #{$lg-layout} {
            padding-bottom: 10px;
        }
        @media #{$md-layout} {
            padding-bottom: 20px;
        }
        @media #{$xs-layout} {
            padding-bottom: 20px;
        }
        > span {
            font-size: 12px;
            text-align: center;
            z-index: 2;
        }
        .progress {
            background-color: #EFEFEF;
            border-radius: 0;
            box-shadow: none;
            height: 3px;
            margin-top: 9px;
            overflow: visible;
            .progress-bar {
                background-color: #232323;
                box-shadow: none;
                position: relative;
                overflow: visible;
                span.text-top {
                    color: #535353;
                    font-size: 12px;
                    position: absolute;
                    right: 0;
                    top: -34px;
                }
            }

        }
        &:nth-child(1) .progress-bar {
            width: 95%;
        }
        &:nth-child(2) .progress-bar {
            width: 85%;
        }
        &:nth-child(3) .progress-bar {
            width: 90%;
        }
        &:nth-child(4) .progress-bar {
            width: 90%;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
}

.testimonial-active-2 {
    .slick-list {
        padding: 0 400px;
        @media #{$xx-layout}{
            padding: 0 200px;
        }
        @media #{$xl-layout}{
            padding: 0 150px;
        }
        @media #{$lg-layout}{
            padding: 0 200px;
        }
        @media #{$md-layout}{
            padding: 0 100px;
        }
        @media #{$xs-layout}{
            padding: 0 0px;
        }
        @media #{$sm-layout}{
            padding: 0 100px;
        }
    }
    
}

.single-testimonial-3 {
    background-color:#ffffff;
    border: 1px solid #262626;
    margin: 0px 30px 0px 30px;
    padding: 30px 60px 30px 60px;
    opacity: 0.3;
    @media #{$xl-layout}{
        padding: 30px 30px 30px 30px;
    }
    @media #{$md-layout}{
        padding: 30px 30px 30px 30px;
        margin: 0px 15px 0px 15px;
    }
    @media #{$xs-layout}{
        padding: 30px 20px 30px 20px;
        margin: 0px 15px 0px 15px;
    }
    i {
        color: #2F2F2F;
        font-size: 22px;
    }
    p {
        color: #535353;
        font-size: 18px;
        font-family: $alegreya;
        line-height: 1.8em;
        margin: 20px 0 0;
    }
    .client-info-wrap3 {
        display: flex;
        align-items: center;
        margin-top: 43px;
        @media #{$md-layout}{
            margin-top:20px;
        }
        @media #{$xs-layout}{
            margin-top:20px;
        }
        .client-img-3 {
            flex: 0 0 80px;
            margin-right: 25px;
            img {
                width: 100%;
            }
        }
        .client-info-3 {
            h3 {
                font-size: 18px;
                margin: 0 0 5px;
                line-height: 1;
            }
            span {
                font-size: 12px;
                color: #535353;
            }
        }
    }
    &.slick-active {
        opacity: 1;
    }
}

.testimonial-active-2 {
    .slick-dots {
        display: flex;
        justify-content: center;
        margin-top: 45px;
        @media #{$xs-layout} {
            margin-top: 20px;
        }
        li {
            margin: 0 7px;
            button {
                border: none;
                padding: 0;
                background-color: rgba(0,0,0,0.18);
                color: #262626;
                font-size: 0px;
                border-radius: 100%;
                width: 18px;
                height: 18px;
                transition: all .3s ease 0s;
                &:hover {
                    background-color: #262626;
                }
            }
            &.slick-active {
                button {
                    background-color: #262626;
                }
            }
        }
    }
}

.team-wrap {
    position: relative;
    overflow: hidden;
    &:after {
        height: 100px;
        width: 150px;
        opacity: 0.9;
        z-index: 9;
        bottom: -40px;
        left: -65px;
        content: "";
        transform: rotate(45deg);
        position: absolute;
        background-color: #fc4f4f;
        transition: all .3s ease 0s;
        opacity: 1;
        visibility: visible;
    }
    img {
        width: 100%;
    }
    .team-content {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translateY(-50%);
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease 0s;
        h4 {
            font-size: 24px;
            color: #fff;
            margin: 0;
            @media #{$md-layout}{
                font-size: 20px;
            }
        }
        span {
            font-size: 12px;
            color: #fff;
            margin: 3px 0 24px;
            display: block;
        }
        .team-social {
            a {
                display: inline-block;
                font-size: 16px;
                color: #fff;
                width: 38px;
                height: 38px;
                line-height: 38px;
                text-align: center;
                border: 1px solid #fff;
                border-radius: 100%;
                margin: 0 5px;
                @media #{$md-layout}{
                    margin: 0 2px;
                }
                &:hover {
                    background-color: #2f2f2f;
                    border: 1px solid #2f2f2f;
                }
            }
        }
    }
    &:hover {
        &:before {
            opacity: 0.9;
            background-color: #fc4f4f;
            pointer-events: none;
        }
        &:after {
            opacity: 0;
            visibility: hidden;
        }
        .team-content {
            opacity: 1;
            visibility: visible;
        }
    }
}






